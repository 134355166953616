import { useSDKAuth } from "./auth-sdk";

const { useQuery } = require("@tanstack/react-query");

const useAuthQuery = (...options) => {
  const query = useQuery(...options);
  const { authorizationLogout } = useSDKAuth();
  if (query?.error?.response?.status === 401) {
    console.log("Access token get expired, logout...");
    authorizationLogout();
  }
  return query;
};

export default useAuthQuery;
