// Libs
import React from "react";

// Components
import MainWaveDivider from "components/libs/wave-divider";
import { Col, Container, Row } from "reactstrap";
import { Fade } from "react-reveal";
import { homepageFeaturedContent } from "data/homepage";

// Styles
import s from "./index.module.scss";

function HomepageContentsFeaturedSection() {
  return (
    <>
      <MainWaveDivider scrollId="features" title="Features" />
      <Container className={s.featuredContainer}>
        {homepageFeaturedContent.map((item, index) => {
          return (
            <div className={s.featuredItem} key={index}>
              <Fade bottom>
                <Row>
                  <Col className={s.textContainer}>
                    <div dangerouslySetInnerHTML={{ __html: item.title }} />
                    <div dangerouslySetInnerHTML={{ __html: item.content }} />
                  </Col>
                  <Col className={s.imageContainer}>
                    <img alt={item.alt} src={item.imgSrc}></img>
                  </Col>
                </Row>
              </Fade>
            </div>
          );
        })}
      </Container>
    </>
  );
}

export default HomepageContentsFeaturedSection;
