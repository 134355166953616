import React from "react";

import PricingIconAdvance from "components/libs/pricing-icon/advance-icon";
import PricingIconFree from "components/libs/pricing-icon/free-icon";
import PricingIconPro from "components/libs/pricing-icon/pro-icon";

export const introSlider = [
  { id: 1, alt: "On-premise firewall", caption: "On-premise firewall" },
  { id: 2, alt: "AWS Security Group", caption: "AWS Security Group" },
  { id: 3, alt: "GCP Firewall Rules", caption: "GCP Firewall Rules" },
  {
    id: 4,
    alt: "Azure Network Security Groups",
    caption: "Azure Network Security Groups",
  },
];

export const homepageFeaturedContent = [
  {
    title: `<h2 class="h2">Firewall rules on steroids</h2>`,
    imgSrc: `/img2.png`,
    alt: `Firewall`,
    content: `              
    <p>Want to keep your existing trusted and high performance firewall?</p>
    <p>Your firewall rules does not support DDNS?</p>
    <p>Your user work from anywhere?</p>
    <p>Your devices are everywhere?</p>
    <p>Securing your network with firewall rules that only support static IP is hard!</p>
    <p> AllowExactly powers up your firewall rules with automatically update to match the latest IP of your devices – Even the devices IP keep changing.</p>
    `,
  },
  {
    title: `<h2 class="h2">Device IP changes?<br />Not a problem!</h2>`,
    imgSrc: `/img3.png`,
    alt: `Devices`,
    content: `              
    <p>AllowExactly provide installable agent for various OS and mobile devices, enable devices to self-report IP address changes with zero-intervention.</p>
    <p>Remove the burden for setting up individual DDNS for each device.</p>
    <p>Of course, if you have invested DDNS system, you can use our “agentless” feature to reuse the existing DDNS infrastructure – Seamlessly.</p>
    <p>AllowExactly power up your firewall rules with automatically update to match the latest IP of your devices – Even the devices IP keep changing.</p>
    `,
  },
  {
    title: `<h2 class="h2">Support Multi-cloud and On-premise</h2>`,
    imgSrc: `/img4.png`,
    alt: `Firewall`,
    content: `              
    <p>AllowExactly support GCP/AWS/Azure and On-premise firewall.</p>
    <p>The IP changes of any single device can propagate to any firewall rules in multi-cloud and on-premise environment.</p>
    `,
  },
  {
    title: `<h2 class="h2">AllowExactly SaaS and On-premise</h2>`,
    imgSrc: `/img5.png`,
    alt: `Firewall`,
    content: `
    <p>You want to comply with security policy by deploying AllowExactly on-premise?</p>
    <p>Talk to our sales team about on-premise deployment options</p>
    <p>Want to try before buy? SaaS version is always here ready to use anytime!</p>
    `,
  },
];

export const homepageHowItWorksContents = [
  {
    title: `<h2 class="h2">Create an account and Download the agent</h2>`,
    imgSrc: `/img6.png`,
    alt: `Data center`,
    content: `
    <p>Create an account with us and run the client agent.</p>
    <p>*Any paid account support agentless mode for zero-install experience.</p>
    <p>**Apple IOS does not support background process so require user opening the app.</p>
    `,
  },
  {
    title: `<h2 class="h2">Link up your cloud account</h2>`,
    imgSrc: `/img7.png`,
    alt: `Data center`,
    content: `
    <p>Login to our Control Panel and authorize us to your cloud account.</p>
    `,
  },
  {
    title: `<h2 class="h2">Select rules to update</h2>`,
    imgSrc: `/img8.png`,
    alt: `Data center`,
    content: `
    <p>Select which firewall rules to update.</p>
    <p>You can select any combination of devices-rules.</p>
    `,
  },
];

export const homepageWhatTheySayContents = [
  {
    name: "Name Surname",
    businessName: "Business Name",
    review:
      "Add an amazing review from your client or customer over here. Add an amazing review from your client or customer over here. Add an amazing review from your client or customer over here.",
    imageSrc: "images/customer1.png",
  },
  {
    name: "Name Surname",
    businessName: "Business Name",
    review:
      "Add an amazing review from your client or customer over here. Add an amazing review from your client or customer over here. Add an amazing review from your client or customer over here.",
    imageSrc: "images/customer2.png",
  },
  {
    name: "Name Surname",
    businessName: "Business Name",
    review:
      "Add an amazing review from your client or customer over here. Add an amazing review from your client or customer over here. Add an amazing review from your client or customer over here.",
    imageSrc: "images/customer3.png",
  },
];

export const pricingContents = {
  modes: [
    {
      name: "Free",
      displayName: "Free",
      subHead: "For Personal Use",
      monthly_plan_code: "",
      annual_plan_code: "",
      monthly_price_id: "price_1Jk8pHLis129x3kCkrapFrjU", //TODO : Unused//
      annual_price_id: "price_1Jk8pHLis129x3kCkrapFrjU", //TODO : Unused//
    },
    {
      name: "Advanced",
      displayName: "Advanced",
      subHead: "Coming Soon",
      monthly_plan_code: "advance_monthly",
      annual_plan_code: "advance_yearly",
      monthly_price_id: "price_1LGLbKLis129x3kClTRLtZWx", //TODO : Unused//
      annual_price_id: "price_1LGM7ULis129x3kCwCwVjY3s", //TODO : Unused//
      monthly_price: "Reasonable Price",
      annually_price: "Reasonable Price",
      logo: <PricingIconAdvance />,
      features: [
        "3 Device supported",
        "3 Fire rules to update",
        "GCP Firewall Management",
        "AWS Web Security Group Management",
        "Azure Network Security Management",
        "Bring your own DDNS",
      ],
    },
    {
      name: "Pro",
      displayName: "Pro!",
      subHead: "Coming Soon",
      monthly_plan_code: "pro_monthly",
      annual_plan_code: "pro_yearly",
      monthly_price_id: "price_1LGMFbLis129x3kCPdmSqNN6", //TODO : Unused//
      annual_price_id: "price_1LGLdYLis129x3kCxJgU5X27", //TODO : Unused//
      monthly_price: "Reasonable Price",
      annually_price: "Reasonable Price",
      logo: <PricingIconPro />,
      features: [
        "50 Device supported",
        "30 Fire rules to update",
        "GCP Firewall Management",
        "AWS Web Security Group Management",
        "Azure Network Security Management",
        "Bring your own DDNS",
        "Full audit trail",
      ],
    },
    {
      name: "Enterprise",
      displayName: "Enterprise",
      subHead: "Coming Soon",
      monthly_price_id: "-",
      annual_price_id: "-",
      monthly_price: "Contact Us",
      annually_price: "Contact Us",
      logo: <PricingIconFree />,
      features: [
        "Customize Device supported",
        "Customize Fire rules to update",
        "GCP Firewall Management",
        "AWS Web Security Group Management",
        "Azure Network Security Management",
        "Full audit trail",
        "Bring your own DDNS",
      ],
    },
  ],

  subHeads: [
    {
      name: "Update Rate & Sizing",
      properties: [
        {
          title: "Update Rate",
          description: "",
        },
        {
          title: "Number of Devices",
          description: "",
        },
        {
          title: "Firewall rules to Update",
          description: "",
        },
      ],
    },

    {
      name: "Firewall Rule Target",
      properties: [
        {
          title: "GCP Firewall",
          description: "",
        },
        {
          title: "Amazon Web Service Security Group",
          description: "",
        },
        {
          title: "Microsoft Azure Network security group",
          description: "",
        },
        // {
        //   title: "Integrate with your own on-premise firewall(support List)",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        // },
        // {
        // title: "Deploy our AllowExactly firewall to your premises (?)",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        // },
        // {
        // title: "Updater Developed by the community (?)",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        // },
      ],
    },

    {
      name: "Deployment Options",
      properties: [
        {
          title: "Agent-less in client device",
          description:
            "You can choose between a quick, installation-free configuration for immediate access or a more comprehensive installation process for advanced capabilities. Tailor the setup to match your needs and preferences effortlessly.",
        },
        {
          title: "Bring your own DDNS",
          description: `With "Bring Your Own DDNS," you have the flexibility to integrate and manage your own DDNS service, ensuring seamless and reliable access to your devices or services from anywhere, anytime. This feature allows you to maintain control over your DNS configurations, enhancing your online accessibility and connectivity`,
        },
        // {
        // title: "Customization of agent and integration (?)",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
        // },
        {
          title: "Deploy On-premise AllowExactly",
          description: `"Deploy On-premise AllowExactly" provides precise control over on-premises deployments, allowing you to select specific locations and the exact number of instances. Ideal for compliance, resource optimization, and tailored deployment needs, it ensures your software operates securely and efficiently in designated on-premises environments.`,
        },
        {
          title: "SaaS Model",
          description: `The "SaaS Model" delivers software via the internet on a subscription basis. It offers accessibility, automatic updates, and cost-effective use of applications without the need for installations. Perfect for modern software consumption, it's widely adopted across industries.`,
        },
      ],
    },

    {
      name: "Management & Availability",
      properties: [
        {
          title: "Management",
          description: "",
        },
        {
          title: "Audit trail",
          description: `An "Audit Trail" is a chronological record that tracks and logs all activities and changes within a system, application, or process. It provides a transparent history of actions, helping ensure accountability, security, and compliance.`,
        },
        {
          title: "Support Level",
          description: "",
        },
        {
          title: "Service Level Agreement",
          description: "",
        },
        {
          title: "Add Ons",
          description: `With "Add-Ons," you can enhance your plan by subscribing to additional features like "Increase Device Number" or "Increase Firewall Connection Number." These optional extras allow you to tailor your package to your specific needs, ensuring your services scale effortlessly as your requirements grow. Explore and select the add-ons that best suit your goals, making your plan truly customized and adaptable.`,
        },
        { title: "", description: "" },
      ],
    },
  ],

  Free: {
    priceMonthly: "FREE",
    priceAnnually: "FREE",
    "Update Rate & Sizing": {
      "Update Rate": "Every 15 Minutes",
      "Number of Devices": "1 Device",
      "Firewall rules to Update": "1",
    },
    "Firewall Rule Target": {
      "GCP Firewall": true,
      "Amazon Web Service Security Group": false,
      "Microsoft Azure Network security group": false,
      // "Integrate with your own on-premise firewall(support List)": false,
      "Deploy our AllowExactly firewall to your premises (?)": false,
      // "Updater Developed by the community (?)": true,
    },
    "Deployment Options": {
      "Agent-less in client device": "Requires agent installation",
      "Bring your own DDNS": false,
      // "Customization of agent and integration (?)": false,
      "Deploy On-premise AllowExactly": false,
      "SaaS Model": true,
    },
    "Management & Availability": {
      Management: "Single user account and single device grouping",
      "Audit trail": false,
      "Support Level": "E-mail Best effort",
      "Service Level Agreement": "Best effort without SLA",
      "Add Ons": false,
      "": "Free account available only for limited early adopter!",
    },
  },

  Advanced: {
    priceMonthly: "Reasonable Price",
    priceAnnually: "Reasonable Price",
    "Update Rate & Sizing": {
      "Update Rate": "Every 1 Minute",
      "Number of Devices": "3 Devices",
      "Firewall rules to Update": "3",
    },
    "Firewall Rule Target": {
      "GCP Firewall": true,
      "Amazon Web Service Security Group": true,
      "Microsoft Azure Network security group": true,
      // "Integrate with your own on-premise firewall(support List)": false,
      // "Deploy our AllowExactly firewall to your premises (?)": false,
      // "Updater Developed by the community (?)": true,
    },
    "Deployment Options": {
      "Agent-less in client device":
        "Both agentless and agent installation supported",
      "Bring your own DDNS": true,
      // "Customization of agent and integration (?)": false,
      "Deploy On-premise AllowExactly": false,
      "SaaS Model": true,
    },
    "Management & Availability": {
      Management: "Single user account and single device grouping",
      "Audit trail": false,
      "Support Level": "Email - 2 business day response",
      "Service Level Agreement": "SLA 99.9% per month",
      "Add Ons": true,
      "": "14 days trail\nNo credit card needed",
    },
  },

  Pro: {
    priceMonthly: "Reasonable Price",
    priceAnnually: "Reasonable Price",
    "Update Rate & Sizing": {
      "Update Rate": "ASAP – Every single change trigger update (Configurable)",
      "Number of Devices": "50 Device",
      "Firewall rules to Update": "30",
    },
    "Firewall Rule Target": {
      "GCP Firewall": true,
      "Amazon Web Service Security Group": true,
      "Microsoft Azure Network security group": true,
      // "Integrate with your own on-premise firewall(support List)": false,
      // "Deploy our AllowExactly firewall to your premises (?)": false,
      // "Updater Developed by the community (?)": true,
    },
    "Deployment Options": {
      "Agent-less in client device":
        "Both agentless and agent installation supported",
      "Bring your own DDNS": true,
      // "Customization of agent and integration (?)": false,
      "Deploy On-premise AllowExactly": false,
      "SaaS Model": true,
    },
    "Management & Availability": {
      Management: "Multi-User and device grouping for easy management",
      "Audit trail":
        "Full audit trail of IP change events, updater events, firewall rules update event with history are available.",
      "Support Level": "Email – 2 business day response",
      "Service Level Agreement": "SLA 99.9% per month",
      "Add Ons": true,
      "": "14 days trail\nNo credit card needed",
    },
  },

  Enterprise: {
    priceMonthly: "Contact Us",
    priceAnnually: "Contact Us",
    "Update Rate & Sizing": {
      "Update Rate": "ASAP – Every single change (Configurable)",
      "Number of Devices": "Contact Us",
      "Firewall rules to Update": "Contact Us",
    },
    "Firewall Rule Target": {
      "GCP Firewall": true,
      "Amazon Web Service Security Group": true,
      "Microsoft Azure Network security group": true,
      // "Integrate with your own on-premise firewall(support List)": true,
      // "Deploy our AllowExactly firewall to your premises (?)": true,
      // "Updater Developed by the community (?)": true,
    },
    "Deployment Options": {
      "Agent-less in client device":
        "Both agentless and agent installation supported",
      "Bring your own DDNS": true,
      // "Customization of agent and integration (?)": "Professional service for customization of IP source such as Active Directory and MDM are available",
      "Deploy On-premise AllowExactly": true,
      "SaaS Model": true,
    },
    "Management & Availability": {
      Management:
        "Multi-User Multi-Account Multi-device with advanced permission (eg: read-only user / Helpdesk user / device admin user)",
      "Audit trail":
        "Full audit trail of IP change events, updater events, firewall rules update event with history are available.",
      "Support Level": "Phone & Email - 4 hour",
      "Service Level Agreement": "Subject to Deployment method",
      "Add Ons": "Contact Us",
      "": "",
    },
  },
};
