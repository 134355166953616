import { useHistory, useParams } from "react-router-dom";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";

export const useSDKDashboards = ({ tenantName }) => {
  const { tenant } = useParams();
  const history = useHistory();

  const { data, isFetching } = useAuthQuery({
    queryKey: ["dashboard", tenantName],
    queryFn: async () => {
      const data = await apiSDK.dashboardApi.getDashboard({
        tenantName: tenantName,
      });
      return data;
    },
    enabled: !!tenantName,
    onError: () => {
      history.push(`/dashboard/${tenant}/error-page`);
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};
