// Libs
import React from "react";
import propTypes from "prop-types";
import { Form, Input, Modal } from "antd";

function ModalAddMembers(props) {
  const { visible, handleCancel, onSubmit } = props;

  //? ============== Handle Submit ============= ?//
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then(async (value) => {
      try {
        await onSubmit({
          email: value.email,
        });
      } catch (error) {
        return;
      } finally {
        handleCancel();
        form.resetFields();
      }
    });
  };
  // * ====================================== * //

  return (
    <>
      <Modal
        okText={"Invite"}
        title={"Add new member"}
        open={visible}
        onCancel={handleCancel}
        onOk={handleSubmit}
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label="Email"
            name={"email"}
            // rules={[{ type: "email", message: "Please input correct email" }]}
          >
            <Input placeholder={"user@gmail.com"} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
}

ModalAddMembers.propTypes = {
  onSubmit: propTypes.func,
  visible: propTypes.bool,
  handleCancel: propTypes.func,
};

export default ModalAddMembers;
