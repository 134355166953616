// Libs
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useHistory, Link, useLocation } from "react-router-dom";
import { Layout, Menu } from "antd";

// Styles
import s from "./index.module.scss";

// Initial Data
import DashboardMenu from "data/dashboard-layout";

const { Sider } = Layout;

function DashboardLayoutSider(props) {
  const { collapsed, setCollapsed } = props;
  const history = useHistory();
  const location = useLocation();

  //? ============== Handle Menu Highlight ============= ?//
  const [currentUrl, setCurrentUrl] = useState(false);
  const handleSelectMenu = ({ key }) => {
    setCurrentUrl(key);
  };
  useEffect(() => {
    setCurrentUrl(location.pathname);
  }, [location.pathname]);
  // * ====================================== * //

  //? ============== Handle Dashboard Menu ============= ?//
  const dashboardMenu = DashboardMenu();
  // * ====================================== * //

  return (
    <>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className={s.sider}
      >
        <div
          className={s.logoContainer}
          onClick={() => {
            history.push("/");
          }}
        >
          <img
            src={collapsed ? "/logo-icon.png" : "/logo.png"}
            className={s.logo}
            alt="logo"
            data-test="dashboard-logo"
          />
        </div>
        <Menu
          mode="inline"
          className={s.menuContainer}
          selectedKeys={[currentUrl || `${location.pathname}`]}
          onSelect={handleSelectMenu}
        >
          {dashboardMenu.map((item) => {
            return (
              <>
                <Menu.Item icon={item.icon} key={item.key}>
                  <div data-testId={item.dataTestId}>
                    <Link to={item.link}>{item.label}</Link>
                  </div>
                </Menu.Item>
              </>
            );
          })}
        </Menu>
      </Sider>
    </>
  );
}

DashboardLayoutSider.propTypes = {
  collapsed: propTypes.bool,
  setCollapsed: propTypes.func,
};

export default DashboardLayoutSider;
