// Libs
import React, { useState } from "react";
import { Avatar, Badge, Col, Dropdown, Layout, Row, Select, Spin } from "antd";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams, useHistory } from "react-router-dom";

// Components
import DashboardHeaderDropdown from "./dropdown";
import MainButton from "components/libs/button";

// Hooks
import { useSDKUsers } from "hooks/user-sdk";

// Icons
import { BellOutlined, PlusSquareOutlined } from "@ant-design/icons";

// Styles
import s from "./index.module.scss";
import DashboardCreateTenantModal from "./create-tenant-modal";

const { Header } = Layout;

function DashboardLayoutHeader() {
  const { user } = useAuth0();
  const history = useHistory();
  const params = useParams();

  //? ============== Hooks ============= ?//
  const { data: userDetails, loading } = useSDKUsers({
    externalUserId: user.sub,
  });
  const defaultTenant = userDetails?.tenants?.find(
    (item) => item.defaultTenant
  );

  // * ====================================== * //

  //? ============== Handle Select ============= ?//
  const handleSelectTenant = async (value) => {
    history.push(`/dashboard/${value}`);
  };
  // * ====================================== * //

  //? ============== Handle Create Tenant Modal ============= ?//
  const [createTenantModal, setCreateTenantModal] = useState(false);
  const handleCreateTenantModal = () => {
    setCreateTenantModal(!createTenantModal);
  };
  // * ====================================== * //

  return (
    <>
      <Header className={`${s.header}`}>
        <Row justify="end" gutter={[16, 0]}>
          <Row className={`${s.container}`}>
            <Col>
              <Select
                style={{ minWidth: 200 }}
                dropdownStyle={{ padding: 0 }}
                popupMatchSelectWidth={120}
                listHeight={128}
                onChange={handleSelectTenant}
                options={userDetails?.tenants?.map((item) => {
                  return {
                    label: `${item.displayName} ${
                      item.tenantId === defaultTenant.tenantId
                        ? "(default)"
                        : ""
                    }`,
                    value: item.tenantName,
                    disabled: item.tenantName === params.tenant,
                  };
                })}
                defaultValue={params.tenant}
                dropdownRender={(menu) => {
                  return (
                    <>
                      <Spin spinning={loading}>{menu}</Spin>
                      <Col
                        span={24}
                        style={{
                          paddingRight: 0,
                          paddingLeft: 0,
                        }}
                      >
                        <MainButton
                          type="dashed"
                          style={{
                            padding: "5px 15px",
                            width: "100%",
                            borderRadius: "0px",
                            fontSize: "14px",
                          }}
                          onClick={handleCreateTenantModal}
                        >
                          <span
                            style={{
                              marginRight: 5,
                              top: "-3px",
                              position: "relative",
                            }}
                          >
                            <PlusSquareOutlined />
                          </span>
                          Create Tenant
                        </MainButton>
                      </Col>
                    </>
                  );
                }}
              />
            </Col>
          </Row>
          <Row className={`${s.container}`} gutter={[16, 0]}>
            <Col className={s.notification}>
              <Badge dot>
                <BellOutlined />
              </Badge>
            </Col>
            <Col className={s.avatarContainer}>
              <Dropdown
                overlay={<DashboardHeaderDropdown />}
                placement="bottomRight"
                arrow
              >
                <Avatar
                  size="default"
                  className={s.avatar}
                  src={user.picture}
                />
              </Dropdown>
            </Col>
          </Row>
        </Row>
      </Header>

      {createTenantModal && (
        <DashboardCreateTenantModal
          isShow={createTenantModal}
          onCancel={handleCreateTenantModal}
        />
      )}
    </>
  );
}

export default DashboardLayoutHeader;
