// Libs
import React from "react";
import { createContext, useContext } from "react";

// #region Initial Context Value
const SessionContext = createContext({
  session: "",
});
// #endregion

// #region Initial Context Providers
export function SessionContextProvider(props) {
  const context = {
    session: "",
  };

  return (
    <SessionContext.Provider value={context}>
      {props.children}
    </SessionContext.Provider>
  );
}
// #endregion
export default SessionContextProvider;

// #region Context Hook Preparation
export const useSessionCtx = () => useContext(SessionContext);
// #endregion
