// Libs
import React, { useState } from "react";
import { Collapse } from "antd";
import propTypes from "prop-types";
import { PlusOutlined, MinusOutlined } from "@ant-design/icons";

export default function MainAccordion(props) {
  const { title, text, defaultCollapse = false } = props;
  const { Panel } = Collapse;

  const [collapse, setCollapse] = useState(defaultCollapse);
  const handleChange = (e) => {
    setCollapse(!collapse);
  };
  return (
    <Collapse
      expandIconPosition="end"
      onChange={handleChange}
      expandIcon={() => (!collapse ? <PlusOutlined /> : <MinusOutlined />)}
      defaultActiveKey={defaultCollapse && 1}
    >
      <Panel header={title} key={defaultCollapse && 1}>
        {text}
      </Panel>
    </Collapse>
  );
}

MainAccordion.propTypes = {
  title: propTypes.string,
  text: propTypes.string,
  defaultCollapse: propTypes.bool,
};
