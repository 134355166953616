// Libs
import React from "react";
import moment from "moment-timezone";
import propTypes from "prop-types";
import { Descriptions, Skeleton, Tag } from "antd";
import { useSDKTenants } from "hooks/tenants-sdk";
import { useParams } from "react-router-dom";

function DashboardSettingsGeneral(props) {
  const params = useParams();

  //? ============== Tenant Hooks ============= ?//
  const { data: tenantDetails } = useSDKTenants({ tenantName: params.tenant });
  // * ====================================== * //

  return (
    <>
      {!tenantDetails && (
        <div>
          <Skeleton />
        </div>
      )}
      {tenantDetails && (
        <section>
          <Descriptions title={"Tenant Info"}>
            <Descriptions.Item label="Name">
              {tenantDetails?.displayName}
            </Descriptions.Item>
            <Descriptions.Item label="Subscription ID">
              {tenantDetails.subscriptionId || "-"}
            </Descriptions.Item>
            <Descriptions.Item label="Plan">
              {
                <Tag
                  color={
                    tenantDetails?.plan?.includes("advance")
                      ? "magenta"
                      : tenantDetails?.plan?.includes("pro")
                      ? "blue"
                      : tenantDetails?.plan?.includes("enterprise")
                      ? "gold"
                      : "default"
                  }
                >
                  {tenantDetails?.plan?.includes("advance")
                    ? "ADVANCE"
                    : tenantDetails?.plan?.includes("pro")
                    ? "PRO"
                    : tenantDetails?.plan?.includes("enterprise")
                    ? "ENTERPRISE"
                    : "FREE"}
                </Tag>
              }
              {tenantDetails.interval ? (
                tenantDetails.interval === "monthly" ? (
                  <Tag color="blue">Monthly</Tag>
                ) : (
                  <Tag color="gold">Yearly</Tag>
                )
              ) : (
                ""
              )}
            </Descriptions.Item>
            <Descriptions.Item label="Start Billing">
              {tenantDetails.currentPeriodStart
                ? moment(tenantDetails.currentPeriodStart).format("YYYY-MM-DD")
                : "-"}
            </Descriptions.Item>
            <Descriptions.Item label="End Billing">
              {tenantDetails.currentPeriodEnd
                ? moment(tenantDetails.currentPeriodEnd).format("YYYY-MM-DD")
                : "-"}
            </Descriptions.Item>
          </Descriptions>
        </section>
      )}
    </>
  );
}

DashboardSettingsGeneral.propTypes = {
  currentTenant: propTypes.any,
};

export default DashboardSettingsGeneral;
