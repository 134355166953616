// Libs
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Badge,
  Empty,
  Form,
  Input,
  Button,
  Radio,
  Select,
  Spin,
  Tag,
  Tooltip,
  Col,
  Row,
} from "antd";
import { useParams } from "react-router-dom";
import { isIPV6Address, isIPV4Address } from "ip-address-validator";

// Hooks
import {
  useSDKCredentials,
  useSDKCredentialsGcpInfo,
} from "hooks/credentials-sdk";

// Icons
import { QuestionCircleOutlined } from "@ant-design/icons";

// Styles
import s from "./index.module.scss";

// Data
import { credentialsDataSample } from "data/control-panel";
import { useSDKFirewallDestination } from "hooks/firewall-destination-sdk";

// Assets
import { firewallDataSample } from "data/control-panel";

function ModalAddGcpFirewall(props) {
  const { handleVisible, isEdit = false, initialValues } = props;
  const params = useParams();
  const tenantName = params.tenant;

  //#region Handle credentials hooks
  const { data: listOfCredentials } = useSDKCredentials({
    tenantName: tenantName,
    type: "gcp",
  });
  const credentialList = !tenantName.includes("<")
    ? listOfCredentials
    : credentialsDataSample;
  //#endregion

  //#region Handle firewall hooks
  const {
    data: listOfFirewall,
    loading: listOfFirewallLoading,
    onUpdate,
    onAdd,
  } = useSDKFirewallDestination({ tenantName: tenantName });
  const firewallData = !tenantName.includes("<")
    ? listOfFirewall
    : firewallDataSample;
  //#endregion

  //#region Handle Already Used Firewall
  const [firewallUsed, setFirewallUsed] = useState([]);
  useEffect(() => {
    if (!listOfFirewallLoading) {
      setFirewallUsed(firewallData?.map((item) => item.firewallRuleName));
    }
  }, [firewallData, listOfFirewallLoading]);
  //#endregion

  //#region Credentials GCP Info Hooks
  const [selectedCredentials, setSelectedCredentials] = useState("");

  //? Handle Select Credentials
  const handleSelectCredentials = (value) => {
    const cloudDetails = credentialList.find((item) => item.cloudId === value);
    setSelectedCredentials(cloudDetails);
  };

  //? GCP Info Hooks
  const { data: gcpInfoList, loading: gcpInfoListLoading } =
    useSDKCredentialsGcpInfo({ credentials: selectedCredentials });
  //#endregion

  //#region Handle Select Firewall Rules
  const [selectProjectId, setSelectProjectId] = useState("");
  const [firewallRulesList, setFirewallRulesList] = useState([]);

  //? Select firewall rules handler
  const handleSelectProjectId = (value) => {
    setSelectProjectId(value);
    form.setFieldValue("firewallName", null);
  };

  //? Get Firewall Rules
  useEffect(() => {
    if (selectProjectId) {
      setFirewallRulesList(
        gcpInfoList
          ? gcpInfoList?.gcpProjectList?.find(
              (item) => item.projectId === selectProjectId
            )?.firewallList
          : []
      );
    }
  }, [gcpInfoList, selectProjectId]);

  //#endregion

  //#region Handle Firewall Version
  const [firewallVersion, setFirewallVersion] = useState(false);

  //? Handle Select Firewall Rules Name
  const handleSelectFirewallRulesName = (value) => {
    const selectedFirewallVersion = firewallRulesList.find(
      (item) => item.firewallName === value
    ).ipAddressType;
    setFirewallVersion(selectedFirewallVersion);
  };
  //#endregion

  //#region Handle Submit
  const [form] = Form.useForm();
  const handleSubmit = () => {
    form.validateFields().then(async (value) => {
      try {
        if (!isEdit) {
          await onAdd({
            ...value,
            firewallIpVersion: firewallVersion,
          });
        } else {
          await onUpdate({
            id: initialValues.firewallDestinationId,
            submission: {
              ...value,
              firewallIpVersion: firewallVersion,
            },
          });
        }
      } catch (error) {
        return;
      } finally {
        handleVisible();
        form.resetFields();
      }
    });
  };
  //#endregion

  //#region Handle Initial Values Update
  const [initialValuesParse, setInitialValuesParse] = useState("");

  //? Set Parsing Initial Value
  useEffect(() => {
    if (isEdit) {
      setInitialValuesParse({
        credentialName: initialValues.cloudId,
        projectId: initialValues.projectName,
        firewallName: initialValues.firewallRuleName,
        placeholderIpAddress: initialValues.placeholderIpAddress,
        updateMode: initialValues.updateMode || "APPEND",
      });
    } else {
      setInitialValuesParse({ updateMode: "APPEND" });
    }
  }, [initialValues, isEdit]);

  //? Set Initial State
  useEffect(() => {
    if (credentialList.length !== 0 && isEdit) {
      const cloudDetails = credentialList.find(
        (item) => item.cloudId === initialValues.cloudId
      );
      //? Set initial credentials selected
      setSelectedCredentials(cloudDetails);

      //? Set initial project id
      setSelectProjectId(initialValues.projectName);

      //? Set initial list of firewall
      setFirewallRulesList(
        gcpInfoList?.gcpProjectList?.find(
          (item) => item.projectId === initialValues.projectName
        )?.firewallList
      );
    }
  }, [credentialList, firewallVersion, gcpInfoList, initialValues, isEdit]);

  //? Set Firewall Version
  useEffect(() => {
    if (!firewallVersion) {
      setFirewallVersion(
        firewallRulesList?.find(
          (item) => item.firewallName === initialValues.firewallRuleName
        )?.ipAddressType
      );
    }
  }, [firewallRulesList, firewallVersion, initialValues.firewallRuleName]);
  //#endregion

  //#region Handle placeholder IP address
  useEffect(() => {
    if (!isEdit) {
      form.setFieldValue(
        "placeholderIpAddress",
        firewallVersion === "IPv4"
          ? "127.0.0.1"
          : firewallVersion === "IPv6"
          ? "::1"
          : ""
      );
    }
  }, [firewallVersion, form, isEdit]);
  //#endregion

  return (
    <>
      {initialValuesParse && (
        <Spin
          spinning={
            isEdit
              ? listOfFirewallLoading && !firewallVersion
              : listOfFirewallLoading
          }
        >
          <Form
            form={form}
            layout="vertical"
            initialValues={initialValuesParse}
          >
            <Form.Item
              label="Credentials"
              name={"credentialName"}
              rules={[{ required: true, message: "Please select credentials" }]}
            >
              <Select
                options={credentialList?.map((item, index) => {
                  return {
                    label: (
                      <>
                        <div className={s.cred_label} key={index}>
                          <div>
                            {item.cloudId} ({item.location})
                          </div>
                          <div style={{ justifySelf: "flex-end" }}>
                            {item.status === "VALID" ? (
                              <Badge status="success" text="Valid" />
                            ) : (
                              <Badge
                                status="warning"
                                text={item.status
                                  .replace(/_/g, " ")
                                  .toLowerCase()}
                                style={{ textTransform: "capitalize" }}
                              />
                            )}
                          </div>
                        </div>
                      </>
                    ),
                    value: item.cloudId,
                  };
                })}
                placeholder="Select credentials"
                showSearch={true}
                onChange={handleSelectCredentials}
                data-testId="modal-add-firewall-credentials"
              />
            </Form.Item>
            <Form.Item
              label="Project Id"
              name={"projectId"}
              rules={[{ required: true, message: "Please select project ID" }]}
            >
              <Select
                options={gcpInfoList?.gcpProjectList?.map((item) => {
                  return { label: item.projectId, value: item.projectId };
                })}
                placeholder="Select project id"
                disabled={!selectedCredentials}
                onChange={handleSelectProjectId}
                notFoundContent={
                  <div style={{ width: "100%" }}>
                    <Spin spinning={gcpInfoListLoading}>
                      <Empty />
                    </Spin>
                  </div>
                }
                data-testId="modal-add-firewall-project-id"
              />
            </Form.Item>
            <Form.Item
              label="Firewall Rules Name"
              name={"firewallName"}
              rules={[
                {
                  required: true,
                  message: "Please select firewalls rules name",
                },
              ]}
            >
              <Select
                options={
                  firewallRulesList &&
                  firewallRulesList?.map((item, index) => {
                    const status = !!firewallUsed.find(
                      (target) => target === item.firewallName
                    );
                    return {
                      label: (
                        <>
                          {item.firewallName} ({item.ipAddressType}){" "}
                          {status && (
                            <span
                              style={{
                                color: "#1890ff",
                                fontStyle: "italic",
                              }}
                              key={index}
                            >
                              Used
                            </span>
                          )}
                        </>
                      ),
                      value: item.firewallName,
                      disabled: status,
                    };
                  })
                }
                placeholder="Select firewall rules name"
                disabled={!selectProjectId}
                onChange={handleSelectFirewallRulesName}
                notFoundContent={
                  <div style={{ width: "100%" }}>
                    <Spin spinning={gcpInfoListLoading}>
                      <Empty />
                    </Spin>
                  </div>
                }
              />
            </Form.Item>
            <Form.Item
              label={
                <>
                  Placeholder IP Address{" "}
                  <span
                    style={{
                      marginLeft: 5,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Tooltip
                      title={
                        <p style={{ margin: 0 }}>
                          This IP placeholder will be used as a default when
                          this firewall didn't have any IP
                        </p>
                      }
                    >
                      <QuestionCircleOutlined />
                    </Tooltip>
                  </span>
                </>
              }
              name={"placeholderIpAddress"}
              rules={[
                {
                  required: true,
                  message: `Placeholder IP Address must be ${firewallVersion}`,
                  validator: (_, value) => {
                    let validIP = false;
                    if (firewallVersion === "IPv4") {
                      validIP = isIPV4Address(value);
                    } else {
                      validIP =
                        isIPV6Address(value) || new RegExp(/::\d/).test(value);
                    }

                    if (validIP) {
                      return Promise.resolve();
                    } else {
                      return Promise.reject(
                        new Error(
                          `Placeholder IP Address must be ${firewallVersion}`
                        )
                      );
                    }
                  },
                },
              ]}
            >
              <Input
                placeholder={`input IP placeholder here  (ex.${
                  firewallVersion === "IPv4"
                    ? "127.0.0.1"
                    : firewallVersion === "IPv6"
                    ? "::1"
                    : "127.0.0.1/::1"
                })`}
                disabled={!firewallVersion}
                addonBefore={firewallVersion ? firewallVersion : "IPv4/IPv6"}
              />
            </Form.Item>
            <Form.Item label="Update Mode" name={"updateMode"}>
              <Radio.Group>
                <Radio value={"APPEND"}>
                  <Tag color="cyan">Append</Tag>
                </Radio>
                <Radio value={"TRACK"}>
                  <Tag color="orange">Track</Tag>
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <Col span={24}>
            <Row align={"middle"} justify={"end"}>
              <Button onClick={handleSubmit} type="primary">
                Save
              </Button>
            </Row>
          </Col>
        </Spin>
      )}
    </>
  );
}

ModalAddGcpFirewall.propTypes = {
  handleVisible: propTypes.func,
  isEdit: propTypes.bool,
  initialValues: propTypes.any,
};

export default ModalAddGcpFirewall;
