import { notification } from "antd";

export default function NotificationErrorSomethingWrong(message) {
  notification.error({
    message: "Oops! Something went wrong",
    description:
      message ||
      "Please try again, and don't hesitate to contact us if you encounter any further errors.",
  });
}
