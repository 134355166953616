import { Col, Input, Row } from "antd";
import MainButton from "components/libs/button";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import s from "./index.module.scss";
import { getConfig } from "config";
import { useCookies } from "react-cookie";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import MainLoading from "components/libs/loading";

function ShowTokenPage() {
  const history = useHistory();
  const config = getConfig();
  const { 0: cookie } = useCookies(["access_token"]);
  const token = cookie.access_token;

  console.log(config);

  useEffect(() => {
    if (config.devMode !== "true") {
      history.push("/");
    }
  }, [config.devMode, history]);

  return (
    <>
      <div className={s.section}>
        <div className={s.container}>
          <Row align={"middle"} justify={"center"}>
            <Col span={24} style={{ marginBottom: 30, textAlign: "center" }}>
              <h2>Token</h2>
              <Col span={16} style={{ margin: "0 auto" }}>
                <Input.TextArea value={token} readOnly autoSize />
              </Col>
            </Col>
            <Col span={24} style={{ textAlign: "center" }}>
              <MainButton
                onClick={() => {
                  history.push("/");
                }}
              >
                Back to homepage
              </MainButton>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default withAuthenticationRequired(ShowTokenPage, {
  onRedirecting: () => <MainLoading />,
});
