// Libs
import React from "react";
import propTypes from "prop-types";

// Styles
import s from "./index.module.scss";

function MainLoading(props) {
  return (
    <>
      <div className={s.spinner}>
        <img src="/loading.svg" alt="Loading" />
      </div>
    </>
  );
}

MainLoading.propTypes = {
  loading: propTypes.bool,
};

export default MainLoading;
