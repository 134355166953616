// Libs
import React from "react";
import { Card } from "antd";

// Contents
import DashboardContentsDeviceManageLink from "components/contents/dashboard/device-manage-link";

// Styles
import s from "./index.module.scss";

function DashboardDeviceManageLink() {
  return (
    <>
      <Card className={s.container}>
        <section className={s.section}>
          <DashboardContentsDeviceManageLink />
        </section>
      </Card>
    </>
  );
}

export default DashboardDeviceManageLink;
