// Libs
import React, { useState } from "react";
import propTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { Col, Collapse, Divider, Row, Segmented, Spin, Tooltip } from "antd";

// Hooks
import { useZohoSubscriptions } from "hooks/zoho-integration-sdk";

// Components
import PricingListItem from "components/libs/price-list-item";

// Styles
import s from "./index.module.scss";
import { pricingContents } from "data/homepage";

// Icons
import {
  CheckCircleFilled,
  CloseCircleFilled,
  InfoCircleFilled,
} from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useSDKTenants } from "hooks/tenants-sdk";
import { stringCapitalize } from "utils/stringCapitalize";

function DashboardSettingsSubscription(props) {
  const params = useParams();
  const { user } = useAuth0();
  const { Panel } = Collapse;

  //? ============== Tenants Hook ============= ?//
  const { data: tenantDetails } = useSDKTenants({ tenantName: params.tenant });
  // * ====================================== * //

  //? ============== Subscription Hooks ============= ?//
  const {
    onCreateSubscription,
    onUpdateSubscription,
    loading: onSubscriptionLoading,
  } = useZohoSubscriptions({ subscriptionId: "" });
  // * ====================================== * //

  //? ============== Handle Segment ============= ?//
  const [segmentSelected, isSegmentSelected] = useState("Monthly");
  const handleSegment = (value) => {
    isSegmentSelected(value);
  };
  // * ====================================== * //

  //? ============== Handle Subscription ============= ?//
  const handleSubscription = (planCode) => {
    if (tenantDetails?.subscriptionId) {
      const submission = {
        planCode: planCode,
        subscriptionId: tenantDetails?.subscriptionId,
        addOns: [],
      };
      onUpdateSubscription(submission);
    } else {
      const submission = {
        email: user.email,
        tenantName: params.tenant,
        planCode: planCode,
      };
      onCreateSubscription(submission);
    }
  };
  // * ====================================== * //

  const priceListType = ["advanced", "pro", "enterprise"];

  return (
    <>
      <Col span={24} className={s.selection}>
        <Segmented
          options={["Monthly", "Annualy"]}
          onChange={handleSegment}
          defaultValue={"Monthly"}
        />
      </Col>
      <Spin spinning={onSubscriptionLoading}>
        <div style={{ padding: "0 30px", marginTop: "30px" }}>
          <Row gutter={[32, 0]}>
            {pricingContents.modes
              .filter((item) => item.name !== "Free")
              .map((item, index) => {
                return (
                  <Col span={8} key={index}>
                    <PricingListItem
                      title={item.name}
                      price={
                        segmentSelected === "Monthly"
                          ? item.monthly_price
                          : item.annually_price
                      }
                      description={item.subHead}
                      logo={item.logo}
                      features={item.features}
                      planCode={
                        segmentSelected === "Monthly"
                          ? item.monthly_plan_code
                          : item.annual_plan_code
                      }
                      onCheckout={handleSubscription}
                      currentPlan={tenantDetails?.plan || "Free"}
                      interval={
                        segmentSelected === "Monthly" ? "Month" : "Year"
                      }
                    />
                  </Col>
                );
              })}
          </Row>
        </div>
      </Spin>

      <section className={s.priceDetailsSection}>
        <Row>
          <Col span={6} />
          {priceListType.map((item) => {
            return (
              <Col
                span={6}
                className={`${
                  tenantDetails?.plan?.includes(item) ? s.selectedPlan : ""
                } ${s.planTitle}`}
              >
                <h1>{stringCapitalize(item)}</h1>
              </Col>
            );
          })}
        </Row>
        <Collapse collapsible="header" defaultActiveKey={["0"]}>
          {pricingContents.subHeads.map((item, index) => {
            return (
              <Panel header={item.name} key={[index]} className={s.panel}>
                {item.properties.map((property, propIndex) => {
                  return (
                    <div className={s.detailsItemsContainer} key={propIndex}>
                      <Row gutter={[16, 0]} align={"middle"}>
                        <Col span={6}>
                          <div className={s.tableTitle}>
                            {property.title}{" "}
                            {property.description ? (
                              <span>
                                <Tooltip title={property.description}>
                                  <InfoCircleFilled />
                                </Tooltip>
                              </span>
                            ) : null}
                          </div>
                        </Col>
                        {priceListType.map((type) => {
                          return (
                            <Col
                              span={6}
                              className={`${
                                tenantDetails?.plan?.includes(type)
                                  ? s.selectedPlan
                                  : ""
                              } ${s.planDetails}`}
                            >
                              <div className={s.detailsItems}>
                                {pricingContents?.[stringCapitalize(type)]?.[
                                  item.name
                                ]?.[property.title] === true ? (
                                  <div className={s.checkIcon}>
                                    <CheckCircleFilled />
                                  </div>
                                ) : pricingContents?.[stringCapitalize(type)]?.[
                                    item.name
                                  ]?.[property.title] === false ? (
                                  <div className={s.crossIcon}>
                                    <CloseCircleFilled />
                                  </div>
                                ) : (
                                  <p>
                                    {
                                      pricingContents?.[
                                        stringCapitalize(type)
                                      ]?.[item.name]?.[property.title]
                                    }
                                  </p>
                                )}
                              </div>
                            </Col>
                          );
                        })}
                      </Row>
                      <Divider className={s.divider} />
                    </div>
                  );
                })}
              </Panel>
            );
          })}
        </Collapse>
      </section>
    </>
  );
}

DashboardSettingsSubscription.propTypes = {
  currentTenant: propTypes.any,
};

export default DashboardSettingsSubscription;
