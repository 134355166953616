// Libs
import React from "react";
import { Spin } from "antd";

// Components
import MainAddOnsItem from "components/libs/addOns-item";

// Hooks
import { useZohoSubscriptionsAddOns } from "hooks/zoho-integration-sdk";

// Styles
import s from "./index.module.scss";

function MemberPageContentsAddOnsSection() {
  //? ============== Add Ons Hooks ============= ?//
  const { data: addOnsList, loading: addOnsLoading } =
    useZohoSubscriptionsAddOns();
  // * ====================================== * //

  return (
    <>
      <Spin spinning={addOnsLoading}>
        <section className={s.mainSection}>
          {addOnsList.map((item, index) => {
            return (
              <section className={s.section} key={index}>
                <MainAddOnsItem
                  title={item.name}
                  price={item.price}
                  type={item.type}
                  key={index}
                  description={item.description}
                  priceId={item.price_id}
                  addonCode={item.addonCode}
                  addonKey={item.key}
                />
              </section>
            );
          })}
        </section>
      </Spin>
    </>
  );
}

export default MemberPageContentsAddOnsSection;
