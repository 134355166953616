export const free = {
  credentials_limit: 1,
  device_limit: 1,
  firewall_destination_limit: 1,
};

export const advance = {
  credentials_limit: 3,
  device_limit: 3,
  firewall_destination_limit: 3,
};

export const pro = {
  credentials_limit: 10,
  device_limit: 10,
  firewall_destination_limit: 10,
};

export const planLimitation = {
  free: {
    credentials_limit: 1,
    device_limit: 1,
    firewall_destination_limit: 1,
  },
  advance: {
    credentials_limit: 3,
    device_limit: 3,
    firewall_destination_limit: 3,
  },
  pro: {
    credentials_limit: 10,
    device_limit: 10,
    firewall_destination_limit: 10,
  },
};
