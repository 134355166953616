// Libs
import React, { useState } from "react";
import { Button, Spin, Table } from "antd";
import { useParams } from "react-router-dom";

// Components
import { ControlPanelFirewallColumns } from "./utils";
import ModalAddFirewall from "components/libs/modal/add-firewall";
import PageHeader from "components/libs/page-header";

// Styles
import s from "./index.module.scss";

// Hooks
import { useSDKFirewallDestination } from "hooks/firewall-destination-sdk";

// Assets
import { firewallDataSample } from "data/control-panel";

function DashboardControlPanelFirewall(props) {
  const params = useParams();
  const tenantName = params.tenant;

  //#region Firewall Hooks
  const {
    data: listOfFirewall,
    loading,
    onDelete,
  } = useSDKFirewallDestination({
    tenantName: tenantName,
  });
  const firewallData = !tenantName.includes("<")
    ? listOfFirewall
    : firewallDataSample;

  //#endregion

  //#region Handle Add Modal
  const [showAddModal, setShowAddModal] = useState(false);
  const handleShowAddModal = () => {
    setIsEdit(false);
    setInitialValues("");
    setShowAddModal(!showAddModal);
  };
  //#endregion

  //#region Handle Update
  const [isEdit, setIsEdit] = useState(false);
  const [initialValues, setInitialValues] = useState("");

  const handleShowEditModal = (data) => {
    setIsEdit(true);
    setInitialValues(data);
    setShowAddModal(!showAddModal);
  };
  //#endregion

  //#region Handle Columns
  const columns = ControlPanelFirewallColumns({
    onDelete,
    onUpdate: handleShowEditModal,
  });
  //#endregion

  return (
    <>
      <PageHeader
        title="Firewall Destination"
        className={s.header}
        extra={[
          <Button size="large" onClick={handleShowAddModal}>
            + Add Firewall
          </Button>,
        ]}
      />
      <Spin spinning={loading}>
        {firewallData && (
          <Table
            columns={columns}
            dataSource={firewallData}
            tableLayout="auto"
            scroll={{ x: 1200 }}
            onChange={() => {
              console.log("some changes");
            }}
          />
        )}
      </Spin>

      {showAddModal && (
        <ModalAddFirewall
          visible={showAddModal}
          handleVisible={handleShowAddModal}
          isEdit={isEdit}
          initialValues={initialValues}
        />
      )}
    </>
  );
}

export default DashboardControlPanelFirewall;
