import React from "react";
import { Col, Row } from "antd";
import s from "./index.module.scss";

export default function PageHeader(props) {
  const { title, extra = [], className } = props;
  return (
    <>
      <Col span={24} className={`${className} `}>
        <Row justify={"space-between"} align={"middle"}>
          <Col style={{ display: "flex", alignItems: "center" }}>
            <h1 className={s.title}>{title}</h1>
          </Col>
          <Col>
            <Row gutter={[16, 0]}>
              {extra.map((item, index) => {
                return <Col key={index}>{item}</Col>;
              })}
            </Row>
          </Col>
        </Row>
      </Col>
    </>
  );
}
