import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import s from "./index.module.scss";
import propTypes from "prop-types";

export default function CompLibsModalTitle(props) {
  const { children, handleBack } = props;
  return (
    <div className={s.title}>
      {handleBack ? (
        <span
          onClick={() => {
            handleBack();
          }}
        >
          <ArrowLeftOutlined />
        </span>
      ) : (
        <></>
      )}
      {children}
    </div>
  );
}

CompLibsModalTitle.propTypes = {
  children: propTypes.node,
  handleBack: propTypes.func,
};
