import { Col, Modal } from "antd";
import React from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import propTypes from "prop-types";
import s from "./index.module.scss";
import MainButton from "components/libs/button";
import { useHistory } from "react-router-dom";

export default function ModalPlanSuggestion(props) {
  const { topic = "", open, setOpen, tenantName } = props;
  const history = useHistory();

  //#region Handle close
  const handleClose = () => {
    Modal.destroyAll();
    setOpen(false);
  };
  //#endregion

  //#region Handle navigate to change plan
  const handleNavigation = () => {
    handleClose();
    history.push(`/dashboard/${tenantName}/settings/subscription`);
  };
  //#endregion

  return (
    <>
      <Modal footer={false} open={open} onCancel={handleClose}>
        <Col style={{ margin: "20px 0" }}>
          <Col className={s.iconContainer}>
            <InfoCircleFilled className={s.icon} />
          </Col>
          <Col>
            <Col className={s.textContainer}>
              <h1>{topic} Limit Reached</h1>
              <p>
                Sorry, you can't add another {topic.toLowerCase()}.<br /> To add
                a new {topic.toLowerCase()}, please upgrade your plan or remove
                an existing {topic.toLowerCase()}.
              </p>
            </Col>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <MainButton
              onClick={() => {
                handleNavigation();
              }}
            >
              Change Plan
            </MainButton>
          </Col>
        </Col>
      </Modal>
    </>
  );
}

ModalPlanSuggestion.propTypes = {
  topic: propTypes.string,
  open: propTypes.bool,
  setOpen: propTypes.func,
  tenantName: propTypes.string,
};
