// Libs
import React, { useState, useEffect } from "react";
import {
  Collapse,
  Container,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  Button,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

// Initial Data
import { headerMenuList } from "data/header";

// Hooks
import { useSDKAuth } from "hooks/auth-sdk";

// Styles
import s from "./index.module.scss";

// Icons
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const MainHeader = () => {
  const history = useHistory();
  const location = useLocation();
  const { user, isAuthenticated, loginWithRedirect } = useAuth0();

  //? ============== Auth Hook ============= ?//
  const { authorizationLogout } = useSDKAuth();
  // * ====================================== * //

  //? ============== Handle Toggle ============= ?//
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  // * ====================================== * //

  //? ============== Handle Scroll Behavior ============= ?//
  useEffect(() => {
    if (location.hash) {
      const element = document.querySelector(`${location.hash}`);
      element && setTimeout(() => element.scrollIntoView(), 100);
    }
  }, [location.hash]);
  // * ====================================== * //

  return (
    <div className="nav-container">
      <Navbar color="#F3F3EF" light expand="md">
        <Container>
          <NavbarBrand
            onClick={() => history.push("/")}
            style={{ cursor: "pointer" }}
          >
            <img alt="logo" src="logo.png"></img>
          </NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="pr-4 flex-fill" navbar justified>
              {headerMenuList.map((item, index) => {
                return (
                  <NavItem
                    className={`nav-bar-item ${s.navBarItem}`}
                    key={index}
                  >
                    <NavLink
                      onClick={() => {
                        history.push(`${item.link}`);
                      }}
                      activeClassName="router-link-exact-active"
                    >
                      {item.label}
                    </NavLink>
                  </NavItem>
                );
              })}
              {!isAuthenticated && (
                <NavItem className={`nav-bar-item ${s.navBarItem}`}>
                  <NavLink
                    activeClassName="router-link-exact-active"
                    onClick={() => loginWithRedirect()}
                  >
                    Sign in
                  </NavLink>
                </NavItem>
              )}
            </Nav>
            <Nav className="d-none d-md-block" navbar>
              {!isAuthenticated && (
                <NavItem>
                  <Button
                    color="warn"
                    className="primary-button button-padding"
                    onClick={() => loginWithRedirect({ screen_hint: "signup" })}
                  >
                    Sign up
                  </Button>
                </NavItem>
              )}
              {isAuthenticated && (
                <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav caret id="profileDropDown">
                    <img
                      src={user.picture}
                      alt="Profile"
                      className="nav-user-profile rounded-circle"
                      width="50"
                    />
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem header>{user.name}</DropdownItem>
                    <DropdownItem
                      className="dropdown-profile"
                      activeClassName="router-link-exact-active"
                      onClick={() => history.push("/profile")}
                    >
                      <FontAwesomeIcon icon="user" className="mr-3" /> Profile
                    </DropdownItem>
                    {/* <DropdownItem
                      id="customerPortalBtn"
                      onClick={() => requestCustomerPortalRedirect()}
                    >
                      Stripe Customer Portal
                    </DropdownItem> */}
                    <DropdownItem onClick={() => authorizationLogout()}>
                      <FontAwesomeIcon icon="power-off" className="mr-3" />
                      Sign out
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              )}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default MainHeader;
