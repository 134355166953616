//? Web Portal SDK
import {
  Configuration,
  RestAPIUserApi,
  RestAPITenantUserApi,
  RestAPITenantApi,
  RestAPICredentialApi,
  RestAPIDeviceApi,
  RestAPIFirewallDestinationApi,
  RestAPIManageLinkApi,
  RestAPIForDashboardApi,
} from "@allowexactly-npm/webportal-sdk/lib";

import { getConfig } from "config";
import { Cookies } from "react-cookie";

//? General Config
const { apiOrigin } = getConfig();

//? Authorization
const cookie = new Cookies();
const accessToken = cookie.get("access_token");

//? Configuration
const configuration = new Configuration({
  basePath: apiOrigin,
  headers: {
    Authorization: `Bearer ${accessToken}`,
    // "x-mock-response-code": 200,
  },
});

//? Web Portal Generate use SDK
const userApi = new RestAPIUserApi(configuration);
const tenantApi = new RestAPITenantApi(configuration);
const tenantUserApi = new RestAPITenantUserApi(configuration);
const credentialsApi = new RestAPICredentialApi(configuration);
const deviceApi = new RestAPIDeviceApi(configuration);
const firewallDestinationApi = new RestAPIFirewallDestinationApi(configuration);
const manageLinkApi = new RestAPIManageLinkApi(configuration);
const dashboardApi = new RestAPIForDashboardApi(configuration);

const api = {
  userApi,
  tenantApi,
  tenantUserApi,
  credentialsApi,
  deviceApi,
  firewallDestinationApi,
  manageLinkApi,
  dashboardApi,
};

export default api;
