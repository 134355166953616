// Libs
import React from "react";
import { Menu } from "antd";
import { useHistory, useRouteMatch } from "react-router-dom";

// Styles
import s from "./index.module.scss";

// Icons
import {
  UserOutlined,
  SettingOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

// Hooks
import { useSDKAuth } from "hooks/auth-sdk";

function DashboardHeaderDropdown() {
  const history = useHistory();
  let { url } = useRouteMatch();

  //? ============== Handle Logout ============= ?//
  const { authorizationLogout } = useSDKAuth();
  // * ====================================== * //

  return (
    <>
      <Menu
        selectable={false}
        className={s.dropdownContainer}
        items={[
          {
            key: "1",
            label: (
              <div
                className={s.dropdownMenu}
                onClick={() => history.push(`${url}/profile`)}
              >
                <UserOutlined />
                Profile
              </div>
            ),
          },
          {
            key: "2",
            label: (
              <>
                <div
                  className={s.dropdownMenu}
                  onClick={() => history.push(`${url}/settings`)}
                >
                  <SettingOutlined />
                  Setting
                </div>
              </>
            ),
          },
          {
            type: "divider",
          },
          {
            key: "4",
            label: (
              <div
                className={s.dropdownMenu}
                onClick={() => authorizationLogout()}
              >
                <LogoutOutlined />
                Logout
              </div>
            ),
          },
        ]}
      />
    </>
  );
}

export default DashboardHeaderDropdown;
