// Libs
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// Components
import ModalAddAwsCredentials from "../add-aws-credentials";
import ModalAddAzureCredentials from "../add-azure-credentials";

// Data
import CompLibsCredentialsOptions from "components/libs/credentials-option";
import CompLibsModalTitle from "components/libs/modal-title";

function ModalAddCredentials(props) {
  const {
    visibility,
    handleVisible,
    handleAddGcp,
    handleAddAws,
    handleAddAzure,
    loading,
    initialInterface = "options",
  } = props;

  const params = useParams();
  const tenantName = params.tenant;
  const { user } = useAuth0();

  //#region Handle modal interface
  const [modalInterface, setModalInterface] = useState(initialInterface);
  const handleBack = () => {
    setModalInterface("options");
  };
  //#endregion

  //#region Handle modal interface by props
  useEffect(() => {
    setModalInterface(initialInterface);
    if (initialInterface === "gcp") {
      handleAddCredentials("gcp");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialInterface]);
  //#endregion

  //#region Handle Add Credentials
  const handleAddCredentials = async (key) => {
    //? Handle GCP
    if (key === "gcp") {
      try {
        handleAddGcp({
          redirectUri: `${window.location.origin}/dashboard/${tenantName}/credentials`,
          errorRedirectUri: `${window.location.origin}/dashboard/${tenantName}/retry-add-credential?retry=1`,
          email: user.email,
          tenantName: tenantName,
        });
      } catch (error) {
        return;
      } finally {
        handleVisible();
        setModalInterface("options");
      }
    }

    //? Handle AWS
    if (key === "aws") {
      setModalInterface("aws");
    }

    if (key === "azure") {
      setModalInterface("azure");
    }
  };
  //#endregion

  return (
    <>
      <Modal
        open={visibility}
        title={
          modalInterface === "aws" ? (
            <CompLibsModalTitle handleBack={() => setModalInterface("options")}>
              Add AWS Credentials
            </CompLibsModalTitle>
          ) : modalInterface === "azure" ? (
            <CompLibsModalTitle handleBack={() => setModalInterface("options")}>
              Add Azure Credentials
            </CompLibsModalTitle>
          ) : (
            "Select Credentials Type"
          )
        }
        onCancel={handleVisible}
        footer={false}
      >
        <Spin spinning={loading}>
          {modalInterface === "options" && (
            <CompLibsCredentialsOptions
              handleSelection={handleAddCredentials}
            />
          )}

          {modalInterface === "aws" && (
            <ModalAddAwsCredentials
              onAdd={handleAddAws}
              handleShow={handleVisible}
            />
          )}

          {modalInterface === "azure" && (
            <ModalAddAzureCredentials
              onAdd={handleAddAzure}
              handleShow={handleVisible}
              handleBack={handleBack}
            />
          )}
        </Spin>
      </Modal>
    </>
  );
}

ModalAddCredentials.propTypes = {
  visibility: propTypes.bool,
  handleVisible: propTypes.func,
  handleAddGcp: propTypes.func,
  handleAddAws: propTypes.func,
  loading: propTypes.bool,
  handleAddAzure: propTypes.func,
  initialInterface: propTypes.string,
};

export default ModalAddCredentials;
