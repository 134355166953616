// Libs
import React from "react";
import { Skeleton } from "antd";

function ModalAddAwsFirewall() {
  return (
    <>
      <Skeleton />
    </>
  );
}

ModalAddAwsFirewall.propTypes = {};

export default ModalAddAwsFirewall;
