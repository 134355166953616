// Libs
import React from "react";
import { Card, Col, Row } from "antd";

// Components
import MainButton from "components/libs/button";

// Styles
import s from "./index.module.scss";

function ErrorPageContents() {
  return (
    <>
      <Card>
        <Row align="middle" justify="center" className={s.section}>
          <Col span={12} className={s.textContainer}>
            <h1>Ooops something went wrong ...</h1>
            <h3>Sorry something went wrong there. Please try again.</h3>
            <p>
              Our sites is under maintenance right now. We're doing our best and
              we'll back soon.
            </p>
            <MainButton>Contact Us</MainButton>
          </Col>
          <Col span={12} className={s.imageContainer}>
            <img src="/images/astronot.png" alt="" />
          </Col>
        </Row>
      </Card>
    </>
  );
}

export default ErrorPageContents;
