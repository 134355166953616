// Libs
import React from "react";
import propTypes from "prop-types";
import { Col, Descriptions, Row, Spin, Tag } from "antd";

function DeviceDetails(props) {
  const { loading, deviceDetails } = props;

  //? Handle ipv4
  const IPv4List =
    deviceDetails.ipv4 &&
    deviceDetails.ipv4 !== "[]" &&
    deviceDetails?.ipv4?.replace(/[[\]\s]+/g, "")?.split(",");

  //? Handle IPv6
  const IPv6List =
    deviceDetails.ipv6 &&
    deviceDetails.ipv6 !== "[]" &&
    deviceDetails?.ipv6?.replace(/[[\]\s]+/g, "")?.split(",");

  return (
    <>
      <Spin spinning={loading}>
        <Descriptions title="Device Details" bordered>
          <Descriptions.Item label="Client UUID">
            {deviceDetails.clientUUID || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Host Name">
            {deviceDetails.hostName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="IPv4">
            <Row gutter={[0, 16]} style={{ minWidth: 200 }}>
              {IPv4List
                ? IPv4List?.map((item, index) => {
                    return (
                      <Col>
                        <Tag key={index}>{item}</Tag>
                      </Col>
                    );
                  })
                : "-"}
            </Row>
          </Descriptions.Item>
          <Descriptions.Item label="IPv6">
            <Row gutter={[0, 16]} style={{ minWidth: 200 }}>
              {IPv6List
                ? IPv6List?.map((item, index) => {
                    return (
                      <Col>
                        <Tag key={index}>{item}</Tag>
                      </Col>
                    );
                  })
                : "-"}
            </Row>
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </>
  );
}

DeviceDetails.propTypes = {
  loading: propTypes.bool,
  deviceDetails: propTypes.object,
};

export default DeviceDetails;
