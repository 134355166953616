// Libs
import React, { useState } from "react";
import propTypes from "prop-types";
import { Col, Row, List, Avatar, Skeleton } from "antd";

// Components
import ModalAddMembers from "components/libs/modal/add-members";

// Utils
import { deleteConfirmation } from "utils/deleteConfirmation";

// Hooks
import { useSDKTenantUsers } from "hooks/tenant-user-sdk";

// Icons
import { UserDeleteOutlined } from "@ant-design/icons";

// Styles
import s from "./index.module.scss";
import MainButton from "components/libs/button";

function DashboardSettingsMembers(props) {
  const { currentTenant, user } = props;

  //? ============== Tenant User Hooks ============= ?//
  const {
    data: tenantUserList,
    onAddMember,
    loading,
    onRemove,
  } = useSDKTenantUsers({
    tenantName: currentTenant.tenantName,
  });
  // * ====================================== * //

  //? ============== Handle Modal ============= ?//
  const [showModal, setShowModal] = useState(false);
  const handleModal = () => {
    setShowModal(!showModal);
  };
  // * ====================================== * //

  //? ============== Handle Remove ============= ?//
  const handleRemove = (id) => {
    deleteConfirmation({
      title: "",
      content:
        "Are you sure you want to remove permissions for [user@email.com]?",
      handleOk: () => {
        onRemove(id);
      },
    });
  };
  // * ====================================== * //

  return (
    <>
      <section>
        <Row justify="end">
          <Col>
            <MainButton size="large" onClick={handleModal}>
              + Add Members
            </MainButton>
          </Col>
        </Row>
      </section>

      <section style={{ marginTop: 30 }}>
        <List
          itemLayout="horizontal"
          dataSource={tenantUserList}
          renderItem={(item) => (
            <List.Item
              actions={[
                item.user.externalUserId !== user.sub && (
                  <div
                    key="list-loadmore-edit"
                    className={s.action}
                    onClick={() => handleRemove(item.tenantUserId)}
                  >
                    <UserDeleteOutlined /> Remove
                  </div>
                ),
              ]}
            >
              <Skeleton avatar title={false} loading={loading} active>
                <List.Item.Meta
                  avatar={<Avatar src={"https://joeschmoe.io/api/v1/random"} />}
                  title={<p style={{ marginBottom: 0 }}>{item.user.email}</p>}
                  description={`ID: ${item.user.externalUserId}`}
                />
              </Skeleton>
            </List.Item>
          )}
        />
      </section>

      <ModalAddMembers
        visible={showModal}
        handleCancel={handleModal}
        onSubmit={onAddMember}
      />
    </>
  );
}

DashboardSettingsMembers.propTypes = {
  currentTenant: propTypes.any,
  user: propTypes.any,
};

export default DashboardSettingsMembers;
