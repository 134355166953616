// Libs
import React from "react";
import { Card } from "antd";

// Components
import DashboardControlPanelCredentials from "components/contents/dashboard/credentials";

// Styles
import s from "./index.module.scss";

function DashboardCredentials() {
  return (
    <>
      <Card className={s.container}>
        <section className={s.section}>
          <DashboardControlPanelCredentials />
        </section>
      </Card>
    </>
  );
}

export default DashboardCredentials;
