// Libs
import React from "react";
import { Container, Row } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

// Components
import MainLoading from "components/libs/loading";
import ExternalApiComponent from "components/libs/external-api";
import Highlight from "components/libs/code-highlight";

export const Auth0Test = () => {
  const { user } = useAuth0();

  return (
    <Container className="mb-5">
      <Row>
        <Highlight>{JSON.stringify(user, null, 2)}</Highlight>
      </Row>
      <Row>
        <ExternalApiComponent></ExternalApiComponent>
      </Row>
    </Container>
  );
};

export default withAuthenticationRequired(Auth0Test, {
  onRedirecting: () => <MainLoading />,
});
