// Libs
import React from "react";
import { useHistory } from "react-router-dom";
import { Result } from "antd";

// Components
import MainButton from "components/libs/button";

function ThankYouPage() {
  const history = useHistory();

  return (
    <>
      <Result
        status="success"
        title="Thank You for your payment!"
        subTitle="A Receipt for this transaction has been sent via email for your records."
        extra={[
          <MainButton
            type="primary"
            key="console"
            onClick={() => history.push("/profile")}
          >
            Go To Profile
          </MainButton>,
        ]}
      />
    </>
  );
}

export default ThankYouPage;
