import { getConfig } from "config";
import { Cookies } from "react-cookie";

//? Web Portal SDK
import {
  Configuration,
  V1Api,
} from "@allowexactly-npm/zoho-subscriptions-integration-sdk/lib";

//? General Config
const { zohoSubscriptionsIntegrationOrigin } = getConfig();

//? Authorization
const cookie = new Cookies();
const accessToken = cookie.get("access_token");

//? Configuration
const configuration = new Configuration({
  basePath: zohoSubscriptionsIntegrationOrigin,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

//? Integration Generate use SDK
const zohoIntegration = new V1Api(configuration);

const integrationApi = {
  zohoIntegration,
};

export default integrationApi;
