// Libs
import propTypes from "prop-types";
import { Button, Col, Row } from "antd";
import React from "react";

// Utils
import { deleteConfirmation } from "utils/deleteConfirmation";

// Styles
import s from "./index.module.scss";

function ImportantActionBox(prop) {
  const {
    title,
    description,
    onClick = () => {},
    clickText,
    disabled,
    type = "danger",
    withButton = true,
  } = prop;

  //? ============== Handle Click ============= ?//
  const handleClick = () => {
    deleteConfirmation({ title: "", content: description, handleOk: onClick });
  };
  // * ====================================== * //

  return (
    <>
      <Row
        className={`${s.container} ${s?.[type]}`}
        align={"middle"}
        justify="space-between"
      >
        <Col>
          <h1>{title}</h1>
          <p dangerouslySetInnerHTML={{ __html: description }} />
        </Col>

        {withButton && (
          <Col>
            <Button
              onClick={handleClick}
              danger
              type="primary"
              disabled={disabled}
              className={` ${s.button} ${s?.[type]}`}
            >
              {clickText}
            </Button>
          </Col>
        )}
      </Row>
    </>
  );
}

ImportantActionBox.propTypes = {
  title: propTypes.string,
  description: propTypes.string,
  onClick: propTypes.func,
  clickText: propTypes.string,
  disabled: propTypes.bool,
  type: propTypes.oneOf(["danger", "warning", "success"]),
  withButton: propTypes.bool,
};

export default ImportantActionBox;
