// Libs
import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Components
import MainLayout from "./components/layout/main";

// Pages
import MainHomepage from "pages/homepage";
import ThankYouPage from "pages/thank-you";
import PaymentRedirectPage from "pages/payment-redirect";
import Dashboard from "pages/dashboard";
import Page404 from "pages/page-404";
import LoginRedirectPage from "pages/login-redirect";
import AfterDeleteAccount from "pages/after-delete-account";

import Auth0Test from "./pages/Auth0Test";

// Styles
import "styles/index.scss";
import "styles/base.scss";

// icons
import initFontAwesome from "./utils/initFontAwesome";
import { CookiesProvider } from "react-cookie";
import ShowTokenPage from "pages/show-token";
import Page500 from "pages/page-500";
import GlobalContext from "context";
import TermsAndConditionsPage from "pages/terms-and-conditions";
import PrivacyPolicyPage from "pages/privacy-policy";

initFontAwesome();

const App = () => {
  const location = useLocation();
  const queryClient = new QueryClient();

  return (
    <div id="app" style={{ position: "relative", minHeight: "100vh" }}>
      <QueryClientProvider client={queryClient}>
        <CookiesProvider>
          {!location.pathname.includes("/dashboard") && (
            <>
              <MainLayout>
                <Switch>
                  <div>
                    <Route exact path="/" component={MainHomepage} />
                    <Route path="/profile" component={LoginRedirectPage} />
                    <Route path="/thank-you" component={ThankYouPage} />
                    <Route
                      path="/account-deletion"
                      component={AfterDeleteAccount}
                    />
                    <Route
                      path="/payment-redirect"
                      component={PaymentRedirectPage}
                    />
                    <Route path="/auth0-test" component={Auth0Test} />
                    <Route path="/show-token" component={ShowTokenPage} />
                    <Route path="/error" component={Page500} />
                    <Route path="/404" component={Page404} />
                    <Route
                      path="/terms-and-conditions"
                      component={TermsAndConditionsPage}
                    />
                    <Route
                      path="/privacy-policy"
                      component={PrivacyPolicyPage}
                    />
                  </div>
                </Switch>
              </MainLayout>
            </>
          )}

          {location.pathname.includes("/dashboard") && (
            <GlobalContext>
              <Switch>
                <Route path={"/dashboard/:tenant"} component={Dashboard} />
              </Switch>
            </GlobalContext>
          )}
        </CookiesProvider>
      </QueryClientProvider>
    </div>
  );
};

export default App;
