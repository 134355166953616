import React from "react";
import propTypes from "prop-types";
import { Button } from "antd";

// Styles
import s from "./index.module.scss";

function MainButtonPrice(props) {
  const {
    handleClick,
    mode,
    currentPlan,
    isAuthenticated,
    pricingContents,
    planCode,
  } = props;

  return (
    <>
      <Button
        className={`primary-button ${s.button}`}
        onClick={() => {
          handleClick(mode);
        }}
        disabled={currentPlan && currentPlan === planCode}
        hidden={currentPlan && mode.name === "Free"}
      >
        {pricingContents[mode.name].priceMonthly === "Contact Us"
          ? "Contact Us"
          : currentPlan && currentPlan === planCode
          ? "Current Plan"
          : pricingContents[mode.name].priceMonthly === "Reasonable Price"
          ? "Coming Soon"
          : isAuthenticated
          ? "Upgrade"
          : "Sign up"}
      </Button>
    </>
  );
}

MainButtonPrice.propTypes = {
  handleClick: propTypes.func,
  mode: propTypes.object,
  currentPlan: propTypes.string,
  isAuthenticated: propTypes.bool,
  pricingContents: propTypes.object,
  planCode: propTypes.string,
};

export default MainButtonPrice;
