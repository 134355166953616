// Libs
import React from "react";
import { Badge, Descriptions, Spin, Table, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";

// Components
import { FirewallDestinationHistoryColumns } from "./utils";
import PageHeader from "components/libs/page-header";

// Hooks
import { useSDKFirewallDestinationHistory } from "hooks/firewall-destination-sdk";

// Styles
import s from "./index.module.scss";

const { Title } = Typography;

function DashboardContentsFirewallDestinationHistory() {
  const history = useHistory();
  const params = useParams();

  //? ============== Handle Columns ============= ?//
  const column = FirewallDestinationHistoryColumns();
  // * ====================================== * //

  //? ============== Device History Hooks ============= ?//
  const { data, loading } = useSDKFirewallDestinationHistory({
    tenantName: params.tenant,
    id: params.firewallId,
    timezone: "",
  });
  // * ====================================== * //

  return (
    <>
      <PageHeader
        title="Firewall Destination History"
        onBack={() => history.goBack()}
        className={s.header}
      />

      {data && (
        <>
          <section style={{ marginBottom: 50 }}>
            <Spin spinning={loading}>
              <Descriptions
                title="Firewall Details"
                className={s.details_container}
              >
                <Descriptions.Item label="Credential Name">
                  {data.cloudId}
                </Descriptions.Item>
                <Descriptions.Item label="Firewall Rule Name">
                  {data.firewallRuleName}
                </Descriptions.Item>
                <Descriptions.Item label="Project Name">
                  {data.projectName}
                </Descriptions.Item>
                <Descriptions.Item label="Status">
                  <Badge
                    status={data.status === "ACTIVE" ? "processing" : "default"}
                    text={data.status?.toLowerCase()}
                    style={{ textTransform: "capitalize" }}
                  />
                </Descriptions.Item>
                <Descriptions.Item label="Firewall IP Version">
                  {data.firewallIpVersion || "-"}
                </Descriptions.Item>
                <Descriptions.Item label="VPC Name">
                  {data.vpcName}
                </Descriptions.Item>
                <Descriptions.Item label="Placeholder IP Address">
                  {data.placeholderIpAddress}
                </Descriptions.Item>
              </Descriptions>
            </Spin>
          </section>

          <Title level={5} style={{ marginBottom: 20, fontWeight: 700 }}>
            Firewall History
          </Title>
          <section>
            <Table
              columns={column}
              dataSource={data.histories}
              loading={loading}
            />
          </section>
        </>
      )}
    </>
  );
}

export default DashboardContentsFirewallDestinationHistory;
