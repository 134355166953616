import React, { useEffect } from "react";

import ContainerMain from "components/container/main";
import s from "./index.module.scss";

export default function TermsAndConditionsPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContainerMain>
        <div style={{ textAlign: "center", marginBottom: 80 }}>
          <h1 style={{ marginTop: 60, fontWeight: 700 }}>
            TERM AND CONDITIONS
          </h1>
          <p>[Last updated: 7 Sep 2023]</p>
        </div>

        <div style={{ width: "80%", margin: "0 auto" }} className={s.content}>
          <h2>Acceptance of Terms</h2>
          <p>
            By downloading or using the AllowExactly app, you accept and agree
            to the following terms. We advise you to read them carefully before
            using the app.
          </p>
          <h2>Intellectual Property and Restrictions</h2>
          <p>
            The app and all associated intellectual property rights, including
            trademarks, copyrights, and database rights, belong to AllowExactly.
            You are prohibited from:
            <ul>
              <li>Copying or modifying the app or any of its parts.</li>
              <li>Extracting the app's source code.</li>
              <li>Translating the app into other languages.</li>
              <li>Creating derivative versions of the app.</li>
            </ul>
          </p>
          <h2>Changes and Payment</h2>
          <p>
            AllowExactly aims to ensure the app is useful and efficient. We
            reserve the right to modify the app or charge for its services at
            any time. We will always provide clear information regarding any
            charges related to the app.
          </p>
          <h2>Personal Data</h2>
          <p>
            The AllowExactly app stores and processes the personal data you
            provide to deliver our services.
          </p>
          <h2>Device and App Security</h2>
          <p>
            Ensure your device and app access remain secure. We advise against
            jailbreaking or rooting your device. Doing so may expose your device
            to risks and impair the app's functionality.
          </p>
          <h2>AllowExactly's Limitation of Responsibility</h2>
          <p>
            You must have an active internet connection for certain app
            functions. AllowExactly isn't responsible for:
            <ul>
              <li>
                Limited app functionality due to lack of internet access or data
                allowance.
              </li>
              <li>Data or third-party charges incurred while using the app.</li>
              <li>Roaming data charges without turning off data roaming.</li>
            </ul>
            Ensure your device remains charged. AllowExactly isn't responsible
            if the app cannot be accessed due to device battery depletion.
          </p>
          <h2>Information Accuracy</h2>
          <p>
            While we strive for accuracy, information in the app is sourced from
            third parties. AllowExactly is not liable for any loss resulting
            from reliance on this information.
          </p>
          <h2>App Updates and Termination</h2>
          <p>
            The app is available on Android & iOS. System requirements may
            change. You must download updates to continue using the app. We may
            cease providing the app or terminate its use at any time. Upon
            termination, unless stated otherwise:
            <ul>
              <li>Your rights under these terms end.</li>
              <li>You must stop using and delete the app.</li>
            </ul>
          </p>
          <h2>Changes to These Terms and Conditions</h2>
          <p>
            We may periodically revise our Terms and Conditions. We advise you
            to check this page for any changes. The current terms are effective
            as of 2023-08-07.
          </p>
          <h2>Contact Us</h2>
          <p>
            For questions or suggestions about our Terms and Conditions, reach
            us at info at allowexactly.com .
          </p>
        </div>
      </ContainerMain>
    </>
  );
}
