// Libs
import React, { useState } from "react";
import { Card, Menu, Skeleton } from "antd";
import {
  Switch,
  Route,
  useRouteMatch,
  Link,
  useLocation,
} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

// Hooks
import { useSDKUsers } from "hooks/user-sdk";

// Pages
import DashboardSettingsSubscription from "./subscription";
import DashboardSettingsGeneral from "./general";
import DashboardSettingsMembers from "./members";
import DashboardSettingsAdvanced from "./advanced";
import DashboardSettingsAddOns from "./add-ons";

// Styles
import s from "./index.module.scss";

function DashboardSettings() {
  const { user } = useAuth0();
  const params = useParams();
  const location = useLocation();
  let { path, url } = useRouteMatch();

  //? ============== Menu Item ============= ?//
  const MenuItems = [
    {
      label: <Link to={`${url}`}>General</Link>,
      key: `${url}`,
    },
    {
      label: <Link to={`${url}/subscription`}>Subscription</Link>,
      key: `${url}/subscription`,
    },
    {
      label: <Link to={`${url}/add-ons`}>Add Ons</Link>,
      key: `${url}/add-ons`,
    },
    {
      label: <Link to={`${url}/members`}>Members</Link>,
      key: `${url}/members`,
    },
    {
      label: <Link to={`${url}/advanced`}>Advanced</Link>,
      key: `${url}/advanced`,
    },
  ];
  // * ====================================== * //

  //? ============== User Hook ============= ?//
  const { data: userDetails } = useSDKUsers({ externalUserId: user.sub });
  const currentTenant = userDetails?.tenants.find(
    (item) => item.tenantName === params.tenant
  );
  // * ====================================== * //

  //? ============== Handle Menu Highlight ============= ?//
  const [currentUrl, setCurrentUrl] = useState(false);
  const handleSelectMenu = ({ key }) => {
    setCurrentUrl(key);
  };
  // * ====================================== * //

  return (
    <>
      <Card title="Tenants Settings">
        <Menu
          items={MenuItems}
          mode="horizontal"
          selectedKeys={[currentUrl || location.pathname]}
          onSelect={handleSelectMenu}
          className={s.menu}
        />

        <div style={{ padding: "30px 20px 0" }}>
          {!currentTenant && <Skeleton />}
          {currentTenant && user && (
            <Switch>
              <Route exact path={path}>
                <DashboardSettingsGeneral />
              </Route>
              <Route exact path={`${path}/subscription`}>
                <DashboardSettingsSubscription />
              </Route>
              <Route exact path={`${path}/add-ons`}>
                <DashboardSettingsAddOns />
              </Route>
              <Route exact path={`${path}/members`}>
                <DashboardSettingsMembers
                  currentTenant={currentTenant}
                  user={user}
                />
              </Route>
              <Route exact path={`${path}/advanced`}>
                <DashboardSettingsAdvanced
                  currentTenant={currentTenant}
                  userId={user.sub}
                />
              </Route>
            </Switch>
          )}
        </div>
      </Card>
    </>
  );
}

export default DashboardSettings;
