// Libs
import propTypes from "prop-types";
import { Button, Col, Form, Input, Segmented, Upload } from "antd";
import React, { useState } from "react";

// Icons
import { UploadOutlined } from "@ant-design/icons";

// Styles
import s from "./index.module.scss";

function ModalAddAwsCredentials(props) {
  const { onAdd, handleShow } = props;

  const [selectOption, setSelectOption] = useState(1);

  //? ============== Handle Submit By Form ============= ?//
  const [byForm] = Form.useForm();
  const handleSubmitByForm = () => {
    byForm.validateFields().then(async (value) => {
      const submission = {
        accessKey: value.accessKey,
        secretKey: value.secretKey,
      };
      const result = await onAdd(submission);
      if (result) {
        handleShow();
        byForm.resetFields();
      }
    });
  };
  // * ====================================== * //

  return (
    <>
      <Segmented
        options={[
          { label: "Form Input", value: 1 },
          { label: "CSV Upload", value: 2 },
        ]}
        block
        onChange={(val) => setSelectOption(val)}
        className={s.option}
      />
      {selectOption === 1 && (
        <div className={s.formSection}>
          <Form layout="vertical" form={byForm}>
            <Form.Item
              name="accessKey"
              label="Access Key"
              rules={[{ required: true, message: "Access key is required!" }]}
            >
              <Input placeholder="input your access key" />
            </Form.Item>
            <Form.Item
              name="secretKey"
              label="Secret Key"
              rules={[{ required: true, message: "Secret key is required!" }]}
            >
              <Input placeholder="input your secret key" type={"password"} />
            </Form.Item>
          </Form>
          <Col style={{ textAlign: "right" }}>
            <Button onClick={handleSubmitByForm}>Submit</Button>
          </Col>
        </div>
      )}
      {selectOption === 2 && (
        <div className={s.formSection}>
          <p>Click here to upload CSV</p>
          <Upload>
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
          <Col style={{ textAlign: "right" }}>
            <Button>Submit</Button>
          </Col>
        </div>
      )}
    </>
  );
}

ModalAddAwsCredentials.propTypes = {
  onAdd: propTypes.func,
  handleShow: propTypes.func,
};

export default ModalAddAwsCredentials;
