import React from "react";
import s from "./index.module.scss";

export default function ContainerMain({ children }) {
  return (
    <>
      <div className={s.section}>
        <div className={s.container}>{children}</div>
      </div>
    </>
  );
}
