import { useAuth0 } from "@auth0/auth0-react";
import { useMutation } from "@tanstack/react-query";
import { message } from "antd";
import moment from "moment-timezone";
import { useState, useCallback } from "react";
import { useCookies } from "react-cookie";
import apiSDK from "utils/sdk-service";

export const useSDKAuth = () => {
  const [loading, setLoading] = useState(false);
  const { user, logout, getAccessTokenSilently } = useAuth0();

  const {
    0: cookie,
    1: setCookie,
    2: removeCookie,
  } = useCookies(["access_token"]);

  //? Handle Status
  const isAuth = cookie.access_token;

  //? Grab token result

  //? Handle Generate Token to Cookies
  const authorizationLogin = useCallback(async () => {
    try {
      if (user) {
        setLoading(true);
        const token = await getAccessTokenSilently();

        if (token) {
          const today = new Date();
          const nextTenYear = new Date();
          nextTenYear.setDate(today.getDate() + 1);

          setCookie("access_token", token, {
            expires: nextTenYear,
            path: "/",
          });

          window.location.reload();
        }
      }
    } catch (error) {
      message.error("Failed get authorization data");
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, setCookie, user]);

  const { mutateAsync: authorizationEvent } = useMutation(
    useCallback(async () => {
      const zone = moment.tz.guess();
      const offset = moment.tz(zone).format("Z");
      if (user && isAuth) {
        const res = await apiSDK.tenantUserApi.getOrCreateDefaultTenantUser({
          v1Auth0DefaultTenantUserForm: {
            email: user.email,
            userId: user.sub,
            timezone: `${zone} (GMT ${offset})`,
          },
        });
        const defaultTenant = res?.result?.user?.tenants?.find(
          (item) => item.defaultTenant
        );

        return {
          tenantName: defaultTenant.tenantName,
          tenantId: defaultTenant.tenantId,
          externalUserId: user.sub,
        };
      }
    }, [isAuth, user]),
    {
      onSuccess: () => {
        message.success("Success login");
      },
      onError: () => {
        message.error("Something wrong when login");
      },
    }
  );

  //? Handle Remove Cookies on Logout
  const authorizationLogout = useCallback(
    (redirectUri) => {
      logout({
        returnTo: redirectUri
          ? `${window.location.origin}${redirectUri}`
          : `${window.location.origin}`,
      });
      removeCookie("access_token");
    },
    [logout, removeCookie]
  );

  return {
    isAuth,
    loading,
    authorizationLogin,
    authorizationLogout,
    authorizationEvent,
  };
};
