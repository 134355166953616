export const footerFirstMenu = [
  { link: "/#features", label: "Features" },
  { link: "/#howItWorks", label: "How It Works" },
  { link: "/#pricingPlans", label: "Pricing Plans" },
  { link: "/#whatTheySay", label: "What They Say" },
  { link: "/#faq", label: "FAQ" },
];

export const footerSecondMenu = [
  { link: "/privacy-policy", label: "Privacy Policy" },
  { link: "/terms-and-conditions", label: "Terms & Conditions" },
  { link: "#", label: "Contact Us" },
];

export const footerDownloadOption = [
  { link: "https://www.whatsapp.com", alt: "logo", imgSrc: "app-store.webp" },
  { link: "https://www.whatsapp.com", alt: "logo", imgSrc: "google-play.webp" },
  { link: "https://www.whatsapp.com", alt: "logo", imgSrc: "pc-dl.svg" },
  { link: "https://www.whatsapp.com", alt: "logo", imgSrc: "mac-dl.svg" },
];

export const footerSocialOption = [
  { link: "https://www.whatsapp.com", icon: ["fab", "whatsapp"] },
  { link: "https://www.twitter.com", icon: ["fab", "twitter"] },
  { link: "https://www.facebook.com", icon: ["fab", "facebook"] },
];
