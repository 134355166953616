import React from "react";
import { Row, Col } from "antd";
import propTypes from "prop-types";

import s from "./index.module.scss";
import { credentialsOption } from "data/control-panel";

export default function CompLibsCredentialsOptions(props) {
  const { handleSelection, enableList = ["gcp", "aws", "azure"] } = props;
  return (
    <>
      <Row gutter={[16, 0]}>
        {credentialsOption.map((item, index) => {
          return (
            <Col
              span={8}
              key={index}
              onClick={() => {
                if (!enableList.includes(item.key)) return;
                handleSelection(item.key);
              }}
              // className={`${!enableList.includes(item.key) && s.disabled}`}
            >
              <div
                className={`${s.optionContainer} ${
                  !enableList.includes(item.key) && s.disabled
                }`}
                data-testId={item.dataTestId}
              >
                <Row style={{ width: "100%" }}>
                  <Col className={s.logoContainer} span={10}>
                    <img src={item.logo} alt={item.label} />
                  </Col>
                  <Col span={14}>
                    <div className={s.description}>
                      Continue with <br />
                    </div>
                    <span className={s.label}>{item.label}</span>
                  </Col>
                </Row>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
}

CompLibsCredentialsOptions.propTypes = {
  handleSelection: propTypes.func,
  enableList: propTypes.array,
};
