import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message } from "antd";
import { useCallback } from "react";
import { useHistory, useParams } from "react-router-dom";
import integrationApi from "utils/sdk-zoho-subscriptions";
import useAuthQuery from "./useAuthQuery";

export const useZohoSubscriptions = ({ subscriptionId }) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  const { tenant } = useParams();

  //? Get Data
  const { data, isFetching } = useAuthQuery({
    queryKey: ["subscription"],
    queryFn: async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1GetSubscriptionsDetails(
          {
            subscriptionId: subscriptionId,
          }
        );
      return res;
    },
    onError: () => {
      history.push(`/dashboard/${tenant}/error-page`);
    },
    enabled: !!subscriptionId,
  });

  //? Create Subscription
  const { mutateAsync: onCreateSubscription, isLoading: onCreateLoading } =
    useMutation(
      useCallback(async (data) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1CreateSubscription(
            {
              subscription: {
                tenantName: data.tenantName,
                email: data.email,
                planCode: data.planCode,
              },
            }
          );
        return res;
      }, []),
      {
        onSuccess: (res) => {
          window.location.replace(res.result);
        },
        onError: () => {
          message.error("something wrong when redirect to payment portal");
        },
      }
    );

  //? Update Subscription
  const { mutateAsync: onUpdateSubscription, isLoading: onUpdateLoading } =
    useMutation(
      useCallback(async (data) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1UpdateSubscription(
            {
              updateSubscription: {
                planCode: data.planCode,
                subscriptionId: data.subscriptionId,
                addOns: data.addOns,
              },
            }
          );
        return res;
      }, []),
      {
        onSuccess: (res) => {
          window.location.replace(res.result);
        },
        onError: () => {
          message.error("something wrong when redirect to payment portal");
        },
      }
    );

  //? Cancel Subscription
  const {
    mutateAsync: onCancelSubscription,
    isLoading: onCancelSubscriptionLoading,
  } = useMutation(
    useCallback(
      async ({ cancelAtEnd }) => {
        const res =
          await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1CancelSubscription(
            {
              cancelAtEnd: cancelAtEnd,
              subscriptionId: subscriptionId,
            }
          );
        return res;
      },
      [subscriptionId]
    ),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant"]);
        message.success("Your subscription successfully cancel");
        return res.success;
      },
      onError: () => {
        message.error("something wrong when redirect to payment portal");
      },
    }
  );

  //? Reactive Subscription
  const {
    mutateAsync: onReactiveSubscription,
    isLoading: onReactiveSubscriptionLoading,
  } = useMutation(
    useCallback(async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1ReactivateSubscription(
          {
            subscriptionId: subscriptionId,
          }
        );
      return res;
    }, [subscriptionId]),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant"]);
        message.success("Your subscription successfully reactivated");
        return res.success;
      },
      onError: () => {
        message.error("something wrong when redirect to payment portal");
      },
    }
  );

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading:
      isFetching ||
      onCreateLoading ||
      onUpdateLoading ||
      onCancelSubscriptionLoading ||
      onReactiveSubscriptionLoading,
    onCreateSubscription,
    onUpdateSubscription,
    onCancelSubscription,
    onReactiveSubscription,
  };
};

export const useZohoSubscriptionsAddOns = () => {
  const history = useHistory();
  const { tenant } = useParams();

  //? Get Add Ons
  const { data, isFetching } = useAuthQuery({
    queryKey: ["zoho-addons"],
    queryFn: async () => {
      const res =
        await integrationApi.zohoIntegration.zohoSubscriptionsIntegrationV1GetAddOnsList();
      return res;
    },
    onError: () => {
      history.push(`/dashboard/${tenant}/error-page`);
    },
  });

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching,
  };
};
