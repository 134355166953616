// Libs
import React from "react";
import { Col } from "antd";
import { useHistory } from "react-router-dom";

// Components
import MainButton from "components/libs/button";

// Styles
import s from "./index.module.scss";

function Page500() {
  const history = useHistory();
  return (
    <>
      <div className={s.section}>
        <div className={s.container}>
          <Col span={24} className={s.titleContainer}>
            <h1 className={s.title}>500</h1>
            <h3>Oops! Something went wrong on our server.</h3>
            <p style={{ width: "60%", margin: "0 auto" }}>
              We apologize for the inconvenience. Our technical team has been
              notified and is working to resolve the issue. Please try again
              later.
            </p>
          </Col>
          <Col span={24}>
            <MainButton onClick={() => history.push("/")}>
              Go To Homepage
            </MainButton>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Page500;
