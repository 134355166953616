// Libs
import React, { useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
  useHistory,
} from "react-router-dom";

// Pages
import DashboardControlPanel from "pages/dashboard/control-panel";
import DashboardProfile from "pages/dashboard/profile";
import DashboardSetting from "pages/dashboard/settings";
import ErrorPage from "pages/dashboard/error-page";
import DashboardCredentials from "./credentials";
import DashboardDevices from "./devices";
import DashboardDevicesHistory from "./devices/history";
import DashboardFirewallDestinationHistory from "./firewalls/history";
import DashboardFirewallsDestinationManageLink from "./firewalls/manage-link";
import DashboardDeviceManageLink from "./devices/manage-link";
import DashboardManageLink from "./manage-link";

// Components
import DashboardLayout from "components/layout/dashboard";
import MainLoading from "components/libs/loading";
import DashboardFirewalls from "./firewalls";

// Hooks
import { useSDKUsers } from "hooks/user-sdk";
import RetryAddCredentialPage from "pages/dashboard/retry-add-credential";
import TooManyRequestPage from "./too-many-request";

function Dashboard(props) {
  const { user } = useAuth0();
  let { path } = useRouteMatch();
  const params = useParams();
  const history = useHistory();

  //? ============== User Hooks ============= ?//
  const { data: userDetails } = useSDKUsers({
    externalUserId: user.sub,
  });
  // * ====================================== * //

  //? ============== Handle Redirect ============= ?//
  useEffect(() => {
    if (userDetails) {
      const checkTenant = userDetails?.tenants.find(
        (item) => item.tenantName === params.tenant
      );

      if (!checkTenant) {
        history.push(`/404`);
      }
    }
  }, [userDetails, params.tenant, history]);
  // * ====================================== * //

  return (
    <>
      {userDetails && (
        <DashboardLayout>
          <Switch>
            <Route exact path={path} component={DashboardControlPanel} />
            <Route
              exact
              path={`${path}/devices`}
              component={DashboardDevices}
            />
            <Route
              path={`${path}/devices/:deviceId/history`}
              component={DashboardDevicesHistory}
            />
            <Route
              exact
              path={`${path}/devices/:deviceId/manage-link`}
              component={DashboardDeviceManageLink}
            />
            <Route path={`${path}/profile`} component={DashboardProfile} />
            <Route path={`${path}/settings`} component={DashboardSetting} />
            <Route
              path={`${path}/credentials`}
              component={DashboardCredentials}
            />
            <Route
              exact
              path={`${path}/manage-link`}
              component={DashboardManageLink}
            />
            <Route
              exact
              path={`${path}/firewalls`}
              component={DashboardFirewalls}
            />
            <Route
              path={`${path}/firewalls/:firewallId/history`}
              component={DashboardFirewallDestinationHistory}
            />
            <Route
              exact
              path={`${path}/firewalls/:firewallId/manage-link`}
              component={DashboardFirewallsDestinationManageLink}
            />
            <Route exact path={`${path}/error-page`} component={ErrorPage} />
            <Route
              exact
              path={`${path}/retry-add-credential`}
              component={RetryAddCredentialPage}
            />
            <Route
              exact
              path={`${path}/too-many-request`}
              component={TooManyRequestPage}
            />
          </Switch>
        </DashboardLayout>
      )}
    </>
  );
}

export default withAuthenticationRequired(Dashboard, {
  onRedirecting: () => <MainLoading />,
});
