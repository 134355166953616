import RetryAddCredentialContent from "components/contents/dashboard/retry-add-credential";
import React from "react";

export default function RetryAddCredentialPage() {
  return (
    <>
      <section>
        <RetryAddCredentialContent />
      </section>
    </>
  );
}
