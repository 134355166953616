import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";
import { notification } from "antd";
import { useCallback } from "react";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";

export const useSDKTenants = ({ tenantName }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const history = useHistory();

  //#region Get Data
  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["tenant", tenant],
    queryFn: async () => {
      const res = await apiSDK.tenantApi.getTenantUserByTenantName({
        tenantName: tenantName,
      });
      return res;
    },
    onError: () => {
      history.push(`/dashboard/${tenant}/error-page`);
    },
    enabled: !!tenantName,
  });
  //#endregion

  //#region Create new tenant
  const { mutateAsync: onAdd, isLoading: onAddLoading } = useMutation(
    useCallback(async (submission) => {
      const res = await apiSDK.tenantApi.createTenant({
        v1CreateTenantForm: {
          displayName: submission.name,
          email: submission.email,
          externalUserId: submission.externalUserId,
        },
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["tenant"]);
        queryClient.invalidateQueries(["user"]);
        notification.success({
          message: "Successfully",
          description: "Congratulation your tenant already created!",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region Delete user
  const { mutateAsync: onDelete, isLoading: onDeleteLoading } = useMutation(
    useCallback(async () => {
      const res = await apiSDK.tenantApi.deleteTenantByTenantName({
        tenantName: tenantName,
      });
      return res;
    }, [tenantName]),
    {
      onSuccess: (res) => {
        notification.success({
          message: "Successfully",
          description: "successfully delete selected tenant",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result;

  return {
    data: result,
    onAdd,
    onDelete,
    loading: isFetching || onAddLoading || onDeleteLoading,
    error,
  };
};
