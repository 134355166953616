// Libs
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { Modal, Select, Col, Tooltip, Divider } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";

// Data
import ModalAddGcpFirewall from "../add-gcp-firewall";
import CompLibsModalTitle from "components/libs/modal-title";
import ModalAddAzureFirewall from "../add-azure-firewall";
import ModalAddAwsFirewall from "../add-aws-firewall";
import { useSDKCredentials } from "hooks/credentials-sdk";
import { useParams, Link } from "react-router-dom";
import { credentialsOption } from "data/control-panel";

const { Option } = Select;

function ModalAddFirewall(props) {
  const { visible, handleVisible, isEdit = false, initialValues } = props;
  const { tenant } = useParams();

  //#region Handle Cancel
  const handleCancel = () => {
    handleVisible();
  };
  //#endregion

  //#region Handle options
  const [stage, setStage] = useState();
  useEffect(() => {
    if (initialValues.credentialLocation) {
      setStage(initialValues.credentialLocation.toLowerCase());
    }
  }, [initialValues.credentialLocation]);

  //#endregion

  //#region Get credentials
  const { data: credentialsList, loading: credentialListLoading } =
    useSDKCredentials({ tenantName: tenant, type: "" });

  const credentialLocationEnableList =
    credentialsList &&
    credentialsList
      .map((item) => item.location.toLowerCase())
      .filter((value, index, array) => array.indexOf(value) === index);

  //#endregion

  return (
    <>
      <Modal footer={null} open={visible} onCancel={handleCancel}>
        <Col span={24} style={{ marginTop: 15 }}>
          <CompLibsModalTitle>Firewall Location</CompLibsModalTitle>
          <Select
            style={{ width: "100%", marginTop: 10 }}
            placeholder="Select specific firewall location (e.g GCP, AWS, Azure)"
            onSelect={(value) => {
              setStage(value);
            }}
            loading={credentialListLoading}
            value={stage}
          >
            {credentialsOption.map((item) => {
              const isDisable = !credentialLocationEnableList.includes(
                item.key
              );
              return (
                <Option key={item.key} disabled={isDisable}>
                  <span>
                    <img
                      src={`${item.logo}`}
                      alt="credentials-logo"
                      style={{
                        width: "25px",
                        height: "25px",
                        objectFit: "contain",
                        filter: isDisable ? "grayscale(1)" : "none",
                      }}
                    />
                  </span>{" "}
                  {item.long_label}
                  {isDisable && (
                    <Tooltip
                      title={
                        <>
                          To add a firewall for this location, please add the
                          necessary credentials by{" "}
                          <Link
                            to={`/dashboard/${tenant}/credentials?mode=created-by-${item.key}`}
                          >
                            clicking here
                          </Link>
                          .
                        </>
                      }
                    >
                      <span
                        style={{
                          marginLeft: "6px",
                          position: "relative",
                          top: "-1.5px",
                        }}
                      >
                        <InfoCircleOutlined />
                      </span>
                    </Tooltip>
                  )}
                </Option>
              );
            })}
          </Select>
        </Col>

        {stage === "gcp" && (
          <>
            <Divider />
            <div style={{ marginBottom: 15 }}>
              <CompLibsModalTitle>Add GCP Firewall</CompLibsModalTitle>
            </div>
            <ModalAddGcpFirewall
              handleVisible={handleVisible}
              initialValues={initialValues}
              isEdit={isEdit}
            />
          </>
        )}

        {stage === "azure" && (
          <>
            <Divider />
            <div style={{ marginBottom: 15 }}>
              <CompLibsModalTitle>Add Azure Firewall</CompLibsModalTitle>
            </div>
            <ModalAddAzureFirewall
              handleVisible={handleVisible}
              initialValues={initialValues}
              isEdit={isEdit}
            />
          </>
        )}

        {stage === "aws" && (
          <>
            <Divider />
            <div style={{ marginBottom: 15 }}>
              <CompLibsModalTitle>Add AWS Firewall</CompLibsModalTitle>
            </div>
            <ModalAddAwsFirewall />
          </>
        )}
      </Modal>
    </>
  );
}

ModalAddFirewall.propTypes = {
  visible: propTypes.bool,
  handleVisible: propTypes.func,
  isEdit: propTypes.bool,
  initialValues: propTypes.any,
};

export default ModalAddFirewall;
