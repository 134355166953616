// Libs
import React from "react";
import { Col, Container, Row } from "reactstrap";

// Components
import MainWaveDivider from "components/libs/wave-divider";

// Initial Data
import { homepageHowItWorksContents } from "data/homepage";

// Styles
import s from "./index.module.scss";

function HomepageContentsHowItWorksSection() {
  return (
    <>
      <MainWaveDivider scrollId="howItWorks" title="How It Works" />
      <Container style={{ position: "relative" }}>
        <img
          className={`d-none d-md-block ${s.timelineImg}`}
          src="images/timeline.png"
          alt=""
        />
        {homepageHowItWorksContents.map((item, index) => {
          return (
            <Row className={s.contentContainer} key={index}>
              <Col className={`${s.imageContainer}`}>
                <img alt={item.alt} src={item.imgSrc} loading="lazy" />
              </Col>
              <Col className={`${s.textContainer}`}>
                <div dangerouslySetInnerHTML={{ __html: item.title }} />
                <div dangerouslySetInnerHTML={{ __html: item.content }} />
              </Col>
            </Row>
          );
        })}
      </Container>
    </>
  );
}

export default HomepageContentsHowItWorksSection;
