import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useHistory, useParams } from "react-router-dom";
import { notification } from "antd";
import { useCallback } from "react";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";

export const useSDKUsers = ({ externalUserId }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const history = useHistory();

  //#region Get Data
  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["user"],
    queryFn: async () => {
      const res = await apiSDK.userApi.getUserByExternalUserId({
        externalUserId: externalUserId,
      });
      return res;
    },
    onError: (err) => {
      history.push(`/dashboard/${tenant}/error-page`);
    },
    enabled: !!externalUserId,
  });
  //#endregion

  //#region Update user
  const { mutateAsync: onUpdate } = useMutation(
    useCallback(async (data) => {
      const res = await apiSDK.userApi.updateUser({
        externalUserId: data.externalUserId,
        v1UpdateUserForm: {
          timezone: data.timeZone,
        },
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        queryClient.invalidateQueries(["user"]);
        notification.success({
          message: "Successfully",
          description: "congratulation your account already updated!",
        });
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region Delete Users
  const { mutateAsync: onDelete } = useMutation(
    useCallback(async (externalUserId) => {
      const res = await apiSDK.userApi.deleteUser({
        externalUserId: externalUserId,
      });
      return res;
    }, []),
    {
      onSuccess: (res) => {
        notification.success({
          message: "Successfully",
          description: "your account already deleted!",
        });
        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result;

  return {
    data: result,
    loading: isFetching,
    onUpdate,
    onDelete,
    error,
  };
};
