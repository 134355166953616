// Libs
import React, { useState } from "react";
import propTypes from "prop-types";
import { Col, Divider, InputNumber, Row, Spin } from "antd";
import { useParams } from "react-router-dom";

// Hooks
import { useSDKTenants } from "hooks/tenants-sdk";
import { useZohoSubscriptions } from "hooks/zoho-integration-sdk";

// Components
import MainButton from "../button";

// Styles
import s from "./index.module.scss";

function MainAddOnsItem(props) {
  const { tenant } = useParams();
  const {
    title,
    price,
    type,
    description,
    addonCode,
    // addonKey,
  } = props;

  //? ============== Handle Quantity ============= ?//
  const [quantity, setQuantity] = useState(1);
  // * ====================================== * //

  //? ============== Tenants Hooks ============= ?//
  const { data: tenantDetails } = useSDKTenants({ tenantName: tenant });
  console.log(tenantDetails);
  // * ====================================== * //

  //? ============== Subscriptions Hooks ============= ?//
  const {
    data: subscriptionDetails,
    onUpdateSubscription,
    loading: subscriptionsLoading,
  } = useZohoSubscriptions({ subscriptionId: tenantDetails?.subscriptionId });
  const currentAddonsDetails = subscriptionDetails?.addons?.find(
    (item) => item.code === addonCode
  );
  // * ====================================== * //

  //? ============== Handle Payment ============= ?//
  const handlePayment = () => {
    const oldAddOns = subscriptionDetails?.addons
      ?.filter((item) => item.code !== addonCode)
      .map((item) => {
        return { addOnsCode: item.code, quantity: `${item.quantity}` };
      });

    const submission = {
      planCode: tenantDetails?.plan,
      subscriptionId: tenantDetails?.subscriptionId,
      addOns: [
        ...oldAddOns,
        { addOnsCode: addonCode, quantity: `${quantity}` },
      ],
    };

    onUpdateSubscription(submission);
  };
  // * ====================================== * //

  return (
    <>
      <Row align="middle" justify="space-between">
        <Col className={s.featured} span={12}>
          <h3>{title}</h3>
          <p>{description}</p>
          {type === "volume" && (
            <p className={s.featuredQtyStatus}>
              Current additional: {currentAddonsDetails?.quantity || 0}
            </p>
          )}
        </Col>
        <Col className={s.featuredButton}>
          <Row justify="end" align="middle" gutter={[16, 0]}>
            {type === "volume" && (
              <Col span={7}>
                <InputNumber
                  addonBefore={"Qty"}
                  defaultValue={1}
                  onChange={(e) => setQuantity(e)}
                />
              </Col>
            )}
            <Col className={s.priceContainer}>
              <p>${+quantity * +price}</p>
            </Col>
            <Col>
              <Spin spinning={subscriptionsLoading}>
                <MainButton
                  onClick={handlePayment}
                  disabled={
                    currentAddonsDetails &&
                    currentAddonsDetails?.type === "unit"
                  }
                >
                  {currentAddonsDetails
                    ? "Subscribed"
                    : type === "unit"
                    ? "Enable Now"
                    : type === "volume"
                    ? "Add Now"
                    : "Buy Now"}
                </MainButton>
              </Spin>
            </Col>
          </Row>
        </Col>
        <Divider />
      </Row>
    </>
  );
}

MainAddOnsItem.propTypes = {
  title: propTypes.string,
  price: propTypes.number,
  type: propTypes.string,
  description: propTypes.string,
  addonKey: propTypes.string,
  addonCode: propTypes.string,
};

export default MainAddOnsItem;
