// Libs
import React from "react";
import propTypes from "prop-types";
import { Badge, Descriptions, Spin } from "antd";

function FirewallDestinationDetails(props) {
  const { firewallDetails, loading } = props;
  return (
    <>
      <Spin spinning={loading}>
        <Descriptions title="Firewall Details" bordered>
          <Descriptions.Item label="Credential Name">
            {firewallDetails.cloudId || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Firewall Rule Name">
            {firewallDetails.firewallRuleName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Project Name">
            {firewallDetails.projectName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge
              status={
                firewallDetails.status === "ACTIVE" ? "processing" : "default"
              }
              text={firewallDetails.status?.toLowerCase() || "-"}
              style={{ textTransform: "capitalize" }}
            />
          </Descriptions.Item>
          <Descriptions.Item label="Firewall IP Version">
            {firewallDetails.firewallIpVersion || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="VPC Name">
            {firewallDetails.vpcName || "-"}
          </Descriptions.Item>
          <Descriptions.Item label="Placeholder IP Address">
            {firewallDetails.placeholderIpAddress || "-"}
          </Descriptions.Item>
        </Descriptions>
      </Spin>
    </>
  );
}

FirewallDestinationDetails.propTypes = {
  firewallDetails: propTypes.object,
  loading: propTypes.bool,
};

export default FirewallDestinationDetails;
