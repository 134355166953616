import TooManyRequestContent from "components/contents/dashboard/too-many-request";
import React from "react";

export default function TooManyRequestPage() {
  return (
    <>
      <section>
        <TooManyRequestContent />
      </section>
    </>
  );
}
