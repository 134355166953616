// Libs
import React, { useState } from "react";
import { Card, Row, Col, List, Tag } from "antd";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import { useParams } from "react-router-dom";

// Components
import MainLoading from "components/libs/loading";

// Icons
import {
  DesktopOutlined,
  KeyOutlined,
  LockOutlined,
  UserAddOutlined,
  DeploymentUnitOutlined,
  QuestionOutlined,
  DownloadOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";

// Hooks
import { useSDKDashboards } from "hooks/dashboard-sdk";

// Components
import CompLibsQuickActionAndQuota from "components/libs/quickAction";
import ModalDownloadAgentInstaller from "components/libs/modal/download-agent-installer";
import { dashboardHomepageSample } from "data/control-panel";

// Styles
import s from "./index.module.scss";

function DashboardControlPanel() {
  const { tenant } = useParams();
  const isDummy = false;

  //? ============== Dashboard Hook ============= ?//
  const { data: dataDashboard } = useSDKDashboards({ tenantName: tenant });
  const dashboardData = !tenant.includes("<")
    ? dataDashboard
    : dashboardHomepageSample;
  // * ====================================== * //

  //? ============== Limit Account ============= ?//
  const credentialCount = isDummy ? 5 : dashboardData?.credentialCount || 0;
  const deviceCount = isDummy ? 2 : dashboardData?.deviceCount || 0;
  const firewallCount = isDummy
    ? 1
    : dashboardData?.firewallDestinationCount || 0;

  const credentialLimit = isDummy ? 3 : dashboardData?.credentialLimit || 0;
  const deviceLimit = isDummy ? 3 : dashboardData?.deviceLimit || 0;
  const firewallLimit = isDummy ? 3 : dashboardData?.firewallLimit || 0;
  // * ====================================== * //

  //#region Quick Action List
  const quickActionList = [
    {
      withQuota: true,
      count: credentialCount,
      limit: credentialLimit,
      progressTitle: "Credentials",
      title: "Add New Credentials",
      icon: <LockOutlined />,
      description:
        "Add additional credentials to this tenant with GCP, AWS, or Azure",
      link: `/dashboard/${tenant}/credentials`,
      ctaText: "Add Now",
      ctaAction: () => {},
      isDisabled: credentialCount >= credentialLimit,
    },
    {
      withQuota: true,
      count: deviceCount,
      limit: deviceLimit,
      progressTitle: "Devices",
      title: "Add New Device",
      icon: <DesktopOutlined />,
      description:
        "Add additional device without agent or with agent to maintenance",
      link: `/dashboard/${tenant}/devices`,
      ctaText: "Add Now",
      ctaAction: () => {},
      isDisabled: deviceCount >= deviceLimit,
    },
    {
      withQuota: true,
      count: firewallCount,
      limit: firewallLimit,
      progressTitle: "Firewall",
      title: "Add New Firewall",
      icon: <ThunderboltOutlined />,
      description: "Add additional firewall to this tenant",
      link: `/dashboard/${tenant}/firewalls`,
      ctaText: "Add Now",
      ctaAction: () => {},
      isDisabled: firewallCount >= firewallLimit,
    },
    {
      withQuota: false,
      count: null,
      limit: null,
      progressTitle: null,
      title: "Connect Device and Firewall",
      icon: <DeploymentUnitOutlined />,
      description:
        "Manage your device and firewall connection to make it integrated",
      link: `/dashboard/${tenant}/manage-link`,
      ctaText: "Manage Now",
      ctaAction: () => {},
    },
    {
      withQuota: false,
      count: null,
      limit: null,
      progressTitle: null,
      title: "Invite your team member",
      icon: <UserAddOutlined />,
      description:
        "Add additional admins to help with your integration and act as a backup account in case you lose access.",
      link: `/dashboard/${tenant}/settings/memebers`,
      ctaText: "Invite Now",
      ctaAction: () => {},
      isDisabled: true,
    },
    {
      withQuota: false,
      count: null,
      limit: null,
      progressTitle: null,
      title: "Help & Support",
      icon: <QuestionOutlined />,
      description:
        "When got some issues and some problem contact me as well here",
      link: `/`,
      ctaText: "Contact Us",
      ctaAction: () => {},
    },
    {
      withQuota: false,
      count: null,
      limit: null,
      progressTitle: null,
      title: "Install Agent",
      icon: <DownloadOutlined />,
      description:
        "Install our agent on your PC and make it easier to add and manage your device",
      link: null,
      ctaText: "Download Now",
      ctaAction: () => handleDownloadModal(),
    },
  ];
  //#endregion

  //? ============== Handle Download Agent ============= ?//
  const [downloadModal, setDownloadModal] = useState(false);
  const handleDownloadModal = async () => {
    setDownloadModal(!downloadModal);
  };
  // * ====================================== * //

  return (
    <>
      <Card title="Control Panel" className={s.container}>
        {/* //? ============== Global Counter ============= ?// */}
        <Col span={24} style={{ marginBottom: 50 }}>
          <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
            <Col span={8}>
              <Card>
                <h1 className={s.countTitle}>
                  <span className={s.countIcon}>
                    <LockOutlined />
                  </span>
                  Credentials
                </h1>
                <h3>{credentialCount}</h3>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h1 className={s.countTitle}>
                  <span className={s.countIcon}>
                    <DesktopOutlined />
                  </span>
                  Device
                </h1>
                <h3>{deviceCount}</h3>
              </Card>
            </Col>
            <Col span={8}>
              <Card>
                <h1 className={s.countTitle}>
                  <span className={s.countIcon}>
                    <KeyOutlined />
                  </span>
                  Firewalls
                </h1>
                <h3>{firewallCount}</h3>
              </Card>
            </Col>
          </Row>
        </Col>
        {/* // * ====================================== * // */}

        {/* //? ============== Account Quota & Quickstart ============= ?// */}
        <Col span={24}>
          <Row gutter={[16, 0]} style={{ marginBottom: 20 }}>
            {/* //? ============== Quickstart ============= ?// */}
            <Col span={24} style={{ marginBottom: 50 }}>
              <Row>
                <Col span={24} className={s.detailCardTitle}>
                  <h2>Quickstart Action & Quota</h2>
                  {quickActionList.map((item) => {
                    return (
                      <>
                        <CompLibsQuickActionAndQuota
                          count={item.count}
                          ctaAction={item.ctaAction}
                          ctaText={item.ctaText}
                          description={item.description}
                          icon={item.icon}
                          limit={item.limit}
                          link={item.link}
                          progressTitle={item.progressTitle}
                          title={item.title}
                          withQuota={item.withQuota}
                          isDisabled={item.isDisabled}
                        />
                      </>
                    );
                  })}
                </Col>
              </Row>
            </Col>
            {/* // * ====================================== * // */}
          </Row>
        </Col>
        {/* //? Activities  */}
        <Col span={24}>
          <Card>
            <Col span={24} className={s.detailCardTitle}>
              <h2>Activities</h2>
            </Col>
            <Col span={24}>
              <List
                dataSource={[
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "ADD",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "UPDATE",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "DELETE",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "ADD",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "UPDATE",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "DELETE",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "DELETE",
                  },
                  {
                    description:
                      "Lorem ipsum dolor sit amet, consectetur adipiscing elit,",
                    type: "DELETE",
                  },
                ]}
                renderItem={(item) => {
                  return (
                    <>
                      <List.Item>
                        <p>
                          <span>
                            <Tag
                              color={
                                item.type === "ADD"
                                  ? "success"
                                  : item.type === "UPDATE"
                                  ? "blue"
                                  : item.type === "DELETE"
                                  ? "error"
                                  : "default"
                              }
                            >
                              {item.type}
                            </Tag>
                          </span>
                          {item.description}
                        </p>
                      </List.Item>
                    </>
                  );
                }}
              />
            </Col>
          </Card>
        </Col>
        {/* // * ====================================== * // */}

        <ModalDownloadAgentInstaller
          visible={downloadModal}
          onCancel={handleDownloadModal}
        />
      </Card>
    </>
  );
}

export default withAuthenticationRequired(DashboardControlPanel, {
  onRedirecting: () => <MainLoading />,
});
