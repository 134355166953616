// Libs
import ModalPlanSuggestion from "components/libs/modal/plan-suggestion";
import React, { useState } from "react";
import { createContext, useContext } from "react";

// #region Initial Context Value
const planSuggestionContext = createContext({
  setOpenModal: () => {},
  setTopic: () => {},
  setTenantName: () => {},
});
// #endregion

// #region Initial Context Providers
export function PlanSuggestionContextProvider(props) {
  const [topic, setTopic] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [tenantName, setTenantName] = useState("");

  const context = {
    setTopic: setTopic,
    setOpenModal: setOpenModal,
    setTenantName: setTenantName,
  };

  return (
    <planSuggestionContext.Provider value={context}>
      {props.children}

      <ModalPlanSuggestion
        topic={topic}
        open={openModal}
        setOpen={setOpenModal}
        tenantName={tenantName}
      />
    </planSuggestionContext.Provider>
  );
}
// #endregion
export default PlanSuggestionContextProvider;

// #region Context Hook Preparation
export const usePlanSuggestion = () => useContext(planSuggestionContext);
// #endregion
