import { Col, Progress, Tooltip } from "antd";
import React from "react";
import s from "./index.module.scss";
import { WarningOutlined } from "@ant-design/icons";
import propTypes from "prop-types";
import { Link, useParams } from "react-router-dom";

export default function CompLibsCircleProgressBar(props) {
  const { count, limit, title } = props;
  const { tenant } = useParams();

  const percentage = (count / limit) * 100;
  const format = `${count}/${limit}`;
  const isOK = percentage <= 50;
  const isWarning = percentage > 50 && percentage <= 100;
  const isOver = percentage > 100;
  const status = (count / limit) * 100 <= 100 ? "normal" : "exception";

  return (
    <>
      <Col span={24} style={{ textAlign: "center" }}>
        <Progress
          type="circle"
          percent={percentage}
          format={() => format}
          strokeColor={
            isOK
              ? "#52c41a"
              : isWarning
              ? "#faad14"
              : isOver
              ? "#f5222d"
              : "grey"
          }
          status={status}
        />
        <Tooltip
          title={
            isOver ? (
              <div>
                Over Limit: Adding new {title.toLowerCase()} is currently
                restricted. To unlock this feature, simply{" "}
                <Link to={`/dashboard/${tenant}/settings/subscription`}>
                  upgrade your plan here
                </Link>
              </div>
            ) : (
              ""
            )
          }
        >
          <div className={`${s.progressTitle} ${isOver ? "danger" : ""}`}>
            {title} {isOver ? <WarningOutlined /> : ""}
          </div>
        </Tooltip>
      </Col>
    </>
  );
}

CompLibsCircleProgressBar.propTypes = {
  count: propTypes.number,
  limit: propTypes.number,
  title: propTypes.string,
};
