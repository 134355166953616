// Libs
import moment from "moment";
import React from "react";
import { Col, Row, Tag } from "antd";

export const DeviceHistoryColumns = (props) => {
  const { data, page } = props;
  return [
    {
      title: "Update Date",
      dataIndex: "updated_date",
      key: "updated_date",
      align: "center",
      width: 200,
      render: (t, r, i) => {
        const trueIndex = i + page.pageSize * (page.current - 1);
        const obj = {
          children: moment(r.updatedDate).format("YYYY-MM-DD"),
          props: {},
        };
        if (i >= 1 && r.dataIndex === data[trueIndex - 1].dataIndex) {
          obj.props.rowSpan = 0;
        } else {
          for (
            let j = 0;
            trueIndex + j !== data.length &&
            r.dataIndex === data[trueIndex + j].dataIndex;
            j = j + 1
          ) {
            obj.props.rowSpan = j + 1;
          }
        }
        return obj;
      },
    },
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
      render: (t, r) => {
        return (
          <div style={{ minWidth: 200 }}>
            {r.field === "ipv4"
              ? "IPv4"
              : r.field === "ipv6"
              ? "IPv6"
              : r.field}
          </div>
        );
      },
    },
    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
      render: (t, r, i) => {
        if (r.field === "ipv4" || r.field === "ipv6") {
          return (
            <>
              <Row gutter={[0, 8]}>
                {r.oldValue && r.oldValue?.length !== 0
                  ? r.oldValue?.map((item, index) => {
                      return (
                        <Col>
                          <Tag key={index}>{item}</Tag>
                        </Col>
                      );
                    })
                  : "-"}
              </Row>
            </>
          );
        } else {
          return <>{r.oldValue}</>;
        }
      },
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
      render: (t, r, i) => {
        if (r.field === "ipv4" || r.field === "ipv6") {
          return (
            <>
              <Row gutter={[0, 8]}>
                {r.newValue && r.newValue?.length !== 0
                  ? r.newValue?.map((item, index) => {
                      return (
                        <Col>
                          <Tag key={index}>{item}</Tag>
                        </Col>
                      );
                    })
                  : "-"}
              </Row>
            </>
          );
        } else {
          return <>{r.newValue}</>;
        }
      },
    },
  ];
};
