// Libs
import React from "react";
import moment from "moment-timezone";
import { Badge, Col, Row, Tooltip } from "antd";

// Styles
import s from "./utils.module.scss";

// Icons
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { deleteConfirmation } from "utils/deleteConfirmation";

// Hooks
import useQueryString from "hooks/useQueryString";
import TableSearch from "components/libs/table-search";

export const ControlPanelCredentialsColumns = ({
  onDelete,
  onUpdateGcp,
  onUpdateAws,
  onUpdateAzure,
}) => {
  //? ============== Handle Delete ============= ?//
  const handleDelete = (name, type) => {
    deleteConfirmation({
      title: "",
      content: "",
      handleOk: () => onDelete(name, type),
    });
  };
  // * ====================================== * //

  const query = useQueryString();
  const mode = query.get("mode");
  const selectedId = query.get("credId");

  const { getColumnSearchProps } = TableSearch();

  return [
    {
      title: "ID",
      dataIndex: "credentialId",
      key: "credentialId",
      ...getColumnSearchProps("credentialId"),
    },
    {
      title: "Cloud Id",
      dataIndex: "cloudId",
      key: "cloudId",
      ...getColumnSearchProps("cloudId"),
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (t, r) => {
        return (
          <>
            {r.status === "VALID" ? (
              <Badge status="success" text="Valid" />
            ) : (
              <Badge
                status="warning"
                text={r.status.replace(/_/g, " ").toLowerCase()}
                style={{ textTransform: "capitalize" }}
              />
            )}
          </>
        );
      },
    },
    {
      title: "Location",
      dataIndex: "location",
      key: "location",
      filters: [
        { text: "GCP", value: "gcp" },
        { text: "AWS", value: "aws" },
        { text: "Azure", value: "azure" },
      ],
      onFilter: (value, record) => record.location.toLowerCase() === value,
      render: (t, r) => {
        return (
          <>
            <div className={s.locationDataContainer}>
              <span className={s.locationLogo}>
                <img
                  src={
                    r.location.toLowerCase() === "gcp"
                      ? "/gcloud-logo.png"
                      : r.location.toLowerCase() === "aws"
                      ? "/aws-logo.png"
                      : r.location.toLowerCase() === "azure" &&
                        "/azure-logo.png"
                  }
                  alt="gcp"
                />
              </span>
              {r.location.toUpperCase()}
            </div>
          </>
        );
      },
    },
    {
      title: "Location Type",
      dataIndex: "locationType",
      key: "locationType",
      ...getColumnSearchProps("locationType"),
    },
    {
      title: "Credentials Type",
      dataIndex: "credentialType",
      key: "credentialType",
      ...getColumnSearchProps("credentialType"),
    },
    {
      title: "Last Updated",
      dataIndex: "lastUpdated",
      key: "lastUpdated",
      render: (t, r) => {
        const lastDateUpdate = r?.lastUpdate;
        return (
          <>
            <p style={{ margin: 0 }}>
              Date :{moment(lastDateUpdate).format("DD MMMM YYYY")}
            </p>
            <p style={{ margin: 0 }}>
              Time :{moment(lastDateUpdate).format("hh:mm:ss")}
            </p>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      width: 100,
      render: (t, r) => {
        const isHighlight = mode === "edit" && r.credentialId === +selectedId;
        return (
          <>
            <Row gutter={[16, 0]}>
              <Col
                className={`${s.actionIcon} ${isHighlight ? s.highlight : ""}`}
                id={`${isHighlight ? "credentials-edit-highlight" : ""}`}
              >
                <Tooltip title="Edit" defaultOpen={isHighlight}>
                  <EditFilled
                    onClick={() => {
                      if (r.location === "GCP") {
                        onUpdateGcp(r.credentialId, r.cloudId);
                      }
                      if (r.location === "AWS") {
                        onUpdateAws(r);
                      }
                      if (r.location === "AZURE") {
                        onUpdateAzure(r);
                      }
                    }}
                  />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="Delete">
                  <DeleteFilled onClick={() => handleDelete(r.credentialId)} />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
};
