// Libs
import propTypes from "prop-types";
import React, { useState } from "react";
import { Fade } from "react-reveal";
import { Carousel, CarouselItem, Col, Container, Row } from "reactstrap";

// Components
import MainButton from "components/libs/button";

// Initial Data
import { introSlider } from "data/homepage";

// Styles
import s from "./index.module.scss";

function HomepageContentsIntroSection(props) {
  const { hideSignUpButton, handleSignUp } = props;

  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === introSlider.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? introSlider.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  return (
    <Fade>
      <Container>
        <Row style={{ paddingTop: "100px" }}>
          <Col className={s.slogan}>
            <h2 className="h2">
              Precision Network Defence: Supercharge Your Firewalls Across
              On-Premise and Multi-Cloud, Reducing Network Attack Surface.
            </h2>
            <h5>Automatically update</h5>
            <Carousel
              className="mb-2"
              activeIndex={activeIndex}
              next={next}
              previous={previous}
              interval={1600}
              ride="carousel"
            >
              {introSlider.map((item) => {
                return (
                  <CarouselItem
                    tag="div"
                    key={item.id}
                    onExiting={() => setAnimating(true)}
                    onExited={() => setAnimating(false)}
                  >
                    <div className={`${s.textCarouselItem}`}>
                      {item.caption}
                    </div>
                  </CarouselItem>
                );
              })}
            </Carousel>
            <h5>with exact IP device located anywhere</h5>
            {!hideSignUpButton && (
              <div style={{ marginTop: 30 }}>
                <MainButton onClick={handleSignUp}>
                  Sign Up for Free Trial
                </MainButton>
              </div>
            )}
          </Col>
          <Col>
            <img alt="Connection map" src="img1.png"></img>
          </Col>
        </Row>
      </Container>
    </Fade>
  );
}

HomepageContentsIntroSection.propTypes = {
  hideSignUpButton: propTypes.bool,
  handleSignUp: propTypes.func,
};

export default HomepageContentsIntroSection;
