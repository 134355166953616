import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, notification } from "antd";
import React from "react";

export default function NotificationErrorRemoveCredentials({
  onClick = () => {},
}) {
  return notification.error({
    message: "Oops! Something went wrong",
    description: (
      <>
        <Col span={24}>
          <p>
            Please remove the related firewall-destination before attempting to
            remove these credentials.
          </p>
        </Col>
        <Col>
          <Button
            type="link"
            style={{
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
            onClick={onClick}
          >
            Remove firewall-destination <ArrowRightOutlined />
          </Button>
        </Col>
      </>
    ),
  });
}
