// Libs
import React, { useState } from "react";
import { Descriptions, Spin, Table, Tag, Typography } from "antd";
import { useHistory, useParams } from "react-router-dom";

// Components
import { DeviceHistoryColumns } from "./utils";
import PageHeader from "components/libs/page-header";

// Hooks
import { useSDKDeviceHistory } from "hooks/device-sdk";

// Styles
import s from "./index.module.scss";

const { Title } = Typography;

function DashboardContentsDeviceHistory() {
  const history = useHistory();
  const params = useParams();
  const [page, setPage] = useState({
    current: 1,
    pageSize: 10,
  });

  //? ============== Device History Hooks ============= ?//
  const { data: deviceHistory, loading } = useSDKDeviceHistory({
    tenantName: params.tenant,
    id: params.deviceId,
  });
  // * ====================================== * //

  //? ============== Handle Columns ============= ?//
  const dataParseList = deviceHistory?.history?.map((item, index) => {
    return item.updateList.map((list, listIndex) => {
      return {
        ...list,
        updatedDate: item.updatedDate,
        fieldNumber: listIndex === 0 && item.updateList.length,
        isMerge: listIndex !== 0,
        dataIndex: index,
      };
    });
  });
  const dataParse = dataParseList?.flat(1);
  const column = DeviceHistoryColumns({
    data: dataParse,
    page: page,
  });
  // * ====================================== * //

  //#region Handle change
  const handleChange = (pagination) => {
    setPage(pagination);
  };
  //#endregion

  return (
    <>
      <PageHeader
        title="Device History"
        onBack={() => history.goBack()}
        className={s.header}
      />

      <section style={{ marginBottom: 50 }}>
        <Spin spinning={loading}>
          <Descriptions
            title="Device Details"
            className={s.details_container}
            column={2}
          >
            <Descriptions.Item label="Client UUID" span={2}>
              {deviceHistory?.clientUUID}
            </Descriptions.Item>
            <Descriptions.Item label="Host Name" span={2}>
              {deviceHistory?.hostName}
            </Descriptions.Item>
            <Descriptions.Item label="IPv4" span={2}>
              {deviceHistory?.ipv4?.map((item, index) => {
                return <Tag key={index}>{item}</Tag>;
              })}
            </Descriptions.Item>
            <Descriptions.Item label="IPv6" span={2}>
              {deviceHistory?.ipv6?.map((item, index) => {
                return <Tag key={index}>{item}</Tag>;
              })}
            </Descriptions.Item>
          </Descriptions>
        </Spin>
      </section>

      <Title level={5}>Device History</Title>
      {deviceHistory ? (
        <Table
          columns={column}
          dataSource={dataParse}
          loading={loading}
          rowClassName={(r, i) => (r.dataIndex % 2 !== 0 ? s.odd_rows : "")}
          onChange={handleChange}
          bordered
          className={s.table}
        />
      ) : (
        ""
      )}
    </>
  );
}

export default DashboardContentsDeviceHistory;
