/* eslint-disable no-template-curly-in-string */

const runtimeConfig = {
  apiOrigin: "${_REACT_APP_API_ORIGIN}",
  domain: "${_REACT_APP_AUTH0_DOMAIN}",
  clientId: "${_REACT_APP_AUTH0_CLIENT_ID}",
  audience: "${_REACT_APP_AUTH0_AUDIENCE}",
  scope: "${_REACT_APP_AUTH0_SCOPE}",
  zohoSubscriptionsIntegrationOrigin:
    "${_REACT_APP_ZOHO_SUBSCRIPTIONS_INTEGRATION_ORIGIN}",
  agentInstallerLinux: "${_REACT_APP_AGENT_INSTALLER_LINUX}",
  agentInstallerWindows: "${_REACT_APP_AGENT_INSTALLER_WINDOWS}",
  agentInstallerMacOS: "${_REACT_APP_AGENT_INSTALLER_MACOS}",
  agentInstallerAndroid: "${_REACT_APP_AGENT_INSTALLER_ANDROID}",
  agentInstallerIos: "${_REACT_APP_AGENT_INSTALLER_IOS}",
  devMode: "${_REACT_APP_DEV_MODE}",
};

// note: runtime config overrides build time environment if provided
export function getConfig() {
  return {
    apiOrigin: getEnvironmentVariable(
      runtimeConfig.apiOrigin,
      process.env.REACT_APP_API_ORIGIN
    ),
    domain: getEnvironmentVariable(
      runtimeConfig.domain,
      process.env.REACT_APP_AUTH0_DOMAIN
    ),
    clientId: getEnvironmentVariable(
      runtimeConfig.clientId,
      process.env.REACT_APP_AUTH0_CLIENT_ID
    ),
    audience: getEnvironmentVariable(
      runtimeConfig.audience,
      process.env.REACT_APP_AUTH0_AUDIENCE
    ),
    scope: getEnvironmentVariable(
      runtimeConfig.scope,
      process.env.REACT_APP_AUTH0_SCOPE
    ),
    zohoSubscriptionsIntegrationOrigin: getEnvironmentVariable(
      runtimeConfig.zohoSubscriptionsIntegrationOrigin,
      process.env.REACT_APP_ZOHO_SUBSCRIPTIONS_INTEGRATION_ORIGIN
    ),
    agentInstallerLinux: getEnvironmentVariable(
      runtimeConfig.agentInstallerLinux,
      process.env.REACT_APP_AGENT_INSTALLER_LINUX
    ),
    agentInstallerWindows: getEnvironmentVariable(
      runtimeConfig.agentInstallerWindows,
      process.env.REACT_APP_AGENT_INSTALLER_WINDOWS
    ),
    agentInstallerMacOS: getEnvironmentVariable(
      runtimeConfig.agentInstallerMacOS,
      process.env.REACT_APP_AGENT_INSTALLER_MACOS
    ),
    agentInstallerAndroid: getEnvironmentVariable(
      runtimeConfig.agentInstallerAndroid,
      process.env.REACT_APP_AGENT_INSTALLER_ANDROID
    ),
    agentInstallerIos: getEnvironmentVariable(
      runtimeConfig.agentInstallerIos,
      process.env.REACT_APP_AGENT_INSTALLER_IOS
    ),
    devMode: getEnvironmentVariable(
      runtimeConfig.devMode,
      process.env.REACT_APP_DEV_MODE
    ),
  };
}

// returns default value if runtime environment variable was not substituted
const getEnvironmentVariable = (value, defaultValue) => {
  return value.startsWith("${") ? defaultValue : value;
};
