// Libs
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "@auth0/auth0-react";
import history from "./utils/history";
import { getConfig } from "./config";
import { BrowserRouter } from "react-router-dom";
import { SWRConfig } from "swr";
import { fetcher } from "utils/api";

// Routes
import App from "./App";

//? ============== Handle Auth0 ============= ?//
// Please see https://auth0.github.io/auth0-react/interfaces/auth0_provider.auth0provideroptions.html
// for a full list of the available properties on the provider
const config = getConfig();
const onRedirectCallback = (appState) => {
  history.push(
    appState && appState.returnTo ? appState.returnTo : window.location.pathname
  );
};
const providerConfig = {
  domain: config.domain,
  clientId: config.clientId,
  ...(config.audience ? { audience: config.audience } : null),
  scope: config.scope,
  redirectUri: `${window.location.origin}/profile`,
  useRefreshTokens: true,
  onRedirectCallback,
};
// * ====================================== * //

ReactDOM.render(
  <SWRConfig value={{ refreshInterval: 0, fetcher: fetcher }}>
    <BrowserRouter>
      <Auth0Provider {...providerConfig}>
        <App />
      </Auth0Provider>
    </BrowserRouter>
  </SWRConfig>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
