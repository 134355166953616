import { v4 as uuid } from "uuid";

//#region Dashboard homepage
export const dashboardHomepageSample = {
  tenantName: "<string>",
  firewallDestinationCount: "2",
  deviceCount: "3",
  credentialCount: "1",
};

//#endregion

//? Credentials Data Sample
export const credentialsDataSample = [
  {
    credentialId: 1,
    cloudId: "lea.buckley@gmail.com",
    status: "VALID",
    location: "gcp",
    locationType: "cloud",
    credentialType: "OAuth2 authorization",
    lastUpdate: "01-06-2023",
  },
  {
    credentialId: 2,
    cloudId: "john.mayer@gmail.com",
    status: "EXPIRED_CREDENTIALS",
    location: "gcp",
    locationType: "cloud",
    credentialType: "OAuth2 authorization",
    lastUpdate: "01-06-2023",
  },
  {
    credentialId: 3,
    cloudId: "john.doe@gmail.com",
    status: "WAITING_APPROVAL",
    location: "gcp",
    locationType: "cloud",
    credentialType: "OAuth2 authorization",
    lastUpdate: "01-06-2023",
  },
  {
    credentialId: 4,
    cloudId: "steven.kesey@gmail.com",
    status: "INVALID_CREDENTIALS",
    location: "gcp",
    locationType: "cloud",
    credentialType: "OAuth2 authorization",
    lastUpdate: "01-06-2023",
  },
];

//? Credentials Options On Add Modal
export const credentialsOption = [
  {
    logo: "/gcloud-logo.png",
    label: "Google",
    long_label: "Google Cloud Platform (GCP)",
    key: "gcp",
    dataTestId: "credentials-option-gcp",
  },
  {
    logo: "/aws-logo.png",
    label: "AWS",
    long_label: "Amazon Web Service (AWS)",
    key: "aws",
    dataTestId: "credentials-option-aws",
  },
  {
    logo: "/azure-logo.png",
    label: "Azure",
    long_label: "Azure",
    key: "azure",
    dataTestId: "credentials-option-azure",
  },
];

//? Device Data Sample
export const devicesDataSample = [
  {
    id: 1,
    clientUUID: uuid(),
    hostName: "sample-device-1",
    ipv4: "182.253.132.250",
    ipv6: "2001:0db8:85a3:0000:0000:8a2e:0370:7334",
    last_update: "2022-02-02 13:42:21",
  },
  {
    id: 2,
    clientUUID: uuid(),
    hostName: "sample-device-2",
    ipv4: "[182.253.132.250, 66.94.29.13]",
    ipv6: `[2001:0000:3238:DFE1:0063:0000:0000:FEFB,2001:0db8:85a3:0000:0000:8a2e:0370:7334]`,
    last_update: "2022-02-02 13:42:21",
  },
  {
    id: 3,
    clientUUID: uuid(),
    hostName: "sample-device-3",
    ipv4: "[182.253.132.250, 66.94.29.13]",
    ipv6: `[2001:0000:3238:DFE1:0063:0000:0000:FEFB,2001:0db8:85a3:0000:0000:8a2e:0370:7334]`,
    last_update: "2022-02-02 13:42:21",
  },
  {
    id: 4,
    clientUUID: uuid(),
    hostName: "sample-device-4",
    ipv4: "[182.253.132.250, 66.94.29.13]",
    ipv6: `[2001:0000:3238:DFE1:0063:0000:0000:FEFB,2001:0db8:85a3:0000:0000:8a2e:0370:7334]`,
    last_update: "2022-02-02 13:42:21",
  },
  {
    id: 5,
    clientUUID: uuid(),
    hostName: "sample-device-5",
    ipv4: "[182.253.132.250, 66.94.29.13]",
    ipv6: `[2001:0000:3238:DFE1:0063:0000:0000:FEFB,2001:0db8:85a3:0000:0000:8a2e:0370:7334]`,
    last_update: "2022-02-02 13:42:21",
  },
  {
    id: 6,
    clientUUID: uuid(),
    hostName: "sample-device-6",
    ipv4: "[182.253.132.250, 66.94.29.13]",
    ipv6: `[2001:0000:3238:DFE1:0063:0000:0000:FEFB,2001:0db8:85a3:0000:0000:8a2e:0370:7334]`,
    last_update: "2022-02-02 13:42:21",
  },
  {
    id: 7,
    clientUUID: uuid(),
    hostName: "sample-device-7",
    ipv4: "[182.253.132.250, 66.94.29.13]",
    ipv6: `[2001:0000:3238:DFE1:0063:0000:0000:FEFB,2001:0db8:85a3:0000:0000:8a2e:0370:7334]`,
    last_update: "2022-02-02 13:42:21",
  },
];

//? Firewall Data Sample
export const firewallDataSample = [
  {
    firewallDestinationId: 1,
    cloudId: "lea.buckley@gmail.com",
    projectName: "sample-firewall-destination",
    vpcName: null,
    firewallRuleName: "sample-firewall-destination",
    firewallIpVersion: "IPv4",
    placeholderIpAddress: "127.0.0.1",
    status: "ACTIVE",
    updateMode: "APPEND",
  },
  {
    firewallDestinationId: 2,
    cloudId: "john.doe@gmail.com",
    projectName: "sample-firewall-destination",
    vpcName: null,
    firewallRuleName: "sample-firewall-destination",
    firewallIpVersion: "IPv4",
    placeholderIpAddress: "127.0.0.1",
    status: "INACTIVE",
    updateMode: "TRACK",
  },
  {
    firewallDestinationId: 3,
    cloudId: "lea.buckley@gmail.com",
    projectName: "example-firewall-destination",
    vpcName: null,
    firewallRuleName: "example-firewall-destination-ipv4",
    firewallIpVersion: "IPv4",
    placeholderIpAddress: "127.0.0.1",
    status: "ACTIVE",
    updateMode: "APPEND",
  },
  {
    firewallDestinationId: 4,
    cloudId: "john.doe@gmail.com",
    projectName: "sample-firewall-destination",
    vpcName: null,
    firewallRuleName: "sample-firewall-destination-ipv6",
    firewallIpVersion: "IPv6",
    placeholderIpAddress: "::1",
    status: "INACTIVE",
    updateMode: "TRACK",
  },
];
