// Libs
import moment from "moment";
import React from "react";

// Styles
import s from "./utils.module.scss";

export const FirewallDestinationHistoryColumns = () => {
  return [
    {
      title: "Field",
      dataIndex: "field",
      key: "field",
    },
    {
      title: "Old Value",
      dataIndex: "oldValue",
      key: "oldValue",
    },
    {
      title: "New Value",
      dataIndex: "newValue",
      key: "newValue",
    },
    {
      title: "Source",
      dataIndex: "source",
      key: "source",
    },
    {
      title: "Created On",
      dataIndex: "createdOn",
      key: "createdOn",
      render: (t, r) => {
        const createdOn = t.replace("[UTC]", "");
        return (
          <div className={s.lastUpdate}>
            <p>{moment(createdOn).format("DD MMMM YYYY")}</p>
            <p>{moment(createdOn).format("hh:mm:ss")}</p>
          </div>
        );
      },
    },
  ];
};
