// Libs
import React from "react";

// Contents
import HomepageContentsIntroSection from "components/contents/main/homepage/intro-section";
import HomepageContentsFeaturedSection from "components/contents/main/homepage/feature-section";
import HomepageContentsHowItWorksSection from "components/contents/main/homepage/how-it-works-section";
import HomepageContentsPricingSection from "components/contents/main/homepage/pricing-section";

// Data
import { useAuth0 } from "@auth0/auth0-react";
import HomepageContentsDownloadSection from "components/contents/main/homepage/download-section";
import HomepageContentsFaqSection from "components/contents/main/homepage/faq-section";
import HomepageContentsAgentSupportFeatures from "components/contents/main/homepage/agent-support-features";

function MainHomepage() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  return (
    <>
      <HomepageContentsIntroSection
        handleSignUp={() => loginWithRedirect({ screen_hint: "signup" })}
        hideSignUpButton={isAuthenticated}
      />
      <HomepageContentsFeaturedSection />
      <HomepageContentsHowItWorksSection />
      <HomepageContentsDownloadSection />
      <HomepageContentsPricingSection />
      <HomepageContentsAgentSupportFeatures />
      <HomepageContentsFaqSection />
    </>
  );
}

export default MainHomepage;
