import React from "react";
import Icon from "@ant-design/icons";

const SvgComponent = () => (
  <svg
    version="1.1"
    id="Capa_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 22.884 22.884"
    xmlSpace="preserve"
  >
    <g>
      <g>
        <path
          d="M13.045,1.997l-1.969,7.872C8.68,8.867,5.479,8.429,2.642,9.307c0.574-2.522,1.97-7.31,1.97-7.31
        C6.698,0.289,10.756,0.902,13.045,1.997z"
        />
        <path d="M14.184,3.145c2.147,1.257,5.943,1.645,8.7,0.822l-2.251,7.589c-2.313,1.224-6.735,1.133-8.433-0.563L14.184,3.145z" />
        <path
          d="M10.398,11.513l-1.967,7.87C5.953,18.243,2.794,17.481,0,18.82c0,0,1.297-5.069,1.966-7.588
        C4.131,9.889,8.444,10.286,10.398,11.513z"
        />
        <path d="M11.746,12.641c2.147,1.518,5.796,1.764,8.493,0.72l-2.247,7.592c-2.176,1.479-6.433,1.252-8.435-0.281L11.746,12.641z" />
      </g>
    </g>
  </svg>
);

export const WindowIcon = (props) => {
  return <Icon component={SvgComponent} {...props} />;
};
