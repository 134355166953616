import React from "react";
import { Col, Row, Tooltip } from "antd";
import { LockOutlined } from "@ant-design/icons";
import QuickstartActionItem from "../quicstart-action-item";
import CompLibsCircleProgressBar from "../circleProgressBar";
import propTypes from "prop-types";
import { useParams, Link } from "react-router-dom";

export default function CompLibsQuickActionAndQuota(props) {
  const {
    withQuota,
    count = 0,
    limit = 0,
    progressTitle = "",
    title = "",
    icon = <LockOutlined />,
    description = "",
    link = "",
    ctaText = "",
    ctaAction = () => {},
    isDisabled = false,
  } = props;
  const { tenant } = useParams();
  return (
    <>
      <Col span={24} style={{ marginBottom: 20 }}>
        <Row>
          <Tooltip
            placement="topRight"
            title={
              isDisabled ? (
                <div>
                  To {title.toLowerCase()} is currently restricted. Upgrade your
                  plan to enable these functionalities.
                  <br />
                  <Link to={`/dashboard/${tenant}/settings/subscription`}>
                    Upgrade Now!
                  </Link>
                </div>
              ) : (
                ""
              )
            }
          >
            <Col
              span={20}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <QuickstartActionItem
                icon={icon}
                title={title}
                description={description}
                link={
                  isDisabled
                    ? `/dashboard/${tenant}/settings/subscription`
                    : link
                }
                ctaText={!isDisabled ? ctaText : "Upgrade Plan"}
                ctaAction={ctaAction}
                isDisabled={isDisabled}
              />
            </Col>
          </Tooltip>
          {withQuota ? (
            <Col span={4}>
              <CompLibsCircleProgressBar
                count={count}
                limit={limit}
                title={progressTitle}
              />
            </Col>
          ) : (
            ""
          )}
        </Row>
      </Col>
    </>
  );
}

CompLibsQuickActionAndQuota.propTypes = {
  withQuota: propTypes.bool,
  count: propTypes.number,
  limit: propTypes.number,
  progressTitle: propTypes.string,
  title: propTypes.string,
  icon: propTypes.any,
  description: propTypes.string,
  link: propTypes.string,
  ctaText: propTypes.string,
  ctaAction: propTypes.func,
  isDisabled: propTypes.bool,
};
