// Libs
import React, { useState } from "react";
import propTypes from "prop-types";
import moment from "moment-timezone";
import { Col, Form, Modal, Select } from "antd";

// Components
import MainButton from "components/libs/button";

function ModalUpdateTimezone(props) {
  const { Option } = Select;
  const { visible, setVisible, userDetails, onUpdate } = props;

  //? ============== Handle Timezone Data ============= ?//
  const dataTimeZone = moment.tz.names().map((item, index) => {
    return {
      location: item.replace("_", " "),
      offset: moment().tz(item).format("Z"),
    };
  });
  // * ====================================== * //

  //? ============== Handle Change Timezone ============= ?//
  const [selectedTimezone, setSelectedTimezone] = useState();
  const handleChange = (e) => {
    e.preventDefault();
    const submission = {
      externalUserId: userDetails.externalUserId,
      timeZone: selectedTimezone,
    };
    onUpdate(submission);
  };
  // * ====================================== * //

  return (
    <>
      <Modal
        footer={false}
        title="Change Time Zone"
        open={visible}
        onCancel={setVisible}
      >
        <Form layout="vertical" onFinish={handleChange}>
          <Form.Item label="Select Time Zone :" name={"timezone"}>
            <Select
              placeholder="Select your timezone"
              onChange={(value) => setSelectedTimezone(value)}
            >
              {dataTimeZone.map((item, index) => {
                return (
                  <Option
                    key={index}
                    value={`${item.location} (GMT ${item.offset})`}
                  >
                    {item.location} (GMT {item.offset})
                  </Option>
                );
              })}
            </Select>
          </Form.Item>
        </Form>
        <Col style={{ textAlign: "right" }}>
          <MainButton type="primary" onClick={handleChange}>
            Save Changes
          </MainButton>
        </Col>
      </Modal>
    </>
  );
}

ModalUpdateTimezone.propTypes = {
  visible: propTypes.bool,
  setVisible: propTypes.func,
  userDetails: propTypes.any,
  onUpdate: propTypes.func,
};

export default ModalUpdateTimezone;
