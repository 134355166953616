// Libs
import React from "react";
import { Col } from "antd";
import { useHistory } from "react-router-dom";

// Components
import MainButton from "components/libs/button";

// Styles
import s from "./index.module.scss";

function Page404() {
  const history = useHistory();
  return (
    <>
      <div className={s.section}>
        <div className={s.container}>
          <Col span={24} className={s.titleContainer}>
            <h1 className={s.title}>404</h1>
            <h3>Page Not Found!</h3>
          </Col>
          <Col span={24}>
            <MainButton onClick={() => history.push("/")}>
              Go To Homepage
            </MainButton>
          </Col>
        </div>
      </div>
    </>
  );
}

export default Page404;
