// Libs
import React from "react";
import propTypes from "prop-types";
import { Button, Col, Modal, Row } from "antd";

// Assets
import { DownloadList } from "./utils";

// Styles
import s from "./index.module.scss";

function ModalDownloadAgentInstaller(props) {
  const { visible, onCancel } = props;

  const handleDownload = (url) => {
    if (url) {
      window.location.href = url;
      onCancel();
    }
  };

  const downloadList = DownloadList();

  return (
    <>
      <Modal open={visible} footer={false} onCancel={onCancel}>
        <h1 className={s.title}>Select Device</h1>
        <Row gutter={[16, 16]} align={"middle"} justify={"center"}>
          {downloadList.map((item) => {
            return (
              <Col span={8}>
                <Button
                  disabled={item.disabled}
                  onClick={() => handleDownload(item.url)}
                  icon={item.icon}
                  className={s.downloadButton}
                >
                  <div className={s.buttonContent}>
                    <p>Download for</p>
                    <h1>{item.label}</h1>
                    {item.disabled ? <span>(Coming soon)</span> : <></>}
                  </div>
                </Button>
              </Col>
            );
          })}
        </Row>
      </Modal>
    </>
  );
}

ModalDownloadAgentInstaller.propTypes = {
  visible: propTypes.bool,
  onCancel: propTypes.func,
};

export default ModalDownloadAgentInstaller;
