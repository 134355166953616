import { Card, Col, Row } from "antd";
import React from "react";
import { useHistory, useParams, useLocation } from "react-router-dom";

import MainButton from "components/libs/button";

import s from "./index.module.scss";

export default function TooManyRequestContent() {
  const history = useHistory();
  const location = useLocation();
  const { description } = location.state;
  const params = useParams();
  const { tenant: tenantName } = params;

  return (
    <>
      <Card>
        <Row align="middle" justify="center" className={s.section}>
          <Col span={12} className={s.textContainer}>
            <h1>429 too many request</h1>
            <div className={s.description}>
              {description ? (
                <div dangerouslySetInnerHTML={{ __html: description }} />
              ) : (
                <p>
                  We're sorry. But you have sent too many request to us
                  recently.
                  <br />
                  Please try again later
                </p>
              )}
            </div>

            <MainButton
              onClick={() => {
                history.push(`/dashboard/${tenantName}`);
              }}
            >
              Back to Dashboard
            </MainButton>
          </Col>
          <Col span={12} className={s.imageContainer}>
            <img src="/images/too-many-request-2.png" alt="" />
          </Col>
        </Row>
      </Card>
    </>
  );
}
