// Libs
import React, { useEffect, useState } from "react";
import { Button, Col, Row, Spin, Table } from "antd";
import { useParams } from "react-router-dom";

// Hooks
import { useSDKDevices } from "hooks/device-sdk";
import useQueryString from "hooks/useQueryString";

// Components
import { ControlPanelDevicesColumns } from "./utils";
import PageHeader from "components/libs/page-header";
import ModalAddDevice from "components/libs/modal/add-device";
import ModalUpdateDevice from "components/libs/modal/update-device";
import ModalTransferDevice from "components/libs/modal/transfer-device";
import ModalDownloadAgentInstaller from "components/libs/modal/download-agent-installer";
import ModalSharedDevice from "components/libs/modal/share-device";

// Styles
import s from "./index.module.scss";

// Icons
import { SyncOutlined } from "@ant-design/icons";

// Assets
import { devicesDataSample } from "data/control-panel";
import { useSDKDashboards } from "hooks/dashboard-sdk";
import { useSDKTenants } from "hooks/tenants-sdk";
import { planLimitation } from "data/plan-limitation";

function DashboardControlPanelDevice(props) {
  const params = useParams();
  const tenantName = params.tenant;
  const query = useQueryString();

  //#region Handle Add Device Modal
  const [showAddDevice, setShowAddDevice] = useState(false);
  const handleShowAddModal = (status = !showAddDevice) => {
    setShowAddDevice(status);
  };
  //#endregion

  //#region Handle Update Device Modal
  const [editValue, setEditValue] = useState("");
  const [showUpdateDevice, setShowUpdateDevice] = useState(false);
  const handleShowUpdateModal = () => {
    setShowUpdateDevice(!showUpdateDevice);
  };
  //#endregion

  //#region Handle device modal
  const [showTransferDeviceModal, setTransferDeviceModal] = useState();
  const [selectedTransferDeviceId, setSelectedTransferDeviceId] = useState();
  const handleShowTransferDeviceModal = (id) => {
    setTransferDeviceModal(!showTransferDeviceModal);
    if (id) {
      setSelectedTransferDeviceId(id);
    } else {
      setSelectedTransferDeviceId("");
    }
  };
  //#endregion

  //#region Handle download agent modal
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const handleShowDownloadModal = () => {
    setShowDownloadModal(!showDownloadModal);
    setShowAddDevice(!showAddDevice);
  };
  //#endregion

  //#region Handle download agent modal
  const [showSharedModal, setSharedModal] = useState(false);
  const [selectedSharedDeviceId, setSelectedSharedDeviceId] = useState();
  const handleShowSharedModal = (id) => {
    setSharedModal(!showSharedModal);
    if (id) {
      setSelectedSharedDeviceId(id);
    } else {
      setSelectedTransferDeviceId("");
    }
  };
  //#endregion

  //#region Device Hooks
  const {
    data: listOfDevices,
    loading,
    onAdd,
    onUnlink,
    onUpdate,
    refetch,
  } = useSDKDevices({
    tenantName: tenantName,
  });
  const deviceData = !tenantName.includes("<")
    ? listOfDevices
    : devicesDataSample;
  //#endregion

  //#region Dashboard hooks
  const { data: dashboardDetails } = useSDKDashboards({
    tenantName: tenantName,
  });
  const deviceCount = dashboardDetails?.deviceCount;
  //#endregion

  //#region Tenant Hooks
  const { data: tenantDetails } = useSDKTenants({
    tenantName: tenantName,
  });
  const tenantPlan = tenantDetails?.plan?.split("_")?.[0] || "free";
  //#endregion

  //#region Handle Columns
  const columns = ControlPanelDevicesColumns({
    onUnlink: onUnlink,
    showUpdateModal: handleShowUpdateModal,
    showTransferModal: handleShowTransferDeviceModal,
    showSharedModal: handleShowSharedModal,
    setEditValue: setEditValue,
  });
  //#endregion

  //#region Handle auto add device by code
  const customActions = query.get("actions");
  const customParams = query.get("params");
  const [doCustomActions, setDoCustomAction] = useState(false);
  useEffect(() => {
    if (!doCustomActions && deviceCount >= 0) {
      if (
        !deviceCount >= planLimitation[tenantPlan]?.device_limit &&
        customActions === "create-device-by-code"
      ) {
        console.log("called");
        setShowAddDevice(true);
      }
      setDoCustomAction(true);
    }
  }, [customActions, customParams, deviceCount, doCustomActions, tenantPlan]);
  //#endregion

  return (
    <>
      <PageHeader
        title="Devices"
        className={s.header}
        extra={[
          <>
            <Row gutter={[16, 0]}>
              <Col>
                <Button size="large" onClick={handleShowAddModal}>
                  + Add Devices
                </Button>
              </Col>
              <Col>
                <Button
                  size="large"
                  onClick={() => {
                    refetch();
                  }}
                >
                  <div className={s.buttonIcon}>
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginRight: 10,
                      }}
                    >
                      <SyncOutlined />
                    </span>
                    Refresh
                  </div>
                </Button>
              </Col>
            </Row>
          </>,
        ]}
      />
      <Spin spinning={loading}>
        {deviceData && (
          <Table
            columns={columns}
            dataSource={deviceData}
            scroll={{ x: 1200 }}
            className={s.table}
            tableLayout="auto"
          />
        )}
      </Spin>

      <ModalAddDevice
        handleVisible={handleShowAddModal}
        visible={showAddDevice}
        onAdd={onAdd}
        onDownload={handleShowDownloadModal}
      />

      <ModalUpdateDevice
        handleVisible={handleShowUpdateModal}
        visible={showUpdateDevice}
        onUpdate={onUpdate}
        currentValue={editValue}
      />

      <ModalTransferDevice
        visible={showTransferDeviceModal}
        handleClose={handleShowTransferDeviceModal}
        deviceId={selectedTransferDeviceId}
      />

      <ModalDownloadAgentInstaller
        visible={showDownloadModal}
        onCancel={handleShowDownloadModal}
      />

      <ModalSharedDevice
        visible={showSharedModal}
        handleClose={handleShowSharedModal}
        deviceId={selectedSharedDeviceId}
      />
    </>
  );
}

export default DashboardControlPanelDevice;
