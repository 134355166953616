// Libs
import moment from "moment";
import React from "react";
import { Button, Col, Row, Tag } from "antd";

// Icons
import { DisconnectOutlined, LinkOutlined } from "@ant-design/icons";

// Styles
import s from "./utils.module.scss";

export const FirewallDeviceManageLinkColumns = ({ status, onLink }) => {
  return [
    {
      title: "Client UUID",
      dataIndex: "client",
      key: "client",
      render: (t, r) => {
        return <>{r.clientUUID ? <Tag>{r.clientUUID}</Tag> : "-"}</>;
      },
    },
    {
      title: "Hostname",
      dataIndex: "hostname",
      key: "hostname",
      render: (t, r) => {
        return <div style={{ minWidth: 200 }}>{r.hostName}</div>;
      },
    },
    {
      title: "IPv4",
      dataIndex: "ipv4",
      key: "ipv4",
      render: (t, r) => {
        const IPv4List =
          r.ipv4 &&
          r.ipv4 !== "[]" &&
          r?.ipv4?.replace(/[[\]\s]+/g, "")?.split(",");
        return (
          <>
            <Row gutter={[0, 16]}>
              {IPv4List
                ? IPv4List?.map((item, index) => {
                    return (
                      <Col>
                        <Tag key={index}>{item}</Tag>
                      </Col>
                    );
                  })
                : "-"}
            </Row>
          </>
        );
      },
    },
    {
      title: "IPv6",
      dataIndex: "ipv6",
      key: "ipv6",
      render: (t, r) => {
        const IPv6List =
          r.ipv6 &&
          r.ipv6 !== "[]" &&
          r?.ipv6?.replace(/[[\]\s]+/g, "")?.split(",");
        return (
          <>
            <Row gutter={[0, 16]}>
              {IPv6List
                ? IPv6List?.map((item, index) => {
                    return (
                      <Col>
                        <Tag key={index}>{item}</Tag>
                      </Col>
                    );
                  })
                : "-"}
            </Row>
          </>
        );
      },
    },
    {
      title: "Last Update",
      dataIndex: "last_update",
      key: "last_update",
      render: (t, r) => {
        return (
          <div className={s.lastUpdate} style={{ minWidth: 200 }}>
            <p>Date: {moment(r?.last_update).format("DD MMMM YYYY")}</p>
            <p>Time: {moment(r?.last_update).format("hh:mm:ss")}</p>
          </div>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row gutter={[16, 0]} className={s.actionColumn}>
              <Col className={s.actionIcon}>
                <Button
                  onClick={() => {
                    onLink(r.id);
                  }}
                  type="primary"
                  danger={status === "available" ? false : true}
                  icon={
                    status === "available" ? (
                      <LinkOutlined />
                    ) : (
                      <DisconnectOutlined />
                    )
                  }
                >
                  {status === "available" ? "Link" : "Unlink"}
                </Button>
              </Col>
            </Row>
          </>
        );
      },
    },
  ];
};
