// Libs
import React from "react";
import propTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Divider, Row } from "antd";

// Components
import MainButton from "../button";

// Style
import s from "./index.module.scss";

function PricingListItem(props) {
  const {
    title,
    price,
    interval = "Month",
    description,
    features = [],
    logo,
    onCheckout,
    planCode,
    currentPlan,
  } = props;

  return (
    <>
      <Card className={s.card}>
        <div>
          <div className={s.icon}>{logo}</div>
          <h1 className={s.title}>{title}</h1>
          <h1 className={s.price}>
            {price !== "Contact Us" ? (
              <>
                <span className={s.amount}>{price}</span>
                <span className={s.interval}>/ {interval}</span>
              </>
            ) : (
              <span className={s.priceDesc}>Let's Talk With Us!</span>
            )}
          </h1>
          <h3 className={s.description}>{description}</h3>
          <Divider />
          {features.map((item, index) => {
            return (
              <Row gutter={[16, 0]} key={index}>
                <Col>
                  <FontAwesomeIcon icon="check" style={{ color: "#eed152" }} />
                </Col>
                <Col>{item}</Col>
              </Row>
            );
          })}
        </div>

        <div className={s.button} span={24}>
          <MainButton
            onClick={() => onCheckout(planCode)}
            disabled={planCode === currentPlan}
          >
            {planCode === currentPlan ? "Selected Plan" : "Upgrade Plan"}
          </MainButton>
        </div>
      </Card>
    </>
  );
}

PricingListItem.propTypes = {
  title: propTypes.string,
  price: propTypes.string,
  interval: propTypes.string,
  description: propTypes.string,
  features: propTypes.array,
  logo: propTypes.any,
  onCheckout: propTypes.func,
  planCode: propTypes.string,
  currentPlan: propTypes.string,
};

export default PricingListItem;
