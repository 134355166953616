// Libs
import React from "react";
import { Card } from "antd";

// Styles
import s from "./index.module.scss";

// Contents
import DashboardContentsDeviceHistory from "components/contents/dashboard/device-history";

function DashboardDevicesHistory() {
  return (
    <>
      <Card className={s.container}>
        <section className={s.section}>
          <DashboardContentsDeviceHistory />
        </section>
      </Card>
    </>
  );
}

export default DashboardDevicesHistory;
