// Libs
import React from "react";
import { useRouteMatch } from "react-router-dom";

// Icons
import {
  DesktopOutlined,
  SettingOutlined,
  AppstoreOutlined,
  KeyOutlined,
  LockOutlined,
  DeploymentUnitOutlined,
} from "@ant-design/icons";

function DashboardMenu() {
  let { url } = useRouteMatch();

  const dashboardMenu = [
    {
      label: "Dashboard",
      link: `${url}`,
      key: `${url}`,
      icon: <AppstoreOutlined />,
      dataTestId: "dashboard-side-menu-home",
    },
    {
      label: "Credentials",
      link: `${url}/credentials`,
      key: `${url}/credentials`,
      icon: <KeyOutlined />,
      dataTestId: "dashboard-side-menu-credentials",
    },
    {
      label: "Devices",
      link: `${url}/devices`,
      key: `${url}/devices`,
      icon: <DesktopOutlined />,
      dataTestId: "dashboard-side-menu-devices",
    },
    {
      label: "Firewalls",
      link: `${url}/firewalls`,
      key: `${url}/firewalls`,
      icon: <LockOutlined />,
      dataTestId: "dashboard-side-menu-firewalls",
    },
    {
      label: "Manage Link",
      link: `${url}/manage-link`,
      key: `${url}/manage-link`,
      icon: <DeploymentUnitOutlined />,
      dataTestId: "dashboard-side-menu-manage-link",
    },
    {
      label: "Settings",
      link: `${url}/settings`,
      key: `${url}/settings`,
      icon: <SettingOutlined />,
      dataTestId: "dashboard-side-menu-settings",
    },
  ];

  return dashboardMenu;
}

export default DashboardMenu;
