/* eslint-disable react-hooks/exhaustive-deps */

// Libs
import React, { useCallback, useEffect } from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { useLocation } from "react-router-dom";

// Hooks
import { useZohoSubscriptions } from "hooks/zoho-integration-sdk";
import { useSDKAuth } from "hooks/auth-sdk";

// Components
import MainLoader from "components/libs/loader";
import MainLoading from "components/libs/loading";

function PaymentRedirectPage() {
  const location = useLocation();
  const { user } = useAuth0();

  //? ============== Get Params ============= ?//
  const planCode = new URLSearchParams(location.search).get("planCode");
  // * ====================================== * //

  //? ============== Zoho Integration ============= ?//
  const { onCreateSubscription } = useZohoSubscriptions({
    subscriptionId: "",
  });
  // * ====================================== * //

  //? ============== Auth Hook ============= ?//
  const { authorizationLogin, authorizationEvent, isAuth } = useSDKAuth();

  //? Redirect to Dashboard
  const redirectToCheckout = useCallback(
    async ({ planCode }) => {
      const defaultTenant = await authorizationEvent();
      const submission = {
        email: user.email,
        planCode: planCode,
        tenantName: defaultTenant.tenantName,
      };
      onCreateSubscription(submission);
    },
    [authorizationEvent]
  );
  // * ====================================== * //

  //? ============== Handle Redirect to Default Tenant ============= ?//
  useEffect(() => {
    if (!isAuth) {
      localStorage.setItem("planCode", planCode);
      authorizationLogin();
    } else {
      const getPlanCode = localStorage.getItem("planCode");
      redirectToCheckout({ planCode: getPlanCode });
    }
  }, [authorizationLogin, isAuth, redirectToCheckout]);
  // * ====================================== * //

  return (
    <>
      <MainLoader />
    </>
  );
}

export default withAuthenticationRequired(PaymentRedirectPage, {
  onRedirecting: () => <MainLoading />,
  returnTo: "/",
});
