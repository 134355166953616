// Libs
import React, { useState } from "react";
import { Layout } from "antd";

// Styles
import s from "./index.module.scss";

// Components
import DashboardLayoutSider from "./sider";
import DashboardLayoutHeader from "./header";
import BetaBanner from "components/libs/beta-banner";

const { Content, Footer } = Layout;

function DashboardLayout(props) {
  const { children } = props;
  const [collapsed, setCollapsed] = useState(false);

  return (
    <>
      <Layout style={{ minHeight: "100vh" }} hasSider>
        <DashboardLayoutSider
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
        <Layout
          className={`site-layout ${s.layout} ${
            collapsed && s.layoutCollapsed
          }`}
        >
          <DashboardLayoutHeader />
          <Content
            style={{
              margin: "24px 16px 0",
              overflow: "initial",
            }}
          >
            {children}
          </Content>
          <Footer style={{ textAlign: "center" }}>
            © Copyright 2022 AllowExactly. All rights reserved.
          </Footer>
          <BetaBanner />
        </Layout>
      </Layout>
    </>
  );
}

export default DashboardLayout;
