// Libs
import React from "react";
import { Card } from "antd";

// Components
import DashboardControlPanelFirewall from "components/contents/dashboard/firewall";

// Styles
import s from "./index.module.scss";

function DashboardFirewalls() {
  return (
    <>
      <Card className={s.container}>
        <section className={s.section}>
          <DashboardControlPanelFirewall />
        </section>
      </Card>
    </>
  );
}

export default DashboardFirewalls;
