import { useMutation, useQueryClient } from "@tanstack/react-query";
import { message, notification } from "antd";
import { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import apiSDK from "utils/sdk-service";
import useAuthQuery from "./useAuthQuery";
import NotificationErrorSomethingWrong from "components/libs/notifications/something-wrong";

export const useSDKDeviceManageLink = ({ tenantName, id, timezone }) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isMutate, setIsMutate] = useState(true);

  //? Get Data
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const data = await apiSDK.manageLinkApi.getManageLinkDeviceDetail({
          deviceId: id,
        });
        setData(data);
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
        setIsMutate(false);
      }
    };

    if (isMutate) {
      getData();
    }
  }, [id, isMutate, tenantName, timezone]);

  //? Mutate Data
  const onMutate = () => {
    setIsMutate(!isMutate);
    setData();
  };

  //? Link Firewall
  const onLink = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const res =
          await apiSDK.manageLinkApi.linkBetweenDeviceAndFirewallDestination({
            v1ManageLinkForm: {
              deviceId: id,
              firewallDestinationId: data.firewallDestinationId,
              target: "FIREWALL_DESTINATION",
              tenantName: tenantName,
            },
          });
        if (res.success) {
          message.success("Successfully link firewall to this device");
          setIsMutate(!isMutate);
          return res.success;
        }
      } catch (error) {
        console.log(error);
        message.error("something wrong when link firewall to this device");
      } finally {
        setLoading(false);
      }
    },
    [id, isMutate, tenantName]
  );

  //? Unlink Firewall
  const onUnlink = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const res =
          await apiSDK.manageLinkApi.unlinkBetweenDeviceAndFirewallDestination({
            v1ManageLinkForm: {
              deviceId: id,
              firewallDestinationId: data.firewallDestinationId,
              target: "FIREWALL_DESTINATION",
              tenantName: tenantName,
            },
          });
        if (res.success) {
          message.success("Successfully unlink firewall to this device");
          setIsMutate(!isMutate);
          return res.success;
        }
      } catch (error) {
        console.log(error);
        message.error("something wrong when unlink firewall to this device");
      } finally {
        setLoading(false);
      }
    },
    [id, isMutate, tenantName]
  );

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: loading || !data,
    onMutate,
    onLink,
    onUnlink,
  };
};

export const useSDKFirewallDestinationManageLink = ({
  tenantName,
  id,
  timezone,
}) => {
  const [data, setData] = useState();
  const [loading, setLoading] = useState(false);
  const [isMutate, setIsMutate] = useState(true);

  //? Get Data
  useEffect(() => {
    const getData = async () => {
      try {
        setLoading(true);
        const data =
          await apiSDK.manageLinkApi.getManageLinkFirewallDestinationDetail({
            firewallDestinationId: id,
          });
        setData(data);
      } catch (error) {
        message.error(error.message);
      } finally {
        setLoading(false);
        setIsMutate(false);
      }
    };

    if (isMutate) {
      getData();
    }
  }, [id, isMutate, tenantName, timezone]);

  //? Mutate Data
  const onMutate = () => {
    setIsMutate(!isMutate);
    setData();
  };

  //? Link Firewall
  const onLink = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const res =
          await apiSDK.manageLinkApi.linkBetweenDeviceAndFirewallDestination({
            v1ManageLinkForm: {
              deviceId: data.deviceId,
              firewallDestinationId: id,
              target: "DEVICE",
              tenantName: tenantName,
            },
          });
        if (res.success) {
          message.success("Successfully link device to this firewall");
          setIsMutate(!isMutate);
          return res.success;
        }
      } catch (error) {
        console.log(error);
        message.error("something wrong when link device to this firewall");
      } finally {
        setLoading(false);
      }
    },
    [id, isMutate, tenantName]
  );

  //? Unlink Firewall
  const onUnlink = useCallback(
    async (data) => {
      try {
        setLoading(true);
        const res =
          await apiSDK.manageLinkApi.unlinkBetweenDeviceAndFirewallDestination({
            v1ManageLinkForm: {
              deviceId: data.deviceId,
              firewallDestinationId: id,
              target: "DEVICE",
              tenantName: tenantName,
            },
          });
        if (res.success) {
          message.success("Successfully unlink firewall to this device");
          setIsMutate(!isMutate);
          return res.success;
        }
      } catch (error) {
        console.log(error);
        message.error("something wrong when unlink firewall to this device");
      } finally {
        setLoading(false);
      }
    },
    [id, isMutate, tenantName]
  );

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: loading || !data,
    onMutate,
    onLink,
    onUnlink,
  };
};

export const useSDKManageLink = ({ tenantName, id, type, timezone }) => {
  const queryClient = useQueryClient();
  const { tenant } = useParams();
  const history = useHistory();

  //#region Get Data
  const { data, isFetching, error } = useAuthQuery({
    queryKey: ["manage-link", id, type],
    queryFn: async () => {
      if (type === "DEVICE") {
        const res = await apiSDK.manageLinkApi.getManageLinkDeviceDetail({
          deviceId: id,
        });
        return res;
      } else {
        const res =
          await apiSDK.manageLinkApi.getManageLinkFirewallDestinationDetail({
            firewallDestinationId: id,
          });
        return res;
      }
    },
    onError: () => {
      history.push(`/dashboard/${tenant}/error-page`);
    },
    enabled: !!id,
  });
  //#endregion

  //#region Link Manage Link
  const { mutateAsync: onLink, isLoading: onLinkLoading } = useMutation(
    useCallback(
      async (data) => {
        const res =
          await apiSDK.manageLinkApi.linkBetweenDeviceAndFirewallDestination({
            v1ManageLinkForm: {
              deviceId: data.deviceId,
              firewallDestinationId: data.firewallDestinationId,
              target: type === "DEVICE" ? "DEVICE" : "FIREWALL_DESTINATION",
              tenantName: tenantName,
            },
          });
        return res;
      },
      [tenantName, type]
    ),
    {
      onSuccess: (res) => {
        const source = type.toLowerCase().replace("_", "-");
        const target = type === "DEVICE" ? "DEVICE" : "FIREWALL_DESTINATION";
        const targetParse = target.toLowerCase().replace("_", "-");

        queryClient.invalidateQueries(["manage-link", id, type]);
        notification.success({
          message: "Successfully",
          description: `successfully link ${source} to ${targetParse}`,
        });
        return res.success;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //#region Unlink Manage Link
  const { mutateAsync: onUnlink, isLoading: onUnlinkLoading } = useMutation(
    useCallback(
      async (data) => {
        const res =
          await apiSDK.manageLinkApi.unlinkBetweenDeviceAndFirewallDestination({
            v1ManageLinkForm: {
              deviceId: data.deviceId,
              firewallDestinationId: data.firewallDestinationId,
              target: type === "DEVICE" ? "DEVICE" : "FIREWALL_DESTINATION",
              tenantName: tenantName,
            },
          });
        return res;
      },
      [tenantName, type]
    ),
    {
      onSuccess: (res) => {
        const source = type.toLowerCase().replace("_", "-");
        const target = type === "DEVICE" ? "DEVICE" : "FIREWALL_DESTINATION";
        const targetParse = target.toLowerCase().replace("_", "-");

        queryClient.invalidateQueries(["manage-link", id, type]);
        notification.success({
          message: "Successfully",
          description: `successfully un-link ${source} to ${targetParse}`,
        });

        return res;
      },
      onError: async (err) => {
        const errorResponse = await err.response.json();
        NotificationErrorSomethingWrong(errorResponse?.detail);
      },
    }
  );
  //#endregion

  //? Get Result
  const result = data?.result || [];

  return {
    data: result,
    loading: isFetching || onLinkLoading || onUnlinkLoading,
    onLink,
    onUnlink,
    error,
  };
};
