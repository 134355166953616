// Libs
import React from "react";
import { Card } from "antd";

// Components
import DashboardContentsFirewallDestinationManageLink from "components/contents/dashboard/firewall-manage-link";

// Styles
import s from "./index.module.scss";

function DashboardFirewallsDestinationManageLink() {
  return (
    <>
      <Card className={s.container}>
        <section className={s.section}>
          <DashboardContentsFirewallDestinationManageLink />
        </section>
      </Card>
    </>
  );
}

export default DashboardFirewallsDestinationManageLink;
