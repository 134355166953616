import { library } from "@fortawesome/fontawesome-svg-core";
import { faLink, faPowerOff, faUser, faQuestionCircle, faCheck, faTimes, faQuoteLeft} from "@fortawesome/free-solid-svg-icons";
import { faFacebook, faWhatsapp, faTwitter } from "@fortawesome/free-brands-svg-icons";

function initFontAwesome() {
  library.add(faLink);
  library.add(faUser);
  library.add(faPowerOff);
  library.add(faFacebook);
  library.add(faWhatsapp);
  library.add(faTwitter);
  library.add(faQuestionCircle);
  library.add(faCheck);
  library.add(faTimes);
  library.add(faQuoteLeft);
}

export default initFontAwesome;
