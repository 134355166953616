// Libs
import React, { useState } from "react";
import propTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";

// Components
import ImportantActionBox from "components/libs/important-action-box";

// Hooks
import { useSDKTenants } from "hooks/tenants-sdk";
import { useSDKTenantUsers } from "hooks/tenant-user-sdk";

// Styles
import s from "./index.module.scss";
import { useZohoSubscriptions } from "hooks/zoho-integration-sdk";
import { Spin } from "antd";

function DashboardSettingsAdvanced(props) {
  const { userId } = props;
  const { tenant } = useParams();
  const history = useHistory();

  //? ============== Tenant Hook ============= ?//
  const { onDelete, data: tenantDetails } = useSDKTenants({
    tenantName: tenant,
  });
  const planStatus = tenantDetails?.planInterval;
  const [afterCancelAndReactive, setAfterCancelAndReactive] = useState(false);
  // * ====================================== * //

  //? ============== Subscription Hook ============= ?//
  const {
    onCancelSubscription,
    onReactiveSubscription,
    loading: onSubscriptionLoading,
  } = useZohoSubscriptions({ subscriptionId: tenantDetails?.subscriptionId });
  // * ====================================== * //

  //? ============== Tenant User Hook ============= ?//
  const { data: tenantUser } = useSDKTenantUsers({
    tenantName: tenant,
  });
  // * ====================================== * //

  //? ============== Handle Delete ============= ?//
  const handleDelete = async () => {
    const cancel = await onCancelSubscription({ cancelAtEnd: "false" });
    const result = await onDelete({ externalUserId: userId });
    if (result && cancel) {
      history.push("/profile");
    }
  };
  // * ====================================== * //

  //? ============== Handle Cancel Subscription ============= ?//
  const handleCancelSubscription = async () => {
    const result = await onCancelSubscription({ cancelAtEnd: "true" });
    if (result) {
      setAfterCancelAndReactive(true);
    }
  };
  // * ====================================== * //

  //? ============== Handle Reactive Subscription ============= ?//
  const handleReactiveSubscription = async () => {
    const result = await onReactiveSubscription();
    if (result) {
      setAfterCancelAndReactive(true);
    }
  };
  // * ====================================== * //

  return (
    <>
      <section className={s.dangerZoneSection}>
        <h1>Danger Zone</h1>

        {tenantDetails?.subscriptionId && (
          <div style={{ marginBottom: 10 }}>
            <Spin spinning={onSubscriptionLoading}>
              <ImportantActionBox
                title={
                  !afterCancelAndReactive
                    ? planStatus !== "cancel"
                      ? "Cancel any associated subscription for this tenant"
                      : "Re-activated subscription plan for this tenant"
                    : "Action in progress"
                }
                description={
                  !afterCancelAndReactive
                    ? planStatus !== "cancel"
                      ? "This action will cancel any associated subscription for this tenant at the end of billing period"
                      : "This action will reactive auto-renewal subscription for this tenant"
                    : "This action take a time to proceed, please refresh in several time to see an update"
                }
                clickText={
                  planStatus !== "cancel"
                    ? "Cancel Subscription"
                    : "Re-active Subscription"
                }
                type={
                  !afterCancelAndReactive
                    ? planStatus !== "cancel"
                      ? "danger"
                      : "success"
                    : "warning"
                }
                onClick={
                  planStatus === "cancel"
                    ? handleReactiveSubscription
                    : handleCancelSubscription
                }
                withButton={!afterCancelAndReactive}
              />
            </Spin>
          </div>
        )}

        <div>
          <ImportantActionBox
            title={
              tenantUser?.length > 1
                ? "You can't delete your tenant!"
                : "Delete this tenant and cancel any associated subscriptions."
            }
            description={
              tenantUser?.length > 1
                ? `
              Your tenant can't be deleted because: <br/>
              <ul>
                <li>
                  Please remove other member before you delete this tenant.
                </li>
              </ul>
              `
                : `This action is permanent and you will not be able to reuse this tenant in the future.`
            }
            clickText={`Delete`}
            onClick={handleDelete}
            disabled={tenantUser?.length > 1 ? true : false}
          />
        </div>
      </section>
    </>
  );
}

DashboardSettingsAdvanced.propTypes = {
  userId: propTypes.number,
};

export default DashboardSettingsAdvanced;
