import React from "react";
import s from "./index.module.scss";

export default function BetaBanner() {
  return (
    <>
      <div className={s.banner}>BETA</div>

      <div className={s.container}>
        <strong>
          <span role="img" aria-label="">
            🚀 Explore our Beta:
          </span>
        </strong>{" "}
        Allow Exactly is now in test mode! Try new features and share your
        feedback. Be part of the evolution!
      </div>
    </>
  );
}
