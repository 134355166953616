// Libs
import React from "react";
import { Col } from "antd";
import { Container } from "reactstrap";

// Components
import MainWaveDivider from "components/libs/wave-divider";

// Assets
import s from "./index.module.scss";
import MainAccordion from "components/libs/accordion";

export default function HomepageContentsFaqSection() {
  const listQuestion = [
    {
      title: "How it's work?",
      text: "Register your account, download your agent, and connect your cloud account, and you're all set to manage your firewall rules and connect with any device rules.",
    },
    {
      title: "How to add device without agent/agent-less?",
      text: "You only need to add your device from the dashboard, then select the 'with-agent' segment on the device panel, add your device by hostname, and it will be added to your tenant. You're now ready to link that device with any firewall you have.",
    },
    {
      title:
        "How to add device with agent and how can I know my specific device?",
      text: "To add a device, first go to the device panel on your dashboard and click 'Add Device.' Next, select the 'with agent' segment, download our agent to your device, and launch the agent from your browser. Click on the magic link provided, and it will automatically log in to your account and add your device to the device list on your dashboard. Now you're ready to connect your device with any firewall-destination you have",
    },
    {
      title: "What are the benefits of a subscription?",
      text: "With a subscription, you can manage a greater number of devices and firewalls, and you can also perform management of multiple cloud accounts for your firewalls. Additionally, an important feature is the ability to deploy AllowExactly on your premises.",
    },
  ];

  return (
    <>
      <MainWaveDivider scrollId="faq" title="FAQ" />
      <Container>
        <Col span={20} className={s.container}>
          <h1 style={{ textAlign: "center" }}>Frequently Ask Question</h1>
          {listQuestion.map((item, index) => {
            return (
              <div className={s.accordion} key={index}>
                <MainAccordion
                  title={item.title}
                  text={item.text}
                  defaultCollapse={index === 0 ? true : false}
                />
              </div>
            );
          })}
        </Col>
      </Container>
    </>
  );
}
