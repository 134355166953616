// Libs
import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Card } from "antd";

// Components
import MainLoading from "components/libs/loading";
import ImportantActionBox from "components/libs/important-action-box";

// Contents
import DashboardContentsProfileUserDetails from "components/contents/dashboard/profile/user-details";
import DashboardContentsProfileTimezoneSettings from "components/contents/dashboard/profile/timezone-settings";

// Styles
import s from "./index.module.scss";

function DashboardProfile() {
  const { user, logout } = useAuth0();

  //? ============== Handle Delete ============= ?//
  const handleDelete = () => {
    window.localStorage.setItem("userId", user.sub);
    logout({
      returnTo: `${window.location.origin}/account-deletion`,
    });
  };
  // * ====================================== * //

  return (
    <>
      <>
        <section className={s.section}>
          <DashboardContentsProfileUserDetails />
        </section>
        <section className={s.section}>
          <DashboardContentsProfileTimezoneSettings />
        </section>
        <section>
          <Card>
            <h1 style={{ fontSize: 18, fontWeight: 800, marginBottom: 20 }}>
              Danger Zone
            </h1>
            <ImportantActionBox
              title="Delete Account"
              description={`
            Permanently delete your account and all data associated with it. 
            if you experienced an issue with your account and need help, please contact use so we can assist you.
            `}
              clickText={"Delete"}
              onClick={handleDelete}
            />
          </Card>
        </section>
      </>
    </>
  );
}

export default withAuthenticationRequired(DashboardProfile, {
  onRedirecting: () => <MainLoading />,
});
