// Libs
import React from "react";
import { Card, Col, Row } from "antd";
import { Container } from "reactstrap";

// Components
import MainWaveDivider from "components/libs/wave-divider";

// Assets
import s from "./index.module.scss";

// Icon
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { agentDeviceSupport, agentFeatures } from "data/agent-features";

export default function HomepageContentsAgentSupportFeatures() {
  return (
    <>
      <MainWaveDivider
        scrollId="agent-features"
        title="Agent Support Features"
      />
      <Container>
        <Card className={s.card}>
          <Row className={s.headerRow}>
            <Col span={9} />
            {agentDeviceSupport.map((item, index) => {
              return (
                <Col
                  className={`${s.deviceIcon} ${index === 0 ? "first" : ""} ${
                    index === agentDeviceSupport.length - 1 ? "last" : ""
                  }  `}
                  span={3}
                >
                  {item.icon}
                </Col>
              );
            })}
          </Row>

          {agentFeatures.map((item, index) => {
            return (
              <Row
                align={"middle"}
                className={`${s.featureRow} ${
                  index === agentFeatures.length - 1 ? "last" : ""
                }`}
              >
                <Col span={9} className={s.featureItem}>
                  {item.features}
                </Col>

                {agentDeviceSupport.map((device) => {
                  return (
                    <Col
                      className={`${s.featureIcon} ${s.featureItem}`}
                      span={3}
                    >
                      {item?.[device.name] === true ? (
                        <CheckCircleFilled className={s.checkIcon} />
                      ) : item?.[device.name] === false ? (
                        <CloseCircleFilled className={s.closeIcon} />
                      ) : (
                        <span className={s.tba}>{item?.[device.name]}</span>
                      )}
                    </Col>
                  );
                })}
              </Row>
            );
          })}
        </Card>
      </Container>
    </>
  );
}
