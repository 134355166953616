// Libs
import propTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Input,
  Modal,
  Result,
  Row,
  Segmented,
  Steps,
  Tooltip,
} from "antd";
import isValidDomain from "is-valid-domain";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

// Styles
import s from "./index.module.scss";

// Hooks
import { useSDKTenants } from "hooks/tenants-sdk";
import { useSDKUsers } from "hooks/user-sdk";
import { useSDKDashboards } from "hooks/dashboard-sdk";
import { planLimitation } from "data/plan-limitation";
import { usePlanSuggestion } from "context/plan-suggestion";
import { getConfig } from "config.js";
import useQueryString from "hooks/useQueryString";

function ModalAddDevice(props) {
  const { visible, handleVisible, onAdd, onDownload } = props;
  const { Step } = Steps;
  const { domain: auth0Domain } = getConfig();
  const query = useQueryString();

  const { setOpenModal, setTopic, setTenantName } = usePlanSuggestion();

  //#region Handle tenant user information
  const params = useParams();
  const { user } = useAuth0();
  const tenantName = params.tenant;
  const { data: tenantDetails } = useSDKTenants({
    tenantName: tenantName,
  });

  const { data: userDetails } = useSDKUsers({
    externalUserId: user?.sub || "",
  });
  const defaultTenant = userDetails?.tenants?.find(
    (item) => item.defaultTenant
  );

  const isDefaultTenant = tenantName === defaultTenant?.tenantName;

  const externalUserId = user?.sub;
  const tenantId = tenantDetails?.tenantId;
  const tenantPlan = tenantDetails?.plan?.split("_")?.[0] || "free";
  //#endregion

  //#region Get Device list number
  const { data: dashboardDetails } = useSDKDashboards({
    tenantName: tenantName,
  });
  const deviceCount = dashboardDetails?.deviceCount;
  //#endregion

  //? ============== Handle Thanks Page ============= ?//
  const [isThankyou, setIsThankyou] = useState(false);
  // * ====================================== * //

  //? ============== Handle Change Segment ============= ?//
  const [selectOption, setSelectOption] = useState(1);
  // * ====================================== * //

  //? ============== Handle Submit Without Agent ============= ?//
  const [deviceForm] = Form.useForm();
  const handleSubmitWithoutAgent = () => {
    deviceForm.validateFields().then(async (value) => {
      try {
        const submission = {
          hostName: value.hostName,
        };
        onAdd(submission);
      } catch (error) {
        return;
      } finally {
        handleClose();
      }
    });
  };
  // * ====================================== * //

  //? ============== Handle Step With Agent ============= ?//
  const [currentStep, setCurrentStep] = useState(0);
  const handleChangeSteps = (current) => {
    setCurrentStep(current);
  };
  // * ====================================== * //

  //? ============== Handle Select Method With Agent ============= ?//
  const [selectedMethod, setSelectedMethod] = useState(
    isDefaultTenant ? "code" : "browser"
  );
  const handleSelectedMethod = (value) => {
    setSelectedMethod(value);
  };
  // * ====================================== * //

  //? ============== Handle Submit With Agent Code ============= ?//
  const [deviceCode, setDeviceCode] = useState("");
  const handleChangeDeviceCode = (e) => {
    setDeviceCode(e.target.value);
  };
  const handleSubmitWithAgentCode = () => {
    //TODO : Update with new quota limiter//
    if (+deviceCount >= planLimitation[tenantPlan]?.device_limit) {
      setOpenModal(true);
      setTopic("Device");
      setTenantName(tenantName);
      handleClose();
    } else {
      window.open(`https://${auth0Domain}/activate?user_code=${deviceCode}`);
      setIsThankyou(true);
    }
  };
  // * ====================================== * //

  //? ============== Handle Close ============= ?//
  const handleClose = () => {
    setIsThankyou(0);
    setSelectedMethod(isDefaultTenant ? "code" : "browser");
    setCurrentStep(0);
    setSelectOption(1);
    handleVisible(false);
  };
  // * ====================================== * //

  //#region Handle open magic link
  const handleOpenMagicLink = () => {
    if (+deviceCount >= planLimitation[tenantPlan]?.device_limit) {
      setOpenModal(true);
      setTopic("Device");
      setTenantName(tenantName);
    } else {
      window.location.href = `agent-ae://login?tenantName=${tenantName}&tenantId=${tenantId}&externalUserId=${externalUserId}`;
    }
    handleClose();
  };
  //#endregion

  //#region Handle auto add device code
  const customActions = query.get("actions");
  const customParams = query.get("params");
  const [doCustomActions, setDoCustomAction] = useState(false);
  useEffect(() => {
    if (
      !doCustomActions &&
      deviceCount >= 0 &&
      customActions === "create-device-by-code" &&
      customParams
    ) {
      //TODO : Update with new quota limiter//
      if (+deviceCount >= planLimitation[tenantPlan]?.device_limit) {
        setOpenModal(true);
        setTopic("Device");
        setTenantName(tenantName);
        handleClose();
      } else {
        const authWindow = window.open(
          `https://${auth0Domain}/activate?user_code=${customParams}`
        );

        if (
          !authWindow ||
          authWindow.closed ||
          typeof authWindow.closed == "undefined"
        ) {
          setSelectOption(2);
          setCurrentStep(2);
          setSelectedMethod("code");
          setDeviceCode(customParams);
        } else {
          setIsThankyou(true);
        }
      }
      setDoCustomAction(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    auth0Domain,
    customActions,
    customParams,
    deviceCode,
    deviceCount,
    tenantName,
    tenantPlan,
    doCustomActions,
  ]);
  //#endregion

  return (
    <>
      <Modal
        title="Add Device"
        open={visible}
        onCancel={handleClose}
        destroyOnClose
        footer={false}
      >
        {!isThankyou ? (
          <>
            <div>
              <Segmented
                options={[
                  { label: "Without Agent", value: 1 },
                  { label: "With Agent", value: 2 },
                ]}
                block
                className={s.option}
                onChange={(val) => setSelectOption(val)}
                value={selectOption}
              />
            </div>
            {selectOption === 1 && (
              <div
                className={`${s.withoutAgentContainer} ${s.contentContainer}`}
              >
                <Form form={deviceForm} layout={"vertical"}>
                  <Form.Item
                    label="Device Hostname"
                    name={"hostName"}
                    rules={[
                      {
                        required: true,
                        message: `Please input valid domain for device hostname`,
                        validator: (_, value) => {
                          if (isValidDomain(value)) {
                            return Promise.resolve();
                          } else {
                            return Promise.reject(
                              new Error(
                                `${value} is not valid domain, please insert valid domain for device hostname`
                              )
                            );
                          }
                        },
                      },
                    ]}
                  >
                    <Input placeholder="e.g: your-domain.com" />
                  </Form.Item>
                </Form>
                <div className={s.withoutAgentButton}>
                  <Button type="primary" onClick={handleSubmitWithoutAgent}>
                    Save
                  </Button>
                </div>
              </div>
            )}

            {selectOption === 2 && (
              <div className={`${s.contentContainer}`}>
                <p>
                  Add device with agent by downloading this agent and follow
                  this step for that:
                </p>
                <Steps
                  current={currentStep}
                  direction="vertical"
                  onChange={(current) => handleChangeSteps(current)}
                >
                  <Step
                    title={"Step 1"}
                    description={
                      <>
                        <p>
                          Download agent with this link{" "}
                          <span>
                            <Button
                              onClick={onDownload}
                              type="link"
                              style={{ padding: 0 }}
                            >
                              Download agent here
                            </Button>
                          </span>
                        </p>
                      </>
                    }
                  />
                  <Step
                    title={"Step 2"}
                    description={
                      <>
                        <p>Select following method:</p>
                        <Row>
                          <Col>
                            <Tooltip
                              placement="top"
                              showArrow={true}
                              title={
                                currentStep === 1 && !isDefaultTenant
                                  ? "To add a device using device code, switch to the default tenant first. Once added, the device can be easily transferred to any other selected tenant."
                                  : ""
                              }
                            >
                              <Button
                                type={
                                  selectedMethod === "code"
                                    ? "primary"
                                    : "default"
                                }
                                onClick={() => handleSelectedMethod("code")}
                                disabled={currentStep < 1 || !isDefaultTenant}
                              >
                                Using Device Code
                              </Button>
                            </Tooltip>
                          </Col>
                          <Col>
                            <Button
                              type={
                                selectedMethod === "browser"
                                  ? "primary"
                                  : "default"
                              }
                              onClick={() => handleSelectedMethod("browser")}
                              disabled={currentStep < 1}
                            >
                              Launch Agent From Browser
                            </Button>
                          </Col>
                        </Row>
                      </>
                    }
                  />
                  <Step
                    title={"Step 3"}
                    description={
                      <>
                        {selectedMethod === "code" && (
                          <>
                            <p>Input user code form agent:</p>
                            <Input
                              placeholder="MXX-GXX"
                              required
                              onChange={handleChangeDeviceCode}
                              value={deviceCode}
                            />
                            <Button
                              disabled={currentStep !== 2}
                              onClick={handleSubmitWithAgentCode}
                              style={{ marginTop: 20 }}
                            >
                              Submit
                            </Button>
                          </>
                        )}
                        {selectedMethod === "browser" && (
                          <>
                            <p>
                              Click this{" "}
                              <Button
                                type="link"
                                style={{ padding: 0 }}
                                onClick={handleOpenMagicLink}
                              >
                                Magic Link
                              </Button>
                            </p>
                          </>
                        )}
                      </>
                    }
                  />
                </Steps>
              </div>
            )}
          </>
        ) : (
          ""
        )}

        {isThankyou ? (
          <>
            <Result
              status="info"
              title="In Progress!"
              subTitle="Adding device by agent take a time, please make sure by refresh your device list"
            />
          </>
        ) : (
          ""
        )}
      </Modal>
    </>
  );
}

ModalAddDevice.propTypes = {
  visible: propTypes.bool,
  handleVisible: propTypes.func,
  onAdd: propTypes.func,
  onDownload: propTypes.func,
};

export default ModalAddDevice;
