import React, { useEffect } from "react";
import ContainerMain from "components/container/main";
import s from "./index.module.scss";

export default function PrivacyPolicyPage() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <ContainerMain>
        <div style={{ textAlign: "center", marginBottom: 80 }}>
          <h1 style={{ marginTop: 60, fontWeight: 700 }}>PRIVACY POLICY</h1>
          <p>[Last updated: 16 Nov. 2023]</p>
        </div>

        <div style={{ width: "80%", margin: "0 auto" }} className={s.content}>
          <p>
            AllowExactly built the AllowExactly app as a Commercial app. This
            SERVICE is provided by AllowExactly and is intended for use as is.
            This page is used to inform visitors regarding our policies with the
            collection, use, and disclosure of Personal Information if anyone
            decided to use our Service. If you choose to use our Service, then
            you agree to the collection and use of information in relation to
            this policy. The Personal Information that we collect is used for
            providing and improving the Service. We will not use or share your
            information with anyone except as described in this Privacy Policy.
            The terms used in this Privacy Policy have the same meanings as in
            our Terms and Conditions, which are accessible at AllowExactly
            unless otherwise defined in this Privacy Policy.
          </p>

          <h2>Information Collection and Use</h2>
          <p>
            For a better experience, while using our Service, we may require you
            to provide us with certain personally identifiable information,
            including but not limited to Email, Cookies and Data Usages. The
            information that we request will be retained by us and used as
            described in this privacy policy.
          </p>
          <p>
            The Data concerning the User is collected to allow the Owner to
            provide its Service, comply with its legal obligations, respond to
            enforcement requests, protect its rights and interests (or those of
            its Users or third parties), detect any malicious or fraudulent
            activity, as well as the following: Analytics, Hosting and backend
            infrastructure, Registration and authentication, Contacting the
            User, Managing contacts and sending messages, Managing data
            collection and online surveys, Content commenting, Building and
            running this Application and Displaying content from external
            platforms.
          </p>
          <b>No sale of your personal information</b>
          <p>
            We do not sell or share your personal information. In case we should
            decide to, we will inform you beforehand and will grant your right
            to opt out of such sale.
          </p>

          <h2>How We Use Your Information</h2>
          <b>Google API Services</b>
          <p>
            AllowExactly's use and transfer to any other app of information
            received from Google APIs will adhere to
            <a href="https://developers.google.com/terms/api-services-user-data-policy">
              &nbsp;Google API Services User Data Policy
            </a>
            , including the Limited Use requirements.
          </p>
          <b>Google Credentials</b>
          <p>
            We use the collected information for the following purposes:
            <ul>
              <li>
                To List available GCP projects and firewalls: The OAuth
                credentials your provide are used to access the list of projects
                and firewalls available at your GCP to know which Network we
                will update.
              </li>
              <li>
                To Update network firewall rules: The OAuth credentials you
                provide are used to access the necessary Google API services to
                update GCP network firewall rules as per your instructions.
              </li>
            </ul>
          </p>

          <h2>Log Data</h2>
          <p>
            We want to inform you that whenever you use our Service, in a case
            of an error in the app we collect data and information (through
            third-party products) on your phone called Log Data. This Log Data
            may include information such as your device Internet Protocol (“IP”)
            address, device name, operating system version, the configuration of
            the app when utilizing our Service, the time and date of your use of
            the Service, and other statistics.
          </p>

          <h2>Cookies</h2>
          <p>
            Cookies are files with a small amount of data that are commonly used
            as anonymous unique identifiers. These are sent to your browser from
            the websites that you visit and are stored on your device's internal
            memory. This Service does not use these “cookies” explicitly.
            However, the app may use third-party code and libraries that use
            “cookies” to collect information and improve their services. You
            have the option to either accept or refuse these cookies and know
            when a cookie is being sent to your device. If you choose to refuse
            our cookies, you may not be able to use some portions of this
            Service.
          </p>

          <h2>Service Providers</h2>
          <p>
            We may employ third-party companies and individuals due to the
            following reasons:
          </p>
          <ul>
            <li>To facilitate our Service;</li>
            <li>To provide the Service on our behalf;</li>
            <li>To perform Service-related services; or</li>
            <li>To assist us in analyzing how our Service is used.</li>
          </ul>
          <p>
            We want to inform users of this Service that these third parties
            have access to their Personal Information. The reason is to perform
            the tasks assigned to them on our behalf. However, they are
            obligated not to disclose or use the information for any other
            purpose.
          </p>

          <h2>Registration and Authentication</h2>
          <b>Google OAuth (Google LLC)</b>
          <p>
            Google OAuth is a registration and authentication service provided
            by Google LLC and is connected to the Google network. Personal Data
            processed: various types of Data as specified in the privacy policy
            of the service. Category of personal information collected according
            to the CCPA: internet or other electronic network activity
            information.
          </p>

          <h2>Security</h2>
          <p>
            We value your trust in providing us your Personal Information, thus
            we are striving to use commercially acceptable means of protecting
            it. But remember that no method of transmission over the internet,
            or method of electronic storage is 100% secure and reliable, and we
            cannot guarantee its absolute security.
          </p>

          <h2>Links to Other Sites</h2>
          <p>
            This Service may contain links to other sites. If you click on a
            third-party link, you will be directed to that site. Note that these
            external sites are not operated by us. Therefore, we strongly advise
            you to review the Privacy Policy of these websites. We have no
            control over and assume no responsibility for the content, privacy
            policies, or practices of any third-party sites or services.
          </p>

          <h2>Children’s Privacy</h2>
          <p>
            We do not knowingly collect personally identifiable information from
            children. We encourage all children to never submit any personally
            identifiable information through the Application and/or Services. We
            encourage parents and legal guardians to monitor their children's
            Internet usage and to help enforce this Policy by instructing their
            children never to provide personally identifiable information
            through the Application and/or Services without their permission. If
            you have reason to believe that a child has provided personally
            identifiable information to us through the Application and/or
            Services, please contact us. You must also be at least 16 years of
            age to consent to the processing of your personally identifiable
            information in your country (in some countries we may allow your
            parent or guardian to do so on your behalf).
          </p>

          <h2>Changes to This Privacy Policy</h2>
          <p>
            We may update our Privacy Policy from time to time. Thus, you are
            advised to review this page periodically for any changes. We will
            notify you of any changes by posting the new Privacy Policy on this
            page. This policy is effective as of 2023-08-07
          </p>
          <h2>Contact Us</h2>
          <p>
            If you have any questions or suggestions about our Privacy Policy,
            do not hesitate to contact us at info at allowexactly.com
          </p>
        </div>
      </ContainerMain>
    </>
  );
}
