// Libs
import { Col, Row } from "antd";
import MainWaveDivider from "components/libs/wave-divider";
import React from "react";
import { Container } from "reactstrap";

// Assets
import s from "./index.module.scss";
import { DownloadList } from "components/libs/modal/download-agent-installer/utils";

export default function HomepageContentsDownloadSection() {
  const downloadList = DownloadList();

  const DownloadButtonComp = (props) => {
    const { item } = props;

    const handleClick = () => {
      if (item.url) {
        window.location.href = item.url;
      }
    };
    return (
      <Col
        span={6}
        className={`${s.download_btn} ${item.disabled ? "disabled" : ""}`}
        onClick={handleClick}
      >
        <div
          className={`${s.download_btn_container} ${
            item.disabled ? "disabled" : ""
          }`}
        >
          <span className={s.icon}>{item.icon}</span>
          <div>
            {item.label}

            <p
              style={{
                margin: 0,
                fontSize: 10,
                width: "100%",
                fontWeight: "normal",
              }}
            >
              {item.disabled ? "(Coming Soon)" : ""}
            </p>
          </div>
        </div>
      </Col>
    );
  };

  return (
    <>
      <MainWaveDivider scrollId="download" title="Download" />
      <Container style={{ padding: "0px 0 60px" }}>
        <Row align="middle">
          <Col span={14} className={s.content}>
            <h1>Download Agent For Your Device</h1>
            <p>
              To get started, simply click the download button and follow the
              instructions provided. Our application is compatible with a wide
              range of devices and operating systems, making it easy for you to
              manage your firewall rules from anywhere, at any time. Download
              now and experience the benefits of streamlined firewall management
              today!
            </p>
            <div style={{ margin: "20px 0 5px" }}>For Desktop:</div>
            <Row gutter={[16, 0]}>
              {downloadList.map((item, index) => {
                return <>{index < 3 && <DownloadButtonComp item={item} />}</>;
              })}
            </Row>
            <div style={{ margin: "20px 0 5px" }}>For Mobile:</div>
            <Row gutter={[16, 0]}>
              {downloadList.map((item, index) => {
                return <>{index >= 3 && <DownloadButtonComp item={item} />}</>;
              })}
            </Row>
          </Col>
          <Col span={10} className={s.image}>
            <img src="download-section.jpg" alt="download" />
          </Col>
        </Row>
      </Container>
    </>
  );
}
