import React from "react";
import { Modal } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
const { confirm } = Modal;

export const deleteConfirmation = ({title, content, handleOk}) => {
  confirm({
    title: title || "Are you sure ?",
    icon: <ExclamationCircleOutlined />,
    content:
      content ||
      "Do you really want to delete these item?, This process cannot be undone.",
    okType: "danger",
    onOk() {
      console.log("OK");
      handleOk();
    },
    onCancel() {
      console.log("Cancel");
    },
  });
};
