// Libs
import React from "react";
import { Badge, Col, Popconfirm, Row, Tag, Tooltip } from "antd";
import { useHistory, useLocation, useParams } from "react-router-dom";

// Styles
import s from "./utils.module.scss";

// Icons
import { EditFilled, DeleteFilled, HistoryOutlined } from "@ant-design/icons";
import TableSearch from "components/libs/table-search";
import useQueryString from "hooks/useQueryString";

export const ControlPanelFirewallColumns = ({ onDelete, onUpdate }) => {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  const path = location.pathname;
  const query = useQueryString();

  const { getColumnSearchProps } = TableSearch();

  return [
    {
      title: "ID",
      dataIndex: "firewallDestinationId",
      key: "firewallDestinationId",
      ...getColumnSearchProps("firewallDestinationId"),
    },
    {
      title: "Credential Name",
      dataIndex: "cloudId",
      key: "cloudId",
      ...getColumnSearchProps("cloudId", [
        query.get("cloudId") ? query.get("cloudId") : "",
      ]),
    },
    {
      title: "Project Name",
      dataIndex: "projectName",
      key: "projectName",
      ...getColumnSearchProps("projectName"),
      render: (t, r) => {
        return (
          <p className={s.projectName}>{r.projectName ? r.projectName : "-"}</p>
        );
      },
    },
    {
      title: "VPC Name",
      dataIndex: "vpcName",
      key: "vpcName",
      render: (t, r) => {
        return <span className={s.vpcName}>{r.vpcName ? r.vpcName : "-"}</span>;
      },
    },
    {
      title: "Firewall Rule Name",
      dataIndex: "firewallRuleName",
      key: "firewallRuleName",
      ...getColumnSearchProps("firewallRuleName"),
      render: (t, r) => {
        return <span className={s.firewallRuleName}>{t}</span>;
      },
    },
    {
      title: "Firewall IP Version",
      dataIndex: "firewallIpVersion",
      key: "firewallIpVersion",
      filters: [
        { text: "IPv4", value: "IPv4" },
        { text: "IPv6", value: "IPv6" },
      ],
      onFilter: (value, record) => record.firewallIpVersion === value,
      render: (t, r) => {
        return <span className={s.firewallIpVersion}>{t || "-"}</span>;
      },
    },
    {
      title: "Placeholder IP Address",
      dataIndex: "placeholderIpAddress",
      key: "placeholderIpAddress",
      render: (t, r) => {
        return <span className={s.placeholderIpAddress}>{t}</span>;
      },
    },
    {
      title: "Update Mode",
      dataIndex: "updateMode",
      key: "updateMode",
      filters: [
        { text: "Append", value: "APPEND" },
        { text: "Track", value: "TRACK" },
      ],
      onFilter: (value, record) => record.updateMode === value,
      render: (t, r) => {
        return (
          <span className={s.updateMode}>
            {
              <Tag color={t === "APPEND" ? "cyan" : "orange"}>
                {t === "APPEND" ? "APPEND" : "TRACK" || "-"}
              </Tag>
            }
          </span>
        );
      },
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      filters: [
        { text: "Active", value: "ACTIVE" },
        { text: "Inactive", value: "INACTIVE" },
      ],
      onFilter: (value, record) => {
        console.log("filter");
        return record.status === value;
      },
      render: (t, r) => {
        return (
          <>
            <div className={s.status}>
              {r.status === "ACTIVE" ? (
                <Badge status="processing" text="Active" />
              ) : r.status === "INACTIVE" ? (
                <Badge status="default" text="Inactive" />
              ) : (
                <Badge status="default" text={r.status} />
              )}
            </div>
          </>
        );
      },
    },
    {
      title: "Action",
      dataIndex: "action",
      key: "action",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <Row gutter={[16, 0]} className={s.action}>
              <Col className={s.actionIcon}>
                <Tooltip title="Edit">
                  <EditFilled onClick={() => onUpdate(r)} />
                </Tooltip>
              </Col>
              <Col className={s.actionIcon}>
                <Popconfirm
                  placement="top"
                  title={"Are you sure unlink this device?"}
                  onConfirm={() => onDelete(r.firewallDestinationId)}
                >
                  <Tooltip title="Delete">
                    <DeleteFilled />
                  </Tooltip>
                </Popconfirm>
              </Col>
              <Col className={s.actionIcon}>
                <Tooltip title="History">
                  <HistoryOutlined
                    onClick={() =>
                      history.push(`${path}/${r.firewallDestinationId}/history`)
                    }
                  />
                </Tooltip>
              </Col>
            </Row>
          </>
        );
      },
    },
    {
      title: "Linked Device",
      dataIndex: "linked_device",
      key: "linked_device",
      fixed: "right",
      render: (t, r) => {
        return (
          <>
            <p
              className={s.manageFirewallLink}
              onClick={() =>
                history.push(
                  `/dashboard/${params.tenant}/manage-link?type=firewall&id=${r.firewallDestinationId}`
                )
              }
            >
              Manage Device Link
            </p>
          </>
        );
      },
    },
  ];
};
