// Libs
import React from "react";
import propTypes from "prop-types";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Input, Modal, Spin } from "antd";
import { useParams, useRouteMatch } from "react-router-dom";

// Hooks
import { useSDKTenants } from "hooks/tenants-sdk";

// Styles
// import s from "./index.module.scss";

function DashboardCreateTenantModal(props) {
  const { isShow, onCancel } = props;
  const { user } = useAuth0();
  const params = useParams();
  const match = useRouteMatch();

  //#region Tenant hooks
  const { onAdd, loading } = useSDKTenants({ tenantName: params.tenant });
  //#endregion

  //#region Handle Create Tenant
  const [form] = Form.useForm();
  const handleCreate = () => {
    form.validateFields().then(async (value) => {
      const submission = {
        email: user?.email,
        externalUserId: user?.sub,
        name: value.tenantName,
      };
      const result = await onAdd(submission);
      if (result) {
        const newUrl = match.path.replace(":tenant", result.result.tenantName);
        window.location.replace(newUrl);
      }
      if (result) {
        form.resetFields();
        onCancel();
      }
    });
  };
  //#endregion

  return (
    <>
      <Modal
        visible={isShow}
        onCancel={onCancel}
        maskStyle={{ zIndex: 1100 }}
        okText={"Save"}
        title={"Create Your Tenant"}
        zIndex={1101}
        onOk={handleCreate}
      >
        <Spin spinning={loading}>
          <Form form={form} layout="vertical">
            <Form.Item
              label="Tenant Name"
              name={"tenantName"}
              rules={[
                {
                  required: true,
                  validator: (_, value) => {
                    const validName = new RegExp("^[a-zA-Z0-9 ]*$");
                    if (validName.test(value)) {
                      return Promise.resolve();
                    }

                    return Promise.reject(
                      new Error("Tenant name must be alphabets or number only")
                    );
                  },
                },
              ]}
            >
              <Input
                name={"tenant_name"}
                placeholder={"Input your tenant name"}
              />
            </Form.Item>
          </Form>
        </Spin>
      </Modal>
    </>
  );
}

DashboardCreateTenantModal.propTypes = {
  isShow: propTypes.bool,
  onCancel: propTypes.func,
};

export default DashboardCreateTenantModal;
