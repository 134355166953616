import React from "react";
import { IosIcon } from "assets/icons/ios-logo";
import { AndroidIcon } from "assets/icons/android-icon";
import { AppleIcon } from "assets/icons/apple-logo";
import { WindowIcon } from "assets/icons/window-logo";
import { LinuxIcon } from "assets/icons/linux-logo";

export const agentFeatures = [
  {
    features: "Magic link support",
    android: true,
    ios: true,
    macos: true,
    windows: true,
    linux: true,
  },
  {
    features: "Launch at startup",
    android: true,
    ios: false,
    macos: true,
    windows: true,
    linux: true,
  },
  {
    features: "Fantastic GUI",
    android: true,
    ios: true,
    macos: true,
    windows: true,
    linux: false,
  },
  {
    features: "Schedule System",
    android: true,
    ios: true,
    macos: true,
    windows: true,
    linux: true,
  },
  {
    features: "System Tray",
    android: false,
    ios: false,
    macos: true,
    windows: true,
    linux: false,
  },
  {
    features: "Auto Update",
    android: "TBA",
    ios: "TBA",
    macos: "TBA",
    windows: "TBA",
    linux: "TBA",
  },
  {
    features: "Multi Instance Support",
    android: "TBA",
    ios: "TBA",
    macos: "TBA",
    windows: "TBA",
    linux: "TBA",
  },
];

export const agentDeviceSupport = [
  { name: "android", label: "Android", icon: <AndroidIcon /> },
  { name: "ios", label: "IOS", icon: <IosIcon /> },
  { name: "macos", label: "MacOS", icon: <AppleIcon /> },
  { name: "windows", label: "Windows", icon: <WindowIcon /> },
  { name: "linux", label: "Linux", icon: <LinuxIcon /> },
];
