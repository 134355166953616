import axios from "axios";
import { getConfig } from "config";

//? General Config
const { apiOrigin } = getConfig();

const instance = axios.create({
  baseURL: `${apiOrigin}/api/v1/`,
  timeout: 40000,
  headers: {
    Accept: "application/json, text/plain, */*",
    "Content-Type": "application/json; charset=utf-8",
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    return config;
  },
  function (error) {
    return error;
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error) return error;
  }
);

export const fetcher = (url) => {
  return instance.get(url).then((res) => {
    const r = res.data;

    return r;
  });
};

export default instance;
