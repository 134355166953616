// Libs
import React from "react";
import { Spinner } from "reactstrap";

// Styles
import s from "./index.module.scss";

function MainLoader(props) {
  const { active, opaque } = props;
  return (
    <>
      {active && (
        <div className={opaque ? s.loaderContainerOpaque : s.loaderContainer}>
          <Spinner color="primary" className={s.loader} />
        </div>
      )}
    </>
  );
}

export default MainLoader;
