// Libs
import React from "react";

// Components
import MemberPageContentsAddOnsSection from "components/contents/dashboard/settings/add-ons-section";

function DashboardSettingsAddOns() {
  return (
    <>
      <MemberPageContentsAddOnsSection />
    </>
  );
}

export default DashboardSettingsAddOns;
