import { ArrowRightOutlined } from "@ant-design/icons";
import { Button, Col, notification } from "antd";
import React from "react";

export default function NotificationWarningExpiredCredentials({
  onClick = () => {},
}) {
  return notification.warning({
    message: "Expired Credentials!",
    description: (
      <>
        <Col span={24}>
          <p>
            Attention! Your credentials have expired and must be updated in
            order to proceed.
          </p>
        </Col>
        <Col>
          <Button
            type="link"
            style={{
              padding: 0,
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => {
              onClick();
            }}
          >
            Update Now <ArrowRightOutlined />
          </Button>
        </Col>
      </>
    ),
  });
}
