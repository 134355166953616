import React from "react";
import SessionContextProvider from "./session";
import PlanSuggestionContextProvider from "./plan-suggestion";

function GlobalContext(props) {
  return (
    <>
      <PlanSuggestionContextProvider>
        <SessionContextProvider>{props.children}</SessionContextProvider>
      </PlanSuggestionContextProvider>
    </>
  );
}

export default GlobalContext;
