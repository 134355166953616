// Libs
import React, { useState } from "react";
import { Button, ButtonGroup } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAuth0 } from "@auth0/auth0-react";
import { Spin, Tooltip } from "antd";

// Components
import MainWaveDivider from "components/libs/wave-divider";
import PricingIconFree from "components/libs/pricing-icon/free-icon";
import PricingIconAdvance from "components/libs/pricing-icon/advance-icon";
import PricingIconPro from "components/libs/pricing-icon/pro-icon";
import MainButtonPrice from "components/libs/button-price";

// Initial Data
import { pricingContents } from "data/homepage";

// Hooks
import { useZohoSubscriptions } from "hooks/zoho-integration-sdk";

// Styles
import s from "./index.module.scss";
import { useSDKUsers } from "hooks/user-sdk";
import { useSDKTenants } from "hooks/tenants-sdk";

function HomepageContentsPricingSection() {
  //? ============== User Session ============= ?//
  const { isAuthenticated, user, loginWithRedirect } = useAuth0();
  // * ====================================== * //

  //? ============== Handle Loading ============= ?//
  const [loading, setLoading] = useState(false);
  // * ====================================== * //

  //? ============== User Hook ============= ?//
  const { data: userDetails } = useSDKUsers({
    externalUserId: user?.sub || "",
  });
  const defaultTenant = userDetails?.tenants?.find(
    (item) => item.defaultTenant
  );
  // * ====================================== * //

  //? ============== Tenants Hook ============= ?//
  const { data: tenantDetails, loading: tenantLoading } = useSDKTenants({
    tenantName: defaultTenant?.tenantName,
  });
  const currentPlan = tenantDetails?.plan;
  // * ====================================== * //

  //? ============== Zoho Checkout Hook ============= ?//
  const { onCreateSubscription, onUpdateSubscription } = useZohoSubscriptions({
    subscriptionId: "",
  });
  // * ====================================== * //

  //? ============== Payment Hook ============= ?//
  const handleSubscribe = async (mode) => {
    setLoading(true);
    if (isAuthenticated) {
      if (!tenantDetails?.subscriptionId) {
        const submission = {
          email: user.email,
          planCode: isMonthly ? mode.monthly_plan_code : mode.annual_plan_code,
          tenantName: defaultTenant.tenantName,
        };
        onCreateSubscription(submission);
      } else {
        const submission = {
          subscriptionId: tenantDetails?.subscriptionId,
          planCode: isMonthly ? mode.monthly_plan_code : mode.annual_plan_code,
          addOns: [], //TODO : Handle Add Ons//
        };
        onUpdateSubscription(submission);
      }
    } else if (mode.name === "Free") {
      loginWithRedirect({
        screen_hint: "signup",
      });
    } else {
      loginWithRedirect({
        redirectUri: `${window.location.origin}/payment-redirect?planCode=${
          isMonthly ? mode.monthly_plan_code : mode.annual_plan_code
        }`,
        screen_hint: "signup",
      });
    }
  };
  // * ====================================== * //

  //? ============== Handle Monthly ============= ?//
  const [isMonthly, setIsMonthly] = useState(true);
  // * ====================================== * //

  return (
    <section>
      <MainWaveDivider scrollId="pricingPlans" title="Pricing Plans" />
      <Spin spinning={user ? tenantLoading : false}>
        <div>
          <h2 className={s.priceHeader1}>Choose a Plan</h2>
          <h3 className={s.priceHeader2}>AllowExactly Free vs Paid Versions</h3>

          <ButtonGroup className={s.pricePlanButton}>
            <Button
              color="primary"
              active={isMonthly}
              onClick={() => {
                setIsMonthly(true);
              }}
            >
              Monthly
            </Button>
            <Button
              color="primary"
              active={!isMonthly}
              onClick={() => {
                setIsMonthly(false);
              }}
            >
              Annually
            </Button>
          </ButtonGroup>
          <Spin style={{ maxHeight: "100%" }} spinning={loading}>
            <div className={s.priceMain}>
              <table className={s.priceTable}>
                <tbody>
                  <tr>
                    <td className={s.priceTableBlank} />
                    <td className={s.priceTableBlank} />
                    <td className={s.priceTableBlank} />
                    <td className={s.priceTablePopular}>Recommended</td>
                    <td className={s.priceTableBlank} />
                  </tr>
                  <tr className={s.priceTableHead}>
                    <td></td>
                    {pricingContents.modes.map((mode) => {
                      return (
                        <td key={mode.name}>
                          {mode.displayName}
                          <br />
                          <small className={s.priceSubHead}>
                            {mode.subHead}
                          </small>
                        </td>
                      );
                    })}
                  </tr>
                  <tr>
                    <td></td>
                    {pricingContents.modes.map((mode) => {
                      return (
                        <td className={s.priceLabel} key={mode.name}>
                          {mode.name === "Free" ? (
                            <PricingIconFree />
                          ) : mode.name === "Advanced" ? (
                            <PricingIconAdvance />
                          ) : (
                            <PricingIconPro />
                          )}
                          <br />
                          {isMonthly
                            ? pricingContents[mode.name].priceMonthly ===
                              "Contact Us"
                              ? "Contact Us"
                              : pricingContents[mode.name].priceMonthly ===
                                "FREE"
                              ? "FREE"
                              : pricingContents[mode.name].priceMonthly + "/mo"
                            : pricingContents[mode.name].priceAnnually ===
                              "Contact Us"
                            ? "Contact Us"
                            : pricingContents[mode.name].priceAnnually ===
                              "FREE"
                            ? "FREE"
                            : pricingContents[mode.name].priceAnnually + "/ann"}
                          <br />
                          <MainButtonPrice
                            handleClick={() => handleSubscribe(mode)}
                            mode={mode}
                            currentPlan={currentPlan}
                            isAuthenticated={isAuthenticated}
                            pricingContents={pricingContents}
                            planCode={
                              isMonthly
                                ? mode.monthly_plan_code
                                : mode.annual_plan_code
                            }
                          />
                        </td>
                      );
                    })}
                  </tr>
                  {pricingContents.subHeads.map((subHead) => (
                    <React.Fragment key={subHead.name}>
                      <tr key={subHead.name} className={s.pricingSubHead}>
                        <td colSpan="5" style={{ textAlign: "center" }}>
                          <b>{subHead.name}</b>
                        </td>
                      </tr>
                      {subHead.properties.map((property) => (
                        <tr key={property}>
                          <td>
                            <div className={s.priceHelpContainer}>
                              {property.title}
                              <span>
                                <Tooltip
                                  placement="top"
                                  title={property.description}
                                >
                                  <span>
                                    <a href="/" className={s.priceHelp}>
                                      {property.description && (
                                        <FontAwesomeIcon
                                          icon={["fas", "question-circle"]}
                                        />
                                      )}
                                    </a>
                                  </span>
                                </Tooltip>
                              </span>
                            </div>
                          </td>
                          {pricingContents.modes.map((mode) => {
                            return (
                              <td key={mode.name}>
                                {pricingContents[mode.name][subHead.name][
                                  property.title
                                ] === true ? (
                                  <FontAwesomeIcon icon="check"></FontAwesomeIcon>
                                ) : pricingContents[mode.name][subHead.name][
                                    property.title
                                  ] === false ? (
                                  <FontAwesomeIcon icon="times"></FontAwesomeIcon>
                                ) : (
                                  pricingContents[mode.name][subHead.name][
                                    property.title
                                  ]
                                )}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                    </React.Fragment>
                  ))}
                  <tr>
                    <td></td>
                    {pricingContents.modes.map((mode) => {
                      return (
                        <td key={mode.name} className={s.priceTableButton}>
                          <MainButtonPrice
                            handleClick={() => handleSubscribe(mode)}
                            mode={mode}
                            currentPlan={currentPlan}
                            isAuthenticated={isAuthenticated}
                            pricingContents={pricingContents}
                            planCode={
                              isMonthly
                                ? mode.monthly_plan_code
                                : mode.annual_plan_code
                            }
                          />
                        </td>
                      );
                    })}
                  </tr>
                </tbody>
              </table>
            </div>
          </Spin>
        </div>
      </Spin>
    </section>
  );
}

export default HomepageContentsPricingSection;
