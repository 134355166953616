// Libs
import propTypes from "prop-types";
import React from "react";

function MainWaveDivider(props) {
  const { scrollId, title } = props;
  return (
    <>
      <div className="mb-5">
        <svg
          width="100%"
          viewBox="0 0 1280.000000 97.000000"
          preserveAspectRatio="none"
        >
          <g
            transform="translate(0.000000,97.000000) scale(0.100000,-0.100000)"
            fill="#005789"
            stroke="none"
          >
            <path
              d="M12465 895 c-767 -136 -1280 -224 -1755 -299 -1998 -318 -3644 -476
                    -4950 -476 -716 0 -1355 36 -2170 121 -637 67 -1064 124 -2320 313 -632 95
                    -1162 166 -1242 166 l-28 0 0 -360 0 -360 6400 0 6400 0 0 475 c0 446 -1 475
                    -17 474 -10 0 -153 -25 -318 -54z"
            />
          </g>
        </svg>
        <div style={{ position: "relative" }}>
          <h2
            id={scrollId}
            className="h2"
            style={{
              position: "absolute",
              bottom: "20%",
              left: "20%",
              color: "white",
            }}
          >
            {title}
          </h2>
          <svg
            width="100%"
            viewBox="0 0 1280.000000 213.000000"
            preserveAspectRatio="none"
          >
            <g
              transform="translate(0.000000,213.000000) scale(0.100000,-0.100000)"
              fill="#005789"
              stroke="none"
            >
              <path
                d="M0 1621 l0 -509 83 -11 c256 -37 474 -112 1153 -399 661 -280 958
                    -391 1324 -497 839 -242 1496 -260 2448 -69 308 62 745 173 1217 309 1029 297
                    1208 348 1472 414 766 192 1283 262 1833 248 519 -13 977 -90 1485 -250 198
                    -62 369 -125 768 -281 499 -196 717 -267 902 -296 39 -6 80 -13 93 -16 l22 -4
                    0 935 0 935 -6400 0 -6400 0 0 -509z"
              />
            </g>
          </svg>
        </div>
      </div>
    </>
  );
}

MainWaveDivider.propTypes = {
  scrollId: propTypes.string,
  title: propTypes.string,
};

export default MainWaveDivider;
