import { AndroidIcon } from "assets/icons/android-icon";
import { AppleIcon } from "assets/icons/apple-logo";
import { IosIcon } from "assets/icons/ios-logo";
import { LinuxIcon } from "assets/icons/linux-logo";
import { WindowIcon } from "assets/icons/window-logo";
import { getConfig } from "config";
import React from "react";

export const DownloadList = () => {
  const config = getConfig();

  return [
    {
      label: "Windows",
      url: config?.agentInstallerWindows,
      icon: <WindowIcon />,
      disabled: false,
    },
    {
      label: "MacOS",
      url: config?.agentInstallerMacOS,
      icon: <AppleIcon />,
      disabled: false,
    },
    {
      label: "Linux",
      url: config?.agentInstallerLinux,
      icon: <LinuxIcon />,
      disabled: false,
    },
    {
      label: "Android",
      url: config?.agentInstallerAndroid,
      icon: <AndroidIcon />,
      disabled: false,
    },
    {
      label: "IOS",
      url: config?.agentInstallerIos,
      icon: <IosIcon />,
      disabled: true,
    },
  ];
};
